import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { $ } from "react-jquery-plugin";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { ProfileContext } from "../../../Context";

const TwofactorPage = () => {
  // ********* Context ********** //
  const { authType, email, mobile, handleUserDetials } = useContext(ProfileContext);

  // ********* Component State  ********** //
  const [checkedAuth, setCheckedAuth] = useState(authType);
  const [vCode, setVcode] = useState("");
  const [googleQr, setGoogleQr] = useState({});
  const [googleCode, setGoogleCode] = useState();
  const [verifyType, setVerifyType] = useState();


  // ********* Check User Selected Auth Type  ********** //
  const checkType = () => {
    setVcode('')
    if (checkedAuth === 2) {
      getGoogleQr();
    } else if (checkedAuth === 1 || checkedAuth === 3) {
      handleGetOtp(checkedAuth === 1 ? email : mobile)
    } else {
      handleGetOtp(email ? email : mobile)
    }
  };


  // ********* Get Auth OTP ********** //
  const handleGetOtp = async (signId, resend) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId, resend).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          $("#otp").modal('show');
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  // ********* Update Two Factor ********** //
  const Update2Fa = async (authType, vCode, signId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.update2fa(authType, vCode, signId).then(async result => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          $("#google_modal").modal('hide');
          $("#otp").modal('hide');
          handleUserDetials()
        } catch (error) {
          alertErrorMessage(error);
          console.log('error', `${error}`);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  }

  // *********  Get Google Qr  ********** //
  const getGoogleQr = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.googleAuth().then(async result => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setGoogleQr(result.data.qr_code);
          setGoogleCode(result.data.secret.base32)
          $("#google_modal").modal('show');
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  // ********* Show/Hide Steps for Google Authentication  ********** //
  function hideStep() {
    const pop = document.querySelector(".step_1");
    pop.classList.add("d-none");
    const pop2 = document.querySelector(".step_2");
    pop2.classList.remove("d-none");
  };


  // ********* Copy Code ********** //
  const copyCode = () => {
    navigator.clipboard
      .writeText(googleCode)
      .then(() => {
        alertSuccessMessage("Copied!!");
      })
      .catch(() => {
        alertErrorMessage("Something went wrong");
      });
  };

  // ********* Scroll Upside ********** //
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <div className="tab-pane" id="AuthencationPill" role="tabpanel" aria-labelledby="Authencation-pill">
        <div className="upload-formate mb-6 d-flex justify-content-center align-items-center">
          <div className="mb-1 text-center">
            <h3 className="mb-1 text-center">
              Two Factor Authentication
            </h3>
            <p className="formate mb-0">
              Select Two Factor Authentication for your security.
            </p>
          </div>
        </div>
        <div className="row">
          {console.log(checkedAuth, 'checkedAuth')}
          <div className="col-md-12 col-lg-8 m-auto">
            <div className="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-4">
              <div className="d-flex align-items-center justify-space-between">
                <h6 className="mb-0 w-100">Authenticator App</h6>
                <div className="form-check  switch_btns form-switch">
                  <input className="form-check-input" type="checkbox" name="Two-Factor" id="1" onClick={() => setCheckedAuth(2)} checked={checkedAuth === 2} />
                </div>
              </div>
              <hr />
              <div className={`d-flex align-items-center justify-space-between ${!mobile && "sec_disable"}`}>
                <h6 className="mb-0 w-100">Mobile OTP </h6>
                <div className="form-check  switch_btns form-switch">
                  <input className="form-check-input" type="checkbox" name="Two-Factor" id="4" disabled={!mobile} onChange={() => setCheckedAuth(3)} checked={checkedAuth === 3} />
                </div>
              </div>
              <hr />
              <div className={`d-flex align-items-center justify-space-between ${!email && "sec_disable"}`} >
                <h6 className="mb-0 w-100">Email OTP</h6>
                <div className="form-check  switch_btns form-switch">
                  <input className="form-check-input" type="checkbox" name="Two-Factor" id="2" disabled={!email} onChange={() => setCheckedAuth(1)} checked={checkedAuth === 1} />
                </div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-space-between">
                <h6 className="mb-0 w-100">None</h6>
                <div className="form-check  switch_btns form-switch">
                  <input className="form-check-input" type="checkbox" name="Two-Factor" id="3" onChange={() => setCheckedAuth(0)} checked={checkedAuth === 0} />
                </div>
              </div>
              <hr />
              <div className="col-md-12 mb-1 mt-5 ">
                <div className="field-box text-center">
                  <button className="btn btn-gradient w-50  justify-content-center btn-medium mb-0" type="button" data-toggle="modal" disabled={authType === checkedAuth} onClick={() => checkType()}><span>Submit</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="google_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <form className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl"> Authenticator App </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body conin_purchase">
              <div className="step_1 " >
                <div className="col-md-8 m-auto mb-5 text-center" >
                  <img className="img-fluid qr_img w-100" src={googleQr} />
                  <div className="field-box field-otp-box mb-3">
                    <input type="text" placeholder="Code" value={googleCode} readOnly />
                    <button type="button" className="btn btn-sm btn-gradient" onClick={() => copyCode()}>
                      <span>Copy </span>
                    </button>
                  </div>
                  <button type="button" className="text-center next_btn btn-gradient m-auto w-100 btn btn-block" onClick={() => hideStep()}> Next </button>
                </div>
              </div>
              <div className="step_2 d-none" >
                <div className="col-md-8 m-auto mb-5 text-center" >
                  <div className="pt-5" >
                    <input type="text" className="mb-3" value={vCode} placeholder="Enter Code.." onChange={(event) => setVcode(event.target.value)} />
                  </div>
                  <button type="button" className="text-center next_btn btn-gradient m-auto w-100 btn btn-block" onClick={() => Update2Fa(checkedAuth, vCode, email ? email : mobile)} disabled={!vCode}> <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="modal fade" id="otp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <form className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">{checkedAuth === 1 && 'Verify Email OTP'}{checkedAuth === 3 && 'Verify Mobile OTP'}{checkedAuth === 0 && email ? 'OTP Sent to Email' : checkedAuth === 0 && mobile ? 'OTP Sent to Mobile' : ''}</h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body conin_purchase">
              <div className="step_2 " >
                <div className="col-md-8 m-auto mb-5 text-center" >
                  <div className="pt-5" >
                    <input type="text" className="mb-3" placeholder="Enter OTP" value={vCode} onChange={(e) => { setVcode(e.target.value) }} />
                  </div>
                  <button type="button" onClick={() => Update2Fa(checkedAuth, vCode, checkedAuth === 1 ? email : checkedAuth === 3 ? mobile : checkedAuth === 0 && email ? email : checkedAuth === 0 && mobile ? mobile : null)} disabled={!vCode} className="next_btn btn-gradient m-auto w-100 btn btn-block"> <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default TwofactorPage;
