import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {


  return (
    <footer className="footer-wrapper">
      <div className="footer-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-5">
              <div className="footer-widget first-block">
                <div className="mb-4">
                  <Link to="/" className="logo-light"><img src="images/logo-white.png" alt="brand" /></Link>
                </div>
                <p className="mb-5">We'll tell you what cryptocurrencies are, how they work and why you should own one right now. So let's do it</p>
                <div className="social">
                  <a className="icon-facebook" href="https://www.facebook.com/profile.php?id=100090344480361&mibextid=ZbWKwL" target="_blank"><i className="ri-facebook-line"></i></a>
                  <a className="icon-twitter" href="https://twitter.com/FlexibitsI40235?t=eOWr75ZaZlFgV9ZNiMMJkg&s=09" target="_blank"><i className="ri-twitter-line"></i></a>
                  <a className="icon-instagram" href="https://instagram.com/flexibits.io?igshid=NjIwNzIyMDk2Mg==" target="_blank"><i className="ri-instagram-line"></i></a>
                  <a className="icon-linkedin" href="https://www.linkedin.com/in/flexibits-exchange-992097285" target="_blank"><i className="ri-linkedin-line"></i></a>
                </div>
              </div>
            </div>
            {/* <!-- End .col --> */}

            <div className="col-lg-2 col-md-6 mb-5">
              <div className="footer-widget">
                <h4>About</h4>
                <ul className="footer-list-widget">
                  <li><Link to="/aboutus">About Us</Link></li>
                  <li><Link to="/PrivacyPolicy">Privacy Policy </Link></li>
                  <li><Link to="/termsConditions">Terms Of Use</Link></li>
                  <li><Link to="#"> KYC Policy </Link></li>
                  <li><Link to="#">AML Policy</Link></li>
                </ul>
              </div>
            </div>
            {/* <!-- End .col --> */}

            <div className="col-lg-2 col-md-6 mb-5">
              <div className="footer-widget">
                <h4>Contact</h4>
                <ul className="footer-list-widget">
                  <li><Link to="/contact">Contact Us</Link></li>
                  <li><a href="#">support@flexibits.io</a></li>
                </ul>
              </div>
            </div>
            {/* <!-- End .col --> */}

            <div className="col-lg-3 col-md-6 mb-5">
              <div className="footer-widget">
                <h4>Download mobile app</h4>
                <div className="down_tab" >
                  <div >
                    <a href="#" ><img src="images/play_store.png" className="img-fluid" /></a>
                    <a href="#" ><img src="images/app_store.png" className="img-fluid" /></a>
                  </div>
                  <div className=" ms-3">
                    <img src="images/qr_code.png" />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End .col --> */}
          </div>
        </div>
        {/* <!-- End .container --> */}
      </div>
      <div className="copyright text-center">
        <div className="container">
          <p>Copyright © 2022 All Rights Reserved... Designed & developed by <a href="#/"
          >FLEXIBITS TECHNOLOGIES</a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;