import HomeService from "../../../api/services/HomeService";
import AuthService from "../../../api/services/AuthService";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

export const MemberPanel = () => {
  const [totalCitraBalance, setTotalCitraBalance] = useState("");
  const [levelData, setLevelData] = useState([]);
  const userId = sessionStorage.getItem("userId");
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    getLevelHandler();
    handleCitraBalance();
  }, [])

  const getLevelHandler = async () => {
    await HomeService.levelHandler(state, userId).then(async (result) => {
      if (result?.success) {
        try {
          alertSuccessMessage(result?.message);
          setLevelData(result?.data)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage()
      }
    });
  };


  const handleCitraBalance = async () => {
    await HomeService.getCitraBalance().then(async (result) => {
      if (result?.succes) {
        console.log(result,'kkkkkkkk');
        setTotalCitraBalance(result?.data)
      }
    });
  };

  return (
    <>
      <section class="page_wrapper">
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1" />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2" />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3" />
        <div class="container">
          <div class="inner text-center">
            <h1 class="title">Member Panel</h1>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Member Panel
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section class="pb-50">
        <div class="container"> 
          <div class="d-flex-between mb-5 funds_tabs ">
            <div class="d-flex-between bidder">
              <span>Total Citra Balance : {totalCitraBalance} </span>
              <div class="d-flex-center ms-1">
                {/* <span class="text-dark">{estimatedportfolio}</span> */}
              </div>
            </div>
          </div>
          {/*  */}
          <div class="tab-content custom-tab-content p-0">
            <div class="tab-pane fade show container active  table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="funds">
              <div class="table-responsive">
                <table class="table ">
                  <thead style={{ backgroundColor: "darkgoldenrod" }}>
                    <tr>
                      <th>Sr No.</th>
                      <th>Name</th>
                      <th>KYC Status</th>
                      <th>CITRA Coins in Flexiwallet</th>
                    </tr>
                  </thead>
                  {levelData?.length > 0 ? (
                    levelData?.map((value, index) => (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <span>{value?.firstName + " " + value?.lastName}</span>
                          </td>
                          <td>Verified</td>
                          <td>{value?.citra_balance}</td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <tr rowSpan="5">
                      <td colSpan="6">
                        <p style={{ textAlign: "center" }}>No data Available</p>
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
