import React, { useEffect, useRef } from 'react';
import { bindNiceSelect } from './util';
import "./index.css"

export default function NiceSelect({ id, className, children, ...otherProps }) {
  // const functCheckRef = useRef(true);
  useEffect(() => {
    // if (functCheckRef.current) {
    if (children) {
      const el = document.getElementById(id)
        ;
      bindNiceSelect(el);
    }

    // functCheckRef.current = false;
    // }
  }, []);

  return (
    <select id={id} {...otherProps} className={className}>
      {children}
    </select>
  );
}