import React from "react";

const ComingSoonPage = () => {

    return (
    <section className="soon_sec">
        <div className="container">
            <div className="soon_img" >
                <img src="images/error.png" className="img-fluid" />             
            </div>
        </div>
    </section>
    );
    
}

export default ComingSoonPage;