import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage, alertSuccessMessageTrade, } from "../../../customComponent/CustomAlertMessage";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import "./trade.css";
import AuthService from "../../../api/services/AuthService";
import HomeService from "../../../api/services/HomeService";
import TVChartContainer from "../../../customComponent/TVChartContainer";
import moment from 'moment';
import { io } from "socket.io-client";
import { Themes } from 'react-tradingview-widget';
import { ProfileContext } from "../../../Context";

const TradePage = () => {

  const { kycStatus } = useContext(ProfileContext)

  console.log(kycStatus, "kycStatuskycStatus");

  const location = useLocation();
  const { state } = location;
  const token = sessionStorage.getItem('token');
  const userId = sessionStorage.getItem('userId');
  let recentPair = sessionStorage.getItem('RecentPair');
  let ParsedPair = JSON.parse(recentPair);
  const [search, setsearch] = useState('');
  const [AllData, setAllData] = useState([]);
  const [BuyOrders, setBuyOrders] = useState([]);
  const [CoinPairDetails, setCoinPairDetails] = useState();
  const [RecentTrade, setRecentTrade] = useState([]);
  const [SellOrders, setSellOrders] = useState([]);
  const [SelectedCoin, setSelectedCoin] = useState(state ? state : ParsedPair ? ParsedPair : null);
  const [baseCurId, setbaseCurId] = useState(state ? state?.base_currency_id : ParsedPair ? ParsedPair?.base_currency_id : null);
  const [quoteCurId, setquoteCurId] = useState(state ? state?.quote_currency_id : ParsedPair ? ParsedPair?.quote_currency_id : null);
  const [buyprice, setbuyprice] = useState(state ? state?.buy_price : ParsedPair ? ParsedPair.buy_price : 0);
  const [buyamount, setbuyamount] = useState(1);
  const [buytotal, setbuytotal] = useState(0);
  const [sellPrice, setsellPrice] = useState(state ? state?.sell_price : ParsedPair ? ParsedPair.sell_price : 0);
  const [sellAmount, setsellAmount] = useState(1);
  const [sellTotal, setsellTotal] = useState(0);
  const [infoPlaceOrder, setinfoPlaceOrder] = useState('LIMIT');
  const [coinFilter, setcoinFilter] = useState('ALL');
  const [BuyCoinBal, setBuyCoinBal] = useState();
  const [SellCoinBal, setSellCoinBal] = useState();
  const [openOrders, setopenOrders] = useState([]);
  const [orderType, setorderType] = useState('All');
  const [pastOrderType, setpastOrderType] = useState('All');
  const [buyTriggerPrice, setbuyTriggerPrice] = useState();
  const [sellTrigegrPrice, setsellTrigegrPrice] = useState();
  const [priceDecimal, setpriceDecimal] = useState(8);
  const [pastOrders, setpastOrders] = useState([]);
  const [pastOrder2, setpastOrder2] = useState([]);
  const [makerFee, setmakerFee] = useState('');
  const [takerFee, settakerFee] = useState('');
  const [favCoins, setfavCoins] = useState([]);
  const [sellOrderPrice, setsellOrderPrice] = useState(undefined);
  const [buyOrderPrice, setbuyOrderPrice] = useState(undefined);
  const [priceChange, setpriceChange] = useState();
  const [priceHigh, setpriceHigh] = useState();
  const [priceLow, setpriceLow] = useState();
  const [volume, setvolume] = useState();
  const [decimals, setdecimals] = useState();
  const [socket, setSocket] = useState(null);
  // const dataContainerRef = useRef(null);
  // const dataContainerRef2 = useRef(null);
  // const dataContainerRef3 = useRef(null);
  // const dataContainerRef4 = useRef(null);
  // ********* Socket Connection and Data fetch ********** //
  // ********* Socket Connection and Data fetch ********** //
  const navigate = useNavigate();

  const nextPage = () => {
    navigate('/kyc');
  };
  useEffect(() => {
    const newSocket = io(`${ApiConfig?.webSocketUrl}`, {
      transports: ['websocket'],
      upgrade: false,
      rejectUnauthorized: false,
      reconnectionAttempts: 3,
      debug: true
    });

    setSocket(newSocket);
    if (state && !ParsedPair) {
      let payload = {
        'message': 'exchange',
        'userId': userId,
        'base_currency_id': state?.base_currency_id,
        'quote_currency_id': state?.quote_currency_id,
      }
      newSocket.emit('message', payload);

    } else if (ParsedPair && !state) {
      let payload = {
        'message': 'exchange',
        'userId': userId,
        'base_currency_id': ParsedPair?.base_currency_id,
        'quote_currency_id': ParsedPair?.quote_currency_id,
      }
      newSocket.emit('message', payload);
    } else {
      let payload = {
        'message': 'exchange',
        'userId': userId,
      }
      newSocket.emit('message', payload);
    }
    return () => {
      newSocket.disconnect();
    };
  }, []);


  // console.log(ParsedPair, "11111111111111111122222222222222222222");

  useEffect(() => {
    if (socket) {
      socket.on('message', (data) => {
        setAllData(data);
        setBuyOrders(data?.buy_order?.slice(-10));
        setRecentTrade(data?.recent_trades?.slice(-10)?.reverse());
        setSellOrders(data?.sell_order?.slice(-10));
        setBuyCoinBal(data?.balance?.quote_currency_balance);
        setSellCoinBal(data?.balance?.base_currency_balance);
        setopenOrders(data?.open_orders?.reverse());
      });
    }
  }, [socket]);


  // ********* Auto Select Coin Pair after Socket Connection ********** //
  useEffect(() => {
    if (!SelectedCoin && CoinPairDetails) {
      setsellOrderPrice(undefined);
      setbuyOrderPrice(undefined);
      setSelectedCoin(CoinPairDetails[0]);
      setbaseCurId(CoinPairDetails[0]?.base_currency_id);
      setquoteCurId(CoinPairDetails[0]?.quote_currency_id);
      setbuyprice(CoinPairDetails[0]?.buy_price);
      setsellPrice(CoinPairDetails[0]?.sell_price);
      coinDetails(CoinPairDetails[0]?.base_currency_id);
      let payload = {
        'message': 'exchange',
        'userId': userId,
        'base_currency_id': CoinPairDetails[0]?.base_currency_id,
        'quote_currency_id': CoinPairDetails[0]?.quote_currency_id,
      }
      socket.emit('message', payload);
      // countDecimalPlaces(CoinPairDetails[0]?.buy_price);
    }
  }, [CoinPairDetails, infoPlaceOrder]);


  // ******** Check Decimals Of Buy Price ************//
  function countDecimalPlaces(number) {
    const decimalString = number.toString();
    const decimalIndex = decimalString.indexOf('.');
    if (decimalIndex !== -1) {
      setdecimals(decimalString.length - decimalIndex - 1)
    } else {
      setdecimals(0)
    }
  };


  // ********* Generate Dynamic Options for Price Decimals In order book ******** //
  const generateOptions = () => {
    const options = [];
    for (let i = decimals; i >= 1; i--) {
      const value = Math.pow(10, -i);
      options.push(
        <option selected={i === decimals} key={i} value={i}>
          {value.toFixed(i)}
        </option>
      );
    }
    return options;
  };

  // ********* Filter Past Orders List by Item Side ********** //
  useEffect(() => {
    let filteredData = pastOrder2?.filter((item) => {
      return pastOrderType === item?.side || pastOrderType === 'All'
    })
    setpastOrders(filteredData ? filteredData : [])
  }, [pastOrderType]);


  // ********* Update Buy Sell 24HChange High Low Volume Price********** //
  useEffect(() => {
    let filteredData = AllData?.pairs?.filter((item) => {
      return item?.base_currency_id === SelectedCoin?.base_currency_id
    })
    if (filteredData) {
      setbuyprice(filteredData[0]?.buy_price);
      setsellPrice(filteredData[0]?.sell_price);
      setpriceChange(filteredData[0]?.change);
      setpriceHigh(filteredData[0]?.high);
      setpriceLow(filteredData[0]?.low);
      setvolume(filteredData[0]?.volume);
    }
  }, [AllData]);


  // ********* Search Coins ********** //
  useEffect(() => {
    let filteredData = AllData?.pairs?.filter((item) => {
      return item?.base_currency?.toLowerCase()?.includes(search?.toLowerCase()) || item?.quote_currency?.toLowerCase()?.includes(search?.toLowerCase())
    })
    setCoinPairDetails(filteredData)

  }, [search, AllData]);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    token && favoriteList();
  }, []);


  const handleOrderPlace = async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.placeOrder(infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage('Order Placed Successfully!!')
          setbuyOrderPrice(undefined);
          setsellOrderPrice(undefined);
          setbuyamount(1);
          setsellAmount(1);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message)
      }
    })
  };

  const handlePastOrder = async (base_currency_id, quote_currency_id) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.pastOrder(base_currency_id, quote_currency_id).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setpastOrders(result?.data)
          setpastOrder2(result?.data)
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    })
  };

  const cancelOrder = async (orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.cancelOrder(orderId).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage('Order Cancelled Successfully');
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message)
      }
    })
  };

  const coinDetails = async (currId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.coinDetails(currId).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setmakerFee(result?.data?.maker_fee);
          settakerFee(result?.data?.taker_fee);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message)
      }
    })
  };
  const handleAddFav = async (pairId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.favoriteCoin(pairId).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          favoriteList()
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message)
      }
    })
  };
  const favoriteList = async () => {
    await AuthService.favoriteList().then((result) => {
      if (result.success) {
        try {
          setfavCoins(result?.data?.pairs ? result?.data?.pairs : ['']);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  const handleSelectCoin = (data) => {
    sessionStorage.setItem('RecentPair', JSON.stringify(data))
    setsellOrderPrice(undefined);
    setbuyOrderPrice(undefined);
    setSelectedCoin(data);
    setbaseCurId(data?.base_currency_id);
    setquoteCurId(data?.quote_currency_id);
    setbuyprice(data?.buy_price);
    setsellPrice(data?.sell_price);
    coinDetails(data?.base_currency_id);
    let payload = {
      'message': 'exchange',
      'userId': userId,
      'base_currency_id': data?.base_currency_id,
      'quote_currency_id': data?.quote_currency_id,
    }
    socket.emit('message', payload);
    // countDecimalPlaces(data?.buy_price);
  };

  const handleOrderType = (e) => {
    setinfoPlaceOrder(e.target.value);
    if (e.target.value === 'MARKET') {
      setsellOrderPrice(undefined);
      setbuyOrderPrice(undefined);
      setbuyprice(SelectedCoin?.buy_price)
      setsellPrice(SelectedCoin?.sell_price)
    };
  };

  return (
    <>
      <div className="trade-wrapper spot login_bg pb-4">
        <div className="spot-container container-fluid">
          <div className="row g-2" >
            <div className="col-12 col-sm-6 col-lg-6 col-xl-3 col-xxl-2 order-2" >
              <div className="trade_card  mb-2" >
                <div className=" trade_tabs buy_sell_cards  buy_sell_row d-flex-between   ">
                  <h6 className="mb-0">{SelectedCoin?.base_currency}/{SelectedCoin?.quote_currency}</h6>
                  <div className='d-flex' >
                    <ul className="nav custom-tabs nav_order">
                      <li className="fav-tab"><a className="active" data-bs-toggle="tab" href="#all_orders"> <img alt='' src="images/order_1.svg" width="22" height="11" />
                      </a></li>
                      <li className="usdt-tab"><a data-bs-toggle="tab" href="#buy_orders"> <img alt='' src="images/order_1.svg" width="22" height="11" />  </a></li>
                      <li className="btc-tab"><a data-bs-toggle="tab" href="#sell_orders" className="me-0"> <img alt='' src="images/order_1.svg" width="22" height="11" />
                      </a></li>
                    </ul>
                    <div className='num-div' >
                      <select className="form-select num-select p-0 input-select" aria-label="Default select example" onClick={(e) => { setpriceDecimal(e.target.value) }}>
                        {/* {generateOptions()} */}
                        <option selected value={8}>
                          0.00000001
                        </option>
                        <option value={7}>
                          0.0000001
                        </option>
                        <option value={6}>
                          0.000001
                        </option>
                        <option value={5}>
                          0.00001
                        </option>
                        <option value={4}>
                          0.0001
                        </option>
                        <option value={3}>
                          0.001
                        </option>
                        <option value={2}>
                          0.01
                        </option>
                        <option value={1}>
                          0.1
                        </option>

                      </select>
                    </div>
                  </div>
                </div>
                <div className="tab-content buy_sell_row_price" >
                  <div className="tab-pane fade px-0  active show" id="all_orders">
                    <div className="price_card">
                      <div className="price_card_head">
                        <div>Price({SelectedCoin?.quote_currency})</div>
                        <div>Quantity({SelectedCoin?.base_currency})</div>
                        <div>Total({SelectedCoin?.quote_currency})</div>
                      </div>
                      <div className="price_card_body scroll_y" style={{ cursor: "pointer" }} >
                        {SellOrders.length > 0 ? SellOrders?.map((data) => {
                          return (
                            <div className="price_item_value" onClick={() => { setbuyamount(data?.remaining); infoPlaceOrder !== 'MARKET' && setbuyOrderPrice(data?.price) }}>
                              <span className="d-flex align-items-center text-danger "> {(data?.price).toFixed(priceDecimal)}</span>
                              <span className=""> {(data?.remaining).toFixed(8)}</span>
                              <span className="text-danger"> {(data?.price * data?.remaining).toFixed(8)}</span>
                            </div>
                          )
                        }) : <p className="text-center" style={{ textAlign: "center" }}>
                          No data Available</p>
                        }
                      </div>
                      <div className="mrkt_trde_tab justify-content-center" >
                        <b className={priceChange >= 0 ? "text-success" : "text-danger"} >{parseFloat(buyprice?.toFixed(8))}</b>
                        <i className={priceChange >= 0 ? 'ri-arrow-up-line ri-xl mx-3 text-success' : 'ri-arrow-down-line ri-xl mx-3'}></i>
                        <span>{parseFloat(priceChange?.toFixed(8))}</span>
                      </div>
                      {/* <div className="price_card_head">

                                              <div>Price({SelectedCoin?.quote_currency})</div>
                                              <div>Quantity({SelectedCoin?.base_currency})</div>
                                              <div>Total({SelectedCoin?.quote_currency})</div>
                                          </div> */}
                      {/* scroll_y */}
                      <div className="price_card_body scroll_y" style={{ cursor: "pointer" }} >
                        {BuyOrders.length > 0 ?
                          BuyOrders?.map((data) => {
                            return (
                              <>
                                <div className="price_item_value" onClick={() => { setsellAmount(data?.remaining); infoPlaceOrder !== 'MARKET' && setsellOrderPrice(data?.price) }}>
                                  <span className={"text-success d-flex lign-items-center"} >{(data?.price).toFixed(priceDecimal)}</span>
                                  <span className="">{(data?.remaining).toFixed(8)}</span>
                                  <span className="text-success">{(data?.price * data?.remaining).toFixed(8)}</span>
                                </div>
                              </>
                            )
                          }) : <p className="text-center" style={{ textAlign: "center" }}>
                            No data Available</p>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade px-0 " id="buy_orders">
                    <div className="price_card">
                      <div className="price_card_head">
                        <div>Price({SelectedCoin?.quote_currency})</div>
                        <div>Quantity({SelectedCoin?.base_currency})</div>
                        <div>Total({SelectedCoin?.quote_currency})</div>
                      </div>
                      <div className="price_card_body scroll_y" style={{ cursor: "pointer" }} >
                        {BuyOrders.length > 0 ?
                          BuyOrders?.map((data) => {
                            return (
                              <>
                                <div className="price_item_value" onClick={() => { setsellAmount(data?.remaining); infoPlaceOrder !== 'MARKET' && setsellOrderPrice(data?.price) }}>
                                  <span className={"text-success d-flex lign-items-center"} >{(data?.price).toFixed(priceDecimal)}</span>
                                  <span className="">{(data?.remaining).toFixed(8)}</span>
                                  <span className="text-success">{(data?.price * data?.remaining).toFixed(8)}</span>
                                </div>
                              </>
                            )
                          }) : <p className="text-center" style={{ textAlign: "center" }}>
                            No data Available</p>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade px-0 " id="sell_orders">
                    <div className="price_card">
                      <div className="price_card_head">
                        <div>Price({SelectedCoin?.quote_currency})</div>
                        <div>Quantity({SelectedCoin?.base_currency})</div>
                        <div>Total({SelectedCoin?.quote_currency})
                        </div>
                      </div>
                      <div className="price_card_body scroll_y" style={{ cursor: "pointer" }} >
                        {SellOrders.length > 0 ? SellOrders?.map((data) => {
                          return (
                            <div className="price_item_value" onClick={() => { setbuyamount(data?.remaining); infoPlaceOrder !== 'MARKET' && setbuyOrderPrice(data?.price) }}>
                              <span className="d-flex align-items-center text-danger "> {(data?.price).toFixed(priceDecimal)}</span>
                              <span className=""> {(data?.remaining).toFixed(8)}</span>
                              <span className="text-danger"> {(data?.price * data?.remaining).toFixed(8)}</span>
                            </div>
                          )
                        }) : <p className="text-center" style={{ textAlign: "center" }}>
                          No data Available</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="trade_card   mb-2" >
                <div className=" trade_tabs buy_sell_cards  buy_sell_row d-flex-between   ">
                  <h6 className="mb-0">My Assets</h6>
                </div>
                <div className="price_card_body " >
                  <div className="actv_data d-flex justify-content-between align-items-center mb-3">
                    <div className="actv_data_name">
                      <p className="mb-0 text-uppercase"> <strong>{SelectedCoin?.base_currency}</strong></p></div>
                    <div className="actv_sorting"><p className="mb-0 text-success ">{SellCoinBal ? parseFloat(SellCoinBal).toFixed(8) : 0}</p></div>
                  </div>
                  <div className="actv_data d-flex justify-content-between align-items-center mb-3">
                    <div className="actv_data_name">
                      <p className="mb-0 text-uppercase"> <strong>{SelectedCoin?.quote_currency}</strong></p></div>
                    <div className="actv_sorting"><p className="mb-0 text-success ">{BuyCoinBal ? parseFloat(BuyCoinBal).toFixed(8) : 0}</p></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-12 col-xl-6  col-xxl-8 order-1 order-xl-2" >
              <div className="trade_card p-3 mb-2" >
                <div className="container-chart-briefing-info">
                  <div className="display-name-asset wm-50">
                    <h6 className="mb-0 displayName" id="display_name" >
                      <span> {`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</span></h6>
                  </div>
                  {SelectedCoin ? <>
                    <dl className="wm-50"><dt>Price</dt><dd className="text-success">{buyprice}</dd></dl>

                    <dl className="wm-50"><dt>24H Change</dt>
                      <dd className={priceChange >= 0 ? "text-success" : "text-danger"}>{(parseFloat(priceChange).toFixed(8))}</dd>
                    </dl>
                    <dl className="wm-50"><dt>24h High</dt>
                      <dd className="text-success" >{parseFloat(priceHigh?.toFixed(8))}</dd>
                    </dl>
                    <dl className="wm-50"><dt>24h Low</dt>
                      <dd className="text-danger" >{parseFloat(priceLow?.toFixed(8))}</dd>
                    </dl>
                    <dl className="wm-50"><dt>24H Vol</dt>
                      <dd>{parseFloat(volume?.toFixed(8))}</dd>
                    </dl></> : ''}
                </div>
              </div>
              <div className="trade_card trade_chart mb-2 p-0"  >
                <TVChartContainer theme={Themes.DARK} width={'100%'} height={'500'} symbol={`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`} />

                {/* <ReactTradingviewWidget theme={Themes.DARK} width={'100%'} height={'500'} symbol={`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`} /> */}
              </div>
              <div className="trade_card trade_chart mb-2  p-2">
                <div className="d-flex align-items-center justify-content-between mb-1" >
                  <h6 className="title mb-0">Order Book</h6>
                  <div className="field-box">
                    <select className=" mb-0 select_mini" name="infoPlaceOrder" onChange={handleOrderType} value={infoPlaceOrder}>
                      <option value="LIMIT">Limit</option>
                      <option value="MARKET">Market</option>
                      {/* <option value="STOPLIMIT">Stop Limit</option> */}
                    </select>
                  </div>
                </div>
                <div className="row" >
                  <div className="col-md-6" >
                    <form action="" className="buysellform data-buy">
                      <p className="text-success" ><b>Avail Lowest Price </b> </p>
                      {infoPlaceOrder === 'STOPLIMIT' &&
                        <div className="input-group mb-3">
                          <input type="number" id="buyPrice" name="buyPrice" className="form-control" aria-label="Amount (to the nearest dollar)" placeholder='Trigger Price' value={buyTriggerPrice} onChange={(e) => { setbuyTriggerPrice(e.target.value) }} />
                          <span className="input-group-text text-start"><small>Trigger Price - {SelectedCoin?.quote_currency}</small></span>
                        </div>}
                      <div className="input-group mb-3">
                        <input type="number" id="buyPrice" name="buyPrice" className="form-control" aria-label="Amount (to the nearest dollar)" disabled={infoPlaceOrder === 'MARKET'} value={buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice} onChange={(e) => { setbuyOrderPrice(e.target.value) }} />
                        <span className="input-group-text text-start"><small>  {SelectedCoin?.quote_currency}</small></span>
                      </div>
                      <div className="input-group mb-3">
                        <input type="number" name="buyAmount" id="buyAmount" aria-invalid="true" className="form-control" aria-label="Amount (to the nearest dollar)" onChange={(e) => { setbuyamount(e.target.value); setbuytotal(+buyprice * +e.target.value) }} value={buyamount} />
                        <span className="input-group-text text-start"><small> {SelectedCoin?.base_currency}</small></span>
                      </div>
                      <div className="input-group mb-3">
                        <input type="number" id="buy_order_total" name="buy_order_total" className="form-control" aria-label="Amount (to the nearest dollar)" value={((buyOrderPrice !== undefined || buyOrderPrice) && buyamount) ? +buyOrderPrice * +buyamount : (buyprice && buyamount) ? +buyprice * +buyamount : 0} />
                        <span className="input-group-text text-start"><small>Total</small></span>
                      </div>
                      <div className="row align-items-center" >
                        <div className="col-md-6" >
                          <div className="btn-group btn-group-mini " role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio125" autoComplete="off" />
                            <label className="btn btn-outline-success" htmlFor="btnradio125" onClick={() => { setbuyamount((((BuyCoinBal / 100) * 25) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }} >25%</label>
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" />
                            <label className="btn btn-outline-success" htmlFor="btnradio250" onClick={() => { setbuyamount((((BuyCoinBal / 100) * 50) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }}>50%</label>
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" />
                            <label className="btn btn-outline-success" htmlFor="btnradio375" onClick={() => { setbuyamount((((BuyCoinBal / 100) * 75) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }}>75%</label>
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio3100" autoComplete="off" />
                            <label className="btn btn-outline-success" htmlFor="btnradio3100" onClick={() => { setbuyamount((((BuyCoinBal / 100) * 100) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }}>100%</label>
                          </div>
                        </div>
                        <div className="col-md-6" >
                          {kycStatus == 2 ? <button type='button' className="btn custom-btn btn-success  w-100 my-3 my-md-0"
                            data-bs-toggle="modal" data-bs-target="#BuyConfirm">
                            Buy {SelectedCoin?.base_currency}
                          </button> : <button type='button' className="btn custom-btn btn-success  w-100 my-3 my-md-0" onClick={nextPage}>
                            KYC not Verrified
                          </button>}
                        </div>


                      </div>
                    </form>
                  </div>
                  <div className="col-md-6" >
                    <form action="" className="buysellform data-sell">
                      <p className="text-danger" > <b>Avail Highest Price</b> </p>
                      {infoPlaceOrder === 'STOPLIMIT' &&
                        <div className="input-group mb-3">
                          <input type="number" id="buyPrice" name="buyPrice" className="form-control" aria-label="Amount (to the nearest dollar)" placeholder='Trigger Price' value={sellTrigegrPrice} onChange={(e) => { setsellTrigegrPrice(e.target.value) }} />
                          <span className="input-group-text text-start"><small>Trigger Price - {SelectedCoin?.quote_currency}</small></span>
                        </div>}
                      <div className="input-group mb-3">
                        <input type="number" id="buyPrice" name="buyPrice" className="form-control" aria-label="Amount (to the nearest dollar)" value={sellOrderPrice !== undefined || sellOrderPrice ? sellOrderPrice : sellPrice} onChange={(e) => { setsellOrderPrice(e.target.value) }} disabled={infoPlaceOrder === 'MARKET'} />
                        <span className="input-group-text text-start" ><small> {SelectedCoin?.quote_currency}</small></span>
                      </div>
                      <div className="input-group mb-3">
                        <input type="number" name="buyAmount" id="buyAmount" aria-invalid="true" className="form-control" aria-label="Amount (to the nearest dollar)" onChange={(e) => { setsellAmount(e.target.value); setsellTotal(+buyprice * +e.target.value) }} value={sellAmount} />
                        <span className="input-group-text text-start"><small>{SelectedCoin?.base_currency}</small></span>
                      </div>
                      <div className="input-group mb-3">
                        <input type="number" id="buy_order_total" name="buy_order_total" className="form-control" aria-label="Amount (to the nearest dollar)" value={((sellOrderPrice !== undefined || sellOrderPrice) && sellAmount) ? +sellOrderPrice * +sellAmount : (sellPrice && sellAmount) ? +sellPrice * +sellAmount : 0} />
                        <span className="input-group-text text-start"><small>Total</small></span>
                      </div>
                      <div className="row align-items-center" >
                        <div className="col-md-6" >
                          <div className="btn-group btn-group-mini " role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio15" autoComplete="off" />
                            <label className="btn btn-outline-danger" htmlFor="btnradio15" onClick={() => { setsellAmount((SellCoinBal / 100) * 25) }}>25%</label>
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio20" autoComplete="off" />
                            <label className="btn btn-outline-danger" htmlFor="btnradio20" onClick={() => { setsellAmount((SellCoinBal / 100) * 50) }}>50%</label>
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio35" autoComplete="off" />
                            <label className="btn btn-outline-danger" htmlFor="btnradio35" onClick={() => { setsellAmount((SellCoinBal / 100) * 75) }}>75%</label>
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio300" autoComplete="off" />
                            <label className="btn btn-outline-danger" htmlFor="btnradio300" onClick={() => { setsellAmount((SellCoinBal / 100) * 100) }}>100%</label>
                          </div>
                        </div>
                        <div className="col-md-6" >
                          {kycStatus == 2 ? <button type='button' className="btn custom-btn btn-danger  w-100 my-3 my-md-0"
                            data-bs-toggle="modal" data-bs-target="#SellConfirm">
                            Sell {SelectedCoin?.base_currency}
                          </button> : <button type='button' className="btn custom-btn btn-danger  w-100 my-3 my-md-0" onClick={nextPage}>
                            KYC not Verrified
                          </button>}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-12 text-center mt-1" ><small><small className="text-gray">Fee: Maker: {makerFee}% | Taker: {takerFee}% </small></small></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 col-xl-3 col-xxl-2 order-3 order-xl-3 " >
              <div className="trade_card " >
                <div className=" trade_tabs buy_sell_cards   ">
                  <ul className="nav custom-tabs nav_order">
                    {token && <li className="favt-tab"><a data-bs-toggle="tab" href="#tab_fav" onClick={() => { setcoinFilter('FAV') }}>FAV. </a></li>}
                    <li className="all-tab"><a className="active" data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('ALL') }}> All </a></li>
                    <li className="usdt-tab"><a data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('USDT') }}>USDT </a></li>
                    <li className="btc-tab"><a data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('BTC') }} className="">BTC</a></li>
                  </ul>
                </div>
                <div className="spot-list-search">
                  <div className="ivu-input" >
                    <i className="ri-search-2-line"></i>
                    <input autoComplete="off" spellCheck="false" type="search" placeholder="Search" className=""
                      onChange={(e) => { setsearch(e.target.value) }} value={search} />
                  </div>
                </div>

                <div className="price_card">
                  <div className="price_card_head">
                    <div>Pair</div>
                    <div>Price</div>
                    <div>24H%</div>
                  </div>

                  <div className="price_card_body tab-content scroll_y" style={{ cursor: "pointer" }}>
                    <div className="tab-pane px-0" id="tab_fav" >
                      {CoinPairDetails ? CoinPairDetails?.map((data) => {
                        return (
                          favCoins.includes(data?.base_currency_id) && <div className="price_item_value"
                          ><span className="d-flex align-items-center">
                              <i className={favCoins.includes(data?.base_currency_id) ? "ri ri-star-fill text-warning me-1 ri-xl" : "ri ri-star-line me-1 ri-xl"} onClick={() => { handleAddFav(data?.base_currency_id) }} >
                              </i> <dt onClick={() => handleSelectCoin(data)}>{`${data?.base_currency}/${data?.quote_currency}`}</dt></span><span className="">{data?.buy_price}</span><span className={data?.change >= 0 ? "text-success" : "text-danger"}>{parseFloat(data?.change.toFixed(8))}%</span></div>

                        )
                      }) : null}
                    </div>
                    <div className="tab-pane px-0 active" id="tab_all" >
                      {CoinPairDetails ? CoinPairDetails?.map((data) => {
                        return (
                          (data?.quote_currency === coinFilter || coinFilter === 'ALL') &&
                          <div className="price_item_value" >
                            <span className="d-flex align-items-center">
                              {token && <i className={favCoins.includes(data?.base_currency_id) ? "ri ri-star-fill text-warning me-1 ri-xl" : "ri ri-star-line me-1 ri-xl"} onClick={() => { handleAddFav(data?.base_currency_id) }} >
                              </i>} <dt onClick={() => handleSelectCoin(data)}>{`${data?.base_currency}/${data?.quote_currency}`}</dt> </span><span className="">{data?.buy_price}</span><span className={data?.change >= 0 ? "text-success" : "text-danger"}>{parseFloat(data?.change.toFixed(8))}%</span></div>
                        )
                      }) : null}
                    </div>

                  </div>
                  <div className="mrkt_trde_tab " >
                    <span>Market Traders</span>
                  </div>
                  <div className="price_card_head">
                    <div>Price({SelectedCoin?.quote_currency})</div>
                    <div>Quantity({SelectedCoin?.base_currency})</div>
                    <div>Time</div>
                  </div>
                  <div className="price_card_body scroll_y" style={{ cursor: "pointer" }}>
                    {RecentTrade.length > 0 ?
                      RecentTrade.map((item, index) =>
                        <>
                          <div className="price_item_value "><span className={item?.side === "BUY" ? "text-success d-flex align-items-center" : "text-danger d-flex align-items-center"}> {parseFloat((item?.price).toFixed(8))}</span><span className="">{(item?.quantity).toFixed(8)}</span><span > {item.updatedAt && moment(item.updatedAt).format("HH:MM:ss")}</span></div>
                        </>
                      ) : <p className="text-center" style={{ textAlign: "center" }}>
                        No data Available</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-12 col-xl-12 order-4" >
            <div className="trade_card mb-0">
              <div className="d-flex-between trade_tabs w-100 align-items-center buy_sell_cards   ">
                <ul className="nav custom-tabs order_his_tabs ">
                  <li className="buysell-tab"><a className="active" data-bs-toggle="tab" href="#open_orders">Open Orders</a></li>
                  <li className="buysell-tab"><a data-bs-toggle="tab" href="#past_orders" onClick={() => { token && handlePastOrder(baseCurId, quoteCurId) }}>Past Orders </a></li>
                </ul>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade show active  px-0" id="open_orders">
                  <div className="scroll_y" style={{ height: '500px' }}>
                    <div className='table-responsive'>
                      <table className="table table_home ">
                        <thead>
                          <tr>
                            <th>Trading Pair</th>
                            <th> Date</th>
                            <th> Type</th>
                            <th> <div className="field-box">
                              <select className=" mb-0 form-select-sm" name="" value={orderType} onChange={(e) => { setorderType(e.target.value) }}>
                                <option value="All">All</option>
                                <option value="BUY">Buy</option>
                                <option value="SELL">Sell</option>
                              </select>
                            </div></th>
                            <th> Price</th>
                            <th>Amount</th>
                            <th>Remaining</th>
                            <th>Filled</th>
                            <th>Total</th>
                            <th> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {openOrders?.length > 0 ? openOrders.map((item, index) =>
                            (orderType === item?.side || orderType === 'All') &&
                            <tr>
                              <td>{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                              <td>{item.updatedAt && moment(item.updatedAt).format("DD-MM-YYYY : HH:MM")}</td>
                              <td>{item?.order_type}</td>
                              <td>{item?.side}</td>
                              <td>{item?.price.toFixed(8)}</td>
                              <td>{item?.quantity.toFixed(8)}</td>
                              <td>{item?.remaining.toFixed(8)}</td>
                              <td>{item?.filled.toFixed(8)}</td>
                              <td>{(item?.price * item?.quantity).toFixed(8)}</td>
                              <td>
                                <a href='#/' className=" btn btn-danger btn-sm btn-icon" onClick={() => { cancelOrder(item?._id) }}><i className="ri-delete-bin-6-line pr-0"></i>
                                </a>
                              </td>
                            </tr>
                          ) : <tr rowSpan="5">
                            <td colSpan="12">
                              <p style={{ textAlign: "center" }}>
                                No data Available
                              </p>
                            </td>
                          </tr>
                          }
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div className="tab-pane fade show  px-0 " id="past_orders">
                  <div className="scroll_y">
                    <div className='table-responsive'>
                      <table className="table table_home ">
                        <thead>
                          <tr>
                            <th>Trading Pair</th>
                            <th>Date</th>
                            <th><div className="field-box">
                              <select className=" mb-0 form-select-sm" name="" value={pastOrderType} onChange={(e) => { setpastOrderType(e.target.value) }}>
                                <option value="All">All</option>
                                <option value="BUY">Buy</option>
                                <option value="SELL">Sell</option>
                              </select>
                            </div></th>
                            <th>Average Filled Price</th>
                            <th>Executed</th>
                            <th>Total</th>
                            <th>Fee</th>
                            <th>Order Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pastOrders.length > 0 ? pastOrders.map((item, index) =>
                            <tr>
                              <td>{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                              <td>{item.updatedAt && moment(item.updatedAt).format("DD-MM-YYYY : HH:MM")}</td>
                              <td>{item?.side}</td>
                              <td>{item?.price.toFixed(8)}</td>
                              <td>{item?.quantity.toFixed(8)}</td>
                              <td>{(item?.quantity * item?.price).toFixed(8)}</td>
                              <td>{item?.fee.toFixed(8)}</td>
                              <td>{item?.order_type}</td>
                            </tr>
                          ) : <tr rowSpan="5">
                            <td colSpan="12">
                              <p style={{ textAlign: "center" }}>
                                No data Available
                              </p>
                            </td>
                          </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="modal fade" id="BuyConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          {/* <form className="modal-content">
            <div className="modal-header flex-column p-0 no-border">
              {<h3 className="modal-title" id="placeBitLaebl"> Do you want to place this order?</h3 >}
              <button button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body p-md-4 pt-md-5 p-2 text-center">
              <h2 className="al_text" > Do you want to place this order?</h2>
              <div className='row justify-content-center '>
                <div className="col-md-10   " >
                  <button type="button" className="next_btn btn-success m-auto w-100 btn btn-block" data-bs-dismiss="modal" onClick={() => handleOrderPlace(infoPlaceOrder, buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice, buyamount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'BUY')}> <span>Buy</span>
                  </button>
                </div>
                <div className="col-md-10 my-3 " >
                  <button type="button" className="next_btn btn-glass m-auto w-100 btn btn-block" data-bs-dismiss="modal"> <span>Cancel</span>
                  </button>
                </div>
                <div className="col-md-10  " >
                  <button type="button" className="next_btn btn-link skip-btn m-auto w-100 btn btn-block" data-bs-dismiss="modal"> <span>Skip  </span>
                  </button>
                </div>
              </div>
            </div>
          </form> */}

          <form className="modal-content" >
            <div className="modal-header flex-column p-0 no-border">
              {/* <h3 className="modal-title" id="placeBitLaebl"> Do you want to place this order?</h3 > */}
              <but button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                className="ri-close-fill"></i></but>
            </div>
            <div className="modal-body p-md-4 pt-md-5 p-2 text-center">
              <h2 className="al_text" > Do you want to place this order?</h2>
              <div className='row justify-content-center '>
                <div className="col-md-10   " >
                  <button type="button" className="next_btn btn-success m-auto w-100 btn btn-block" data-bs-dismiss="modal" onClick={() => handleOrderPlace(infoPlaceOrder, buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice, buyamount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'BUY')}> <span>Buy {SelectedCoin?.base_currency}</span>
                  </button>
                </div>
                <div className="col-md-10 my-3 " >
                  <button type="button" className="next_btn btn-glass m-auto w-100 btn btn-block" data-bs-dismiss="modal"> <span>Cancel</span>
                  </button>
                </div>
                {/* <div className="col-md-10  " >
                  <button type="button" className="next_btn btn-link skip-btn m-auto w-100 btn btn-block" data-bs-dismiss="modal"> <span>Skip  </span>
                  </button>
                </div> */}
              </div>
            </div>
          </form>
          14:30
        </div >
      </div >
      <div className="modal fade" id="SellConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <form className="modal-content">
            <div className="modal-header flex-column p-0 no-border">
              {/* <h3 className="modal-title" id="placeBitLaebl"> Do you want to place this order?</h3 > */}
              <button button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body p-md-4 pt-md-5 p-2 text-center">
              <h2 className="al_text" > Do you want to place this order?</h2>
              <div className='row justify-content-center '>
                <div className="col-md-10" >
                  <button type="button" className="next_btn btn-danger m-auto w-100 btn btn-block" data-bs-dismiss="modal" onClick={() => handleOrderPlace(infoPlaceOrder, sellOrderPrice !== undefined || sellOrderPrice ? sellOrderPrice : sellPrice, sellAmount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'SELL')} > <span>Sell {SelectedCoin?.base_currency}</span>
                  </button>
                </div>
                <div className="col-md-10 my-3 " >
                  <button type="button" className="next_btn btn-glass m-auto w-100 btn btn-block" data-bs-dismiss="modal"> <span>Cancel</span>
                  </button>
                </div>
                {/* <div className="col-md-10  " >
                  <button type="button" className="next_btn btn-link skip-btn m-auto w-100 btn btn-block" data-bs-dismiss="modal"> <span>Skip  </span>
                  </button>
                </div> */}
              </div>
            </div>
          </form>


          14:30

        </div >
      </div >
    </>
  )
}

export default TradePage;
