import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import { widget } from '../charting_library';
import Datafeed from './datafeed';
import logo from '.././../assets/trade_logo.png'
import { ProfileContext } from '../../Context';



export default function TVChartContainer({ symbol }) {
	const { theme } = useContext(ProfileContext);
	const [tvWidget, setTvWidget] = useState();
	const functCheckRef = useRef(true);

	const getChart = (symbol) => {
		let Theme = localStorage.getItem('Theme');
		const widgetOptions = {
			symbol: `${symbol}`,
			interval: '1',
			fullscreen: false,
			timezone: 'Asia/Kolkata',
			container: 'TVChartContainer',
			datafeed: Datafeed,
			has_intraday: true,
			library_path: '/charting_library/',
			pricescale: 100000000,
			intraday_multipliers: ['1', '60'],
			custom_css_url: 'css/style.css',
			time_frames: [
				{ text: '6M', resolution: 'D', description: '6 Month' },
				{ text: '1Y', resolution: 'W', description: '1 Year' },
				{ text: '5Y', resolution: 'W', description: '5 Year' },
			],
			theme: Theme === 'Dark' ? 'light' : 'dark',
			overrides: {
				"symbolInfo.priceFormatter": {
					precision: 4
				},
				"paneProperties.background": Theme === 'Dark' ? '#b7d5ff' : "#0d162e",
				"paneProperties.backgroundType": "solid",
			},

			loading_screen: {
				backgroundColor: "#0d162e",
			},
			time_scale: {
				min_bar_spacing: 20
			},
			logo: {
				image: logo,
				link: "https://charlieexchange.co/trade"
			},

			// enabled_features: ["hide_left_toolbar_by_default",],
			disabled_features: ["use_localstorage_for_settings", "adaptive_logo", "border_around_the_chart"]

		};

		setTvWidget(new widget(widgetOptions));
	};

	useEffect(() => {
		if (symbol.split('/')[0] !== 'undefined') {
			if (functCheckRef.current) {
				getChart(symbol);
			}
			functCheckRef.current = false;
		};
	}, [symbol]);

	useEffect(() => {
		if (tvWidget) {
			tvWidget.onChartReady(() => {
				const chart = tvWidget.chart();
				if (chart) {
					chart.setSymbol(symbol, () => null);
				}
			});
		}
	}, [symbol]);

	return (
		<>
			<div id={'TVChartContainer'} />
		</>

	);
}