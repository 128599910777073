import { Link } from "react-router-dom";
// import LoaderHelper from "../../../customComponent/";
import AuthService from "../../../api/services/AuthService";
// import { alertErrorMessage, alertSuccessMessage } from "../../components/CustomAlertMessage";
//import { useData } from "../../context";
import { useEffect, useState } from "react";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";

export const Levels = () => {
  const userId = sessionStorage.getItem("userId");
  const [levelData, setLevelData] = useState([]);

  useEffect(() => {
    levelUserList1(userId);
  }, []);

  const uniqueData = levelData.filter((item, index, self) => {
    return index === self.findIndex((t) => t.rootuserId === item.rootuserId);
  });

  console.log(uniqueData, "uniqueData");

  const levelUserList1 = async (userId) => {
    //LoaderHelper.loaderStatus(true);
    await AuthService.levelUserList(userId).then(async (result) => {
      if (result?.data?.length > 0) {
        try {
          // LoaderHelper.loaderStatus(false);
          setLevelData((oldArray) => [...new Set([...oldArray, result])]);
        } catch {
          //LoaderHelper.loaderStatus(false);
        }
      } else {
        // LoaderHelper.loaderStatus(false);
      }
    });
  };
  const handleLevels = () => {
    return uniqueData.length > 0 ? (
      uniqueData?.map((data) => {
        return (
          <div className="tree_row">
            <div className="user_cass">
              <img
                src={
                  data?.rootprofilepicture
                    ? `${ApiConfig.baseUrl +
                    "uploads/" +
                    data?.rootprofilepicture
                    }`
                    : "images/profilelogo.png"
                }
                alt=""
              />
              <span>
                <span> {data?.rootfirstName} </span>
                {data?.rootlastName}
              </span>
              <small>ID: {data?.rootuserId} </small>
            </div>
            <ul
              className="tree_ul"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {data.data.length > 0
                ? data?.data?.map((data) => {
                  return (
                    <>
                      <li onClick={() => levelUserList1(data?._id)}>
                        <div className="user_cass addedCss">
                          <img
                            src={
                              data?.profilepicture
                                ? `${ApiConfig.baseUrl +
                                "uploads/" +
                                data?.profilepicture
                                }`
                                : "images/profilelogo.png"
                            }
                            alt=""
                          />
                          <span>
                            {" "}
                            {data?.firstName} {data?.lastName}{" "}
                          </span>
                          <small style={{ fontSize: "0.7rem" }}>
                            {" "}
                            User ID: {data?._id}{" "}
                          </small>
                          <small style={{ fontSize: "0.7rem" }}>
                            Kyc Status:{" "}
                            {data?.kycVerified === 1
                              ? "Pending"
                              : data?.kycVerified === 2
                                ? "Approved"
                                : data?.kycVerified === 3
                                  ? "Rejected"
                                  : "Not Submitted"}
                          </small>
                        </div>
                      </li>
                    </>
                  );
                })
                : ""}
            </ul>
          </div>
        );
      })
    ) : (
      <div className="favouriteData">
        <img
          src="images/no-data.svg"
          className="img-fluid"
          width="96"
          height="96"
          alt=""
        />
        <br />
        <p className="mt-3 mb-4"> No Data Found. </p>
      </div>
    );
  };

  return (
    <>
      <section class="page_wrapper">
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1" />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2" />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3" />
        <div class="container">
          <div class="inner text-center">
            <h1 class="title">Levels</h1>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Levels
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section class="pb-50 ">
        <div class="container">
          <div class="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
            <div className="col-lg-12 m-auto">
              <div className="card custom-tab-content h-100   ">
                <div className=" ">
                  <div className="tree_view_modal">{handleLevels()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// //////////////////////
