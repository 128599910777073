import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";

const NotificationPage = () => {
  useEffect(() => {
    handleNotifications();
  }, []);

  const [notifications, setNotifications] = useState([]);

  const handleNotifications = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notifications().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.data.length > 0) {
        try {
          setNotifications(result?.data?.reverse());
        } catch (error) {
          console.log(error);
        }
      } else {
        alertErrorMessage(result.message)
      }
    });
  };

  return (
    <>
      <section class="page_wrapper">
        <img
          src="images/shape/1-light.png"
          alt="shape"
          className="shape shape-1  "
        />
        <img
          src="images/shape/2-light.png"
          alt="shape"
          className="shape shape-2  "
        />
        <img
          src="images/shape/3-light.png"
          alt="shape"
          className="shape shape-3"
        />

        <div class="container">
          <div class="inner text-center">
            <h1 class="title">Notifications</h1>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Notifications
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section class="pb-50">
        <div class="container">
          <div class="tab-content custom-tab-content p-0">
            <div class="tab-pane fade show container active  table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
              id="funds">
              <div class="table-responsive">
                <table class="table ">
                  <thead style={{ backgroundColor: "darkgoldenrod" }}>
                    <tr>
                      <th>Sr No.</th>
                      <th>Date</th>
                      <th>Title</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  {notifications.length > 0 ? (
                    notifications.map((value, index) => (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{moment(value?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}</td>
                          <td>{value?.title}</td>
                          <td>{value?.message}</td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <tr rowSpan="5">
                      <td colSpan="6">
                        <p style={{ textAlign: "center" }}>No data Available</p>
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default NotificationPage;
