import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function alertErrorMessage(message) {
  if (!toast.isActive('Toasterror')) {
    toast.error(message ? message?.toUpperCase() : 'Network Error', {
      toastId: 'Toasterror',
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      closeButton: false,
    });
  }
}

function alertSuccessMessage(message) {
  if (!toast.isActive('Toastsuccess')) {
    toast.success(message ? message?.toUpperCase() : 'Success', {
      toastId: 'Toastsuccess',
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      closeButton: false
    });
  }
}

function alertWarningMessage(message) {
  if (!toast.isActive('ToastWarning')) {
    toast.info(message ? message?.toUpperCase() : 'Oops', {
      toastId: 'ToastWarning',
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    })
  };
}



export { alertErrorMessage, alertSuccessMessage, alertWarningMessage };