import React, { useEffect, useState } from "react";
import { alertSuccessMessage, alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import HomeService from "../../../api/services/HomeService";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { useNavigate, Link } from "react-router-dom";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton, LinkedinIcon, FacebookIcon, WhatsappIcon, TwitterIcon, } from "react-share";

const ReferralPage = () => {
  const navigate = useNavigate();

  const { appUrl } = ApiConfig;
  const [totalReferrals, setTotalReferrals] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const link = "https://flexibits.io/" + "signup?reffcode=" + referralLink;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    handleReferralData();
    handleReferralLink();
  }, []);

  const handleReferralData = async () => {
    LoaderHelper.loaderStatus(true)
    await HomeService.referralData().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false)
        setTotalReferrals(result?.data?.total_referred);
      } else {
        LoaderHelper.loaderStatus(false)
        alertErrorMessage(result.message);
      }
    });
  };


  const handleReferralLink = async () => {
    LoaderHelper.loaderStatus(true)
    await HomeService.referralLink().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false)
        setReferralLink(result?.data?.my_code);
      } else {
        LoaderHelper.loaderStatus(false)
        alertErrorMessage("Something Went Wrong");
      }
    });
  };

  const getLevelHandler = (data) => {
    navigate("/memberPanel", { state: data });
  };


  const getLevelIncomeHandler = (data) => {
    navigate("/levelIncome", { state: data });
  };

  const copyCode = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500); // Reset copied state after 1.5 seconds
      })
      .catch((error) => {
        console.error(error);
      });
  };



  return (
    <>
      <section class="page_wrapper">
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1" />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2" />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3" />
        <div class="container">
          <div class="inner text-center">
            <h1 class="title">Flexibits Referral Program</h1>
            <span class="partnerx-hero-description-title pt-3">
              Refer &amp; earn 50% of trading fee paid by your friends as reward. Be your own Boss!
            </span>
            <nav class="mt-4">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/profile">My Account</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Level Income
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div className="">
        <section class=" invite_sec logg_inn bg_img pb-5 ">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-12 col-md-12 mx-auto">
                <div class="card">
                  <div class="card-body invite_card ">
                    <div className="reff_card">
                      <h5>
                        {" "}<i class="fa fa-link"></i> Your referral link{" "}
                      </h5>
                      <div className="field-box field-otp-box share mb-3 mt-3">
                        <input type="text" value={"https://flexibits.io/" + "signup?reffcode=" + referralLink} />
                        <button className="btn btn-sm btn-gradient" onClick={() => copyCode()}>
                          <span><i class="ri-file-copy-line"></i> {copied ? 'Copied!' : 'Copy'}</span>
                        </button>
                      </div>
                      <div class="share" style={{ columnGap: "2rem" }}>

                        <FacebookShareButton url={link}>
                          <FacebookIcon size={40} round />
                        </FacebookShareButton>

                        <TwitterShareButton url={link}>
                          <TwitterIcon size={40} round />
                        </TwitterShareButton>

                        <LinkedinShareButton url={link}>
                          <LinkedinIcon size={40} round />
                        </LinkedinShareButton>

                        <WhatsappShareButton url={link}>
                          <WhatsappIcon size={40} round />
                        </WhatsappShareButton>

                      </div>
                    </div>

                    <div className="level_tabs">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="Chart-tab" data-bs-toggle="tab" data-bs-target="#Chart" type="button" role="tab" aria-controls="Chart" aria-selected="true"> Level Chart</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="Income-tab" data-bs-toggle="tab" data-bs-target="#Income" type="button" role="tab" aria-controls="Income" aria-selected="false">
                            Level Income
                          </button>
                        </li>
                      </ul>

                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane   show active" id="Chart" role="tabpanel" aria-labelledby="Chart-tab" >
                          <div className="row g-4 level_chart">
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(1)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>01</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(2)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>02</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(3)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>03</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(4)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>04</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(5)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>05</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(6)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>06</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(7)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>07</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(8)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>08</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(9)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>09</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(10)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>10</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(11)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>11</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(12)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>12</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(13)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>13</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(14)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>14</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(15)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>15</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(16)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>16</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(17)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>17</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(18)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>18</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(19)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>19</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelHandler(20)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>20</h4>
                                <span>Level</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane  " id="Income" role="tabpanel" aria-labelledby="Income-tab">
                          <div className="row g-4 level_icome">
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(1)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>01</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(2)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>02</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(3)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>03</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(4)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>04</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(5)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>05</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(6)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>06</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(7)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>07</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(8)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>08</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(9)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>09</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(10)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>10</h4>
                                <span>Level</span>
                              </span>
                            </div>

                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(11)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>11</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(12)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>12</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(13)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>13</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(14)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>14</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(15)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>15</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(16)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>16</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(17)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>17</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(18)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>18</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(19)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>19</h4>
                                <span>Level</span>
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span className="level_card cursor-pointer" onClick={() => getLevelIncomeHandler(20)}>
                                <i class="ri-arrow-right-up-line"></i>
                                <h4>20</h4>
                                <span>Level</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br /> <br />
        <section class="partnerx-section partnerx-hero">
          <div class="partnerx-hero-bullets">
            <ul class="partnerx-hero-bullets-list">
              <li class="partnerx-hero-bullets-list-item">
                <img
                  src="images/50.png"
                  alt="Bullet-1"
                  class="partnerx-hero-bullets-list-item-image"
                />
                <span class="partnerx-hero-bullets-list-item-text">
                  Earn 50% as reward of every trading fee
                </span>
              </li>
              <li class="partnerx-hero-bullets-list-item">
                <img
                  src="images/cash-on-delivery.png"
                  alt="Bullet-2"
                  class="partnerx-hero-bullets-list-item-image"
                />
                <span class="partnerx-hero-bullets-list-item-text">
                  Payout every 24 hours!
                </span>
              </li>
              <li class="partnerx-hero-bullets-list-item">
                <img
                  src="images/bitcoin_new.png"
                  alt="Bullet-3"
                  class="partnerx-hero-bullets-list-item-image"
                />
                <span class="partnerx-hero-bullets-list-item-text">
                  Unlimited referrals
                </span>
              </li>
              <li class="partnerx-hero-bullets-list-item">
                <img
                  src="images/money.png"
                  alt="Bullet-4"
                  class="partnerx-hero-bullets-list-item-image"
                />
                <span class="partnerx-hero-bullets-list-item-text">
                  Unlimited rewards
                </span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReferralPage;
