import React, { useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleInputReset = () => {
    setName("");
    setEmail("");
    setMobileNumber("");
    setSubject("");
    setMessage("");
  }

  const handleContact = async (name, email, mobileNumber, subject, message) => {
    await AuthService.support(name, email, mobileNumber, subject, message).then(async result => {
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          handleInputReset();
        } catch (error) {
          alertErrorMessage(error);
          /*  console.log('error', `${error}`); */
        }
      } else {
        alertErrorMessage(result.message);
      }
    })
  }


  function validateForm() {

    if (name.length == 0) {
      alertErrorMessage('Name can not be empty')
      return
    }

    if (email.length == 0) {
      alertErrorMessage('Email can not be empty')
      return
    }
    if (mobileNumber.length == 0) {
      alertErrorMessage('Mobile Number  can not be empty')
      return
    }

    if (subject.length == 0) {
      alertErrorMessage('Subject can not be empty')
      return
    }
    else if (message.length == 0) {
      alertErrorMessage('Message can not be empty')
      return
    }
    else {
      handleContact(name, email, mobileNumber, subject, message)
    }
  }




  return (
    <>


      <section className="inner-page-banner page_wrapper">
        <div className="container">
          <div className="inner text-center pt-0">
            <h1 className="title"> Contact Us </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
              </ol>
            </nav>
          </div>
        </div>
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1  " />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2  " />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3  " />


      </section >
      <section className="pb-90">
        <div className="container">
          <div className="row" >
            <div className="col-md-10 m-auto" >
              <div className="create-item-wrapper plicy_sec" >
                <div className="form-field-wrapper ">
                  <div className="row">

                    <div className="col-md-6 mb-4">
                      <div className="field-box">
                        <label htmlFor="name" className="form-label">Enter Your Name<span style={{ color: "red" }}>*</span></label>
                        <input type="text" required value={name === "undefined" ? "" : name} name="name" onChange={(event) => setName(event.target.value)} />


                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="field-box">
                        <label htmlFor="email" className="form-label">Enter Email Address<span style={{ color: "red" }}>*</span></label>
                        <input type="email" placeholder="" value={email} name="email" onChange={(event) => setEmail(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="field-box">
                        <label htmlFor="name" className="form-label">Enter Phone Number<span style={{ color: "red" }}>*</span></label>
                        <input type="number" placeholder="" value={mobileNumber} name="mobileNumber" onChange={(event) => setMobileNumber(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="field-box">
                        <label htmlFor="subject" className="form-label">Enter Subject<span style={{ color: "red" }}>*</span></label>
                        <input type="text" placeholder="" value={subject} name="subject" onChange={(event) => setSubject(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <div className="field-box">
                        <label htmlFor="message" className="form-label">Enter Your Message<span style={{ color: "red" }}>*</span></label>
                        <textarea value={message} name="message" onChange={(event) => setMessage(event.target.value)} />
                      </div>
                    </div>

                    <button className=" mt-5 btn btn-gradient btn-medium justify-content-center w-100" type="button" onClick={() => { validateForm() }}><span> Submit</span></button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
}

export default ContactPage;