import React, { useState, useContext, useEffect } from "react";
import { ProfileContext } from "../../Context";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthService from "../../api/services/AuthService";
import { ApiConfig } from "../../api/apiConfig/apiConfig";
import moment from "moment";

const UserHeader = () => {

  const navigate = useNavigate();

  // ********* Context ********** //
  const { setUpdateRouting, updateRouting, userImage } = useContext(ProfileContext);



  const { pathname } = useLocation();

  useEffect(() => {
    handleNotifications();
  }, []);

  const [notifications, setNotifications] = useState([]);

  const handleNotifications = async () => {
    await AuthService.notifications().then(async (result) => {
      if (result.data) {
        try {
          setNotifications(result?.data?.reverse());
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  // ********* User Logout ********** //
  const logout = () => {
    sessionStorage.clear();
    navigate("/");
    // setUpdateRouting(!updateRouting)
  }

  function showMenu() {
    let tab = document.getElementById("qwert");
    console.log(tab);
    tab.classList.add("active");
  }

  function hideMenu() {
    let tab = document.getElementById("qwert");
    console.log(tab);
    tab.classList.remove("active");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const removeHandler = () => {
    const tab = document.getElementById("body");
    tab.classList.remove("trade_body");
  };




  return (
    <>
      <header className="ib-header header-default header-fixed header--sticky">
        <div className="container">
          <div className="header-inner d-flex align-items-center justify-content-between">
            <div className="header-left d-flex align-items-center">
              <div className="logo-wrapper">
                <Link to="/" className="logo logo-light">
                  <img src="images/logo-white.png" alt="brand" />
                </Link>
              </div>
            </div>
            <div className="header-right d-flex align-items-center">
              <div className="mainmenu-wrapper">
                <nav id="sideNav" className="mainmenu-nav d-none d-xl-block">
                  <ul className="mainmenu">
                    <li className="">
                      <Link className="" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/market">
                        Market
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/trade">
                        Trade
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/quickBuy">
                        Quick Buy/Sell
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/funds">
                        Spot Wallet
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/flexiWallet">
                        Flexi Wallet
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <ul className="header-right-inner">
                <li className="setting-option mobile-menu-bar d-block d-xl-none">
                  <button className="hamberger-button" onClick={() => showMenu()}>
                    <i className="ri-menu-2-fill"></i>
                  </button>
                </li>

                {/* notification  */}
                <li className="avatar-info notifications">
                  <span className="cursor_pointer text-white  btn-icon">
                    <i className="ri-notification-3-line me-0"></i>
                    <span className="nofti_alert"></span>
                  </span>
                  <ul className="submenu pb-0">
                    <h6 className="dropdown-header text-white d-flex align-items-center pb-3 ">
                      <i className="ri-notification-3-line me-3"></i>New
                      Notificatons
                    </h6>
                    <hr />

                    {notifications.length > 0
                      ? notifications.slice(0, 2).map((value, index) => (
                        <li className="noft_data" key={value.id}>
                          <Link to="/notifications">
                            <div>
                              <div className="small text-gray-500">
                                {value?.subject}
                              </div>
                              <span className="font-weight-bold">
                                <td>{value?.message}</td>
                              </span>
                            </div>
                          </Link>
                        </li>
                      ))
                      : "no data available"}
                    <Link to="/notifications" className="dropdown-item text-center small text-gradient">
                      Show All Alerts
                    </Link>
                  </ul>
                </li>
                {/* notification  End */}
                <li className="avatar-info">
                  <span className="cursor_pointer">
                    {!userImage ? (
                      <img src="images/profilelogo.png" alt="user avatar" />
                    ) : (
                      <img src={`${ApiConfig.baseUrl + userImage}`} alt="user avatar" />
                    )}
                  </span>
                  <ul className="submenu">
                    <li>
                      <Link to="/profile">
                        <i className="ri-user-line"></i> My Account
                      </Link>
                    </li>
                    <li>
                      <Link className="" to="/referral">
                        <i className="ri-share-line"></i>Level Income
                      </Link>
                    </li>
                    <li>
                      <a href="/#" style={{ cursor: "pointer" }} onClick={logout}>
                        <i className="ri-logout-box-r-line"></i>Log out
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <div className="popup-mobile-menu" id="qwert">
        <div className="inner">
          <div className="header-top">
            <div className="logo logo-custom-css">
              <a className="logo-light" href="index.php">
                <img src="images/logo-white.png" alt="nft-logo" />
              </a>
            </div>
            <div className="close-menu">
              <button className="close-button" onClick={() => hideMenu()}>
                <i className="ri-close-fill"></i>
              </button>
            </div>
          </div>
          <nav>
            <ul className="mainmenu">
              <li>
                <Link className="" to="/" onClick={() => { removeHandler(); hideMenu(); }}>
                  Home
                </Link>
              </li>
              <li className="">
                <Link className="" to="/market" onClick={() => { removeHandler(); hideMenu(); }}>
                  Market
                </Link>
              </li>
              <li className="">
                <Link className="" to="/trade" onClick={() => { removeHandler(); hideMenu(); }}>
                  Trade
                </Link>
              </li>
              <li className="">
                <Link className="" to="/quickBuy" onClick={() => { removeHandler(); hideMenu(); }}>
                  Quick Buy
                </Link>
              </li>
              <li className="">
                <Link className="" to="/funds" onClick={() => { removeHandler(); hideMenu(); }}>
                  Spot Wallet
                </Link>
              </li>
              <li className="">
                <Link className="" to="/flexiWallet" onClick={() => { removeHandler(); hideMenu(); }}>
                  Flexi Wallet
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
