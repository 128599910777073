import React, { useState, useEffect, useContext } from "react";
import { alertSuccessMessage, alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";


const CurrencyPrefrence = () => {

    const [currencyType, setCurrencyType] = useState();
    const [currencyName, setCurrencyName] = useState();



    const handleInputChange = (event) => {
        setCurrencyType(event.target.id);
    }

    const handleUserDetials = async () => {
        LoaderHelper.loaderStatus(false);
        await AuthService.getDetails().then(async (result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setCurrencyName(result?.data?.currency_prefrence);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    };

    const handleCurrency = async (currencyType) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.setCurrency(currencyType).then(async result => {
            LoaderHelper.loaderStatus(false)
            if (result.success) {
                try {
                    alertSuccessMessage(result.message);
                    handleUserDetials();
                } catch (error) {
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }
    useEffect(() => {
        handleUserDetials();
    }, []);

    const resetInput = () => {
        setCurrencyName("");
    }

    return (
        <>
            <div className="tab-pane  active show" id="CurrencyPill" role="tabpanel" aria-labelledby="Currency-pill">
                <div className="upload-formate mb-6  ">
                    <h3 className="mb-1">
                        Currency Preference
                    </h3>
                    <p className="formate">
                        Select your preferred display currency for all markets
                    </p>
                </div>
                <div className=" row py-4">
                    <div className="col-md-12 col-lg-8 m-auto">
                        <div className="row">

                            <div className="col-md-6">
                                <label className="card-radio-btn mb-3 ">
                                    <input type="radio" name="bitcoin" className={`card-input-element d-none${currencyName === "USDT" ? "card-input-element d-none active" : ""}`} id="USDT" onChange={handleInputChange} onClick={resetInput} />
                                    <div className="card card-body">
                                        <img src="images/coins/tether.png" className="img-fluid check_img" />
                                        <div className="content_head">Tether USD (USDT)</div>
                                    </div>
                                </label>

                            </div>
                            <div className="col-md-6">
                                <label className="card-radio-btn mb-3 ">
                                    <input type="radio" name="bitcoin" className={`card-input-element d-none${currencyName === "BTC" ? "card-input-element d-none active" : ""}`} id="BTC" onChange={handleInputChange} onClick={resetInput} />
                                    <div className="card card-body">
                                        <img src="images/coins/bitcoin.png" className="img-fluid check_img" />
                                        <div className="content_head">Bitcoin (BTC)</div>
                                    </div>
                                </label>

                            </div>
                            <div className="col-md-6">
                                <label className="card-radio-btn mb-3">
                                    <input type="radio" name="bitcoin" className={`card-input-element d-none${currencyName === "INR" ? "card-input-element d-none active" : ""}`} id="INR" onChange={handleInputChange} onClick={resetInput} />
                                    <div className="card card-body">
                                        <img src="images/coins/rupee.png" className="img-fluid check_img" />
                                        <div className="content_head"> Rupee (INR)</div>
                                    </div>
                                </label>

                            </div>
                            <div className="col-md-6">

                                <label className="card-radio-btn ">
                                    <input type="radio" name="bitcoin" className={`card-input-element d-none${currencyName === "BNB" ? "card-input-element d-none active" : ""}`} id="BNB" onChange={handleInputChange} onClick={resetInput} />
                                    <div className="card card-body">
                                        <img src="images/coins/bnb.png" className="img-fluid check_img" />
                                        <div className="content_head">BNB</div>
                                    </div>
                                </label>
                            </div>

                        </div>
                        <button className=" mt-5 btn btn-gradient btn-medium justify-content-center w-100" type="button" onClick={() => handleCurrency(currencyType)}><span> Save Currency Preference </span></button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CurrencyPrefrence;