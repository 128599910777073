import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { ProfileContext } from "../../../Context";

const LoginPage = () => {
  const navigate = useNavigate();
  const [isAuthEnabled, setIsAuthEnabled] = useState(false);
  const [verification_code, setverification_code] = useState('');
  const [userDetails, setUserDetails] = useState();
  const [authType, setAuthType] = useState();
  const [signId, setSignId] = useState('');
  const [mobileNumber, setmobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // ********* Context ********** //
  const { setUpdateRouting, updateRouting } = useContext(ProfileContext)

  // ********* Country Code/List ********** //
  const countryCodes = require('country-codes-list')
  const myCountryCodesObject = countryCodes.customList('countryCode', '{countryNameEn} : (+{countryCallingCode})  ');

  // ********* Login Function ********** //
  const handleLogin = async (signId, password) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.login(signId, password).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        if (result?.data?.['2fa'] === 0) {
          try {
            alertSuccessMessage('Login Successfull !!');
            sessionStorage.setItem("emailId", result.data.emailId);
            sessionStorage.setItem("mobileNumber", result.data.mobileNumber);
            sessionStorage.setItem("token", result.data.token);
            sessionStorage.setItem("userId", result.data.userId);
            navigate("/profile");
            // setUpdateRouting(!updateRouting)
            // window.location.reload();
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          setAuthType(result?.data?.['2fa'])
          setIsAuthEnabled(true);
          setUserDetails(result?.data)
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  // ********* Auth Verificatioon Function ********** //
  const handleAuthVerify = async (signId, authType, vCode) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCode(signId, authType, vCode).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          sessionStorage.setItem("emailId", result.data.emailId);
          sessionStorage.setItem("mobileNumber", result.data.mobileNumber);
          sessionStorage.setItem("token", result.data.token);
          sessionStorage.setItem("userId", result.data.userId);
          alertSuccessMessage(result.message);
          navigate("/profile");
          // setUpdateRouting(!updateRouting)
          // window.location.reload();
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleOtpresend = async (signId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtpresend(signId).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result.message);
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  // ********* Reset Form Inputs ********** //
  const handleReset = () => {
    setSignId('');
    setmobileNumber('');
    setPassword('');
    setShowPassword(false);
  };

  // ********* Scroll Upside ********** //
  // useEffect(() => {
  //   window.scrollTo({ top: 20, behavior: 'smooth' });
  // }, []);

  return (
    <>
      <div className="page_wrapper">
        <div className="container">
          <div className="signup-wrapper ">
            <div className="row align-items-cente gutter-0">
              <div className="col-xl-6 col-lg-12 d-xl-block d-none">
                <img src="images/signin.svg" className="w-100 img-fluid" alt="signup" />
              </div>
              <div className="col-xl-6 col-lg-12 ">
                <div className="signup-content signin-content">
                  <div className={`sign-in_tab ${isAuthEnabled && "d-none"}`}>
                    <h3 className="mb-2">Welcome to Flexibits</h3>
                    <p>Enter the information you entered while registering.</p>
                    <div className="mb-3">
                      <ul className="nav custom-tabs my-3">
                        <li>
                          <a className="active" data-bs-toggle="tab" href="#login_Mobile" onClick={handleReset}>
                            Mobile
                          </a>
                        </li>
                        <li>
                          <a data-bs-toggle="tab" href="#login_email" onClick={handleReset}>
                            Email
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      <div className="tab-pane show  px-0 container active" id="login_Mobile">
                        {/* <form> */}
                        <div>
                          <div className="row">
                            <div className="col-12">
                              <div className="fleld-box">
                                <label htmlFor="Code" className="form-label">
                                  Mobile
                                </label>
                              </div>
                            </div>
                            <div className="col-4 mb-4">
                              <div className="field-box ">
                                <select name="countryCode" defaultValue="+91">
                                  <optgroup label="Other countries">
                                    <option data-countrycode="IN" value="+91"  >
                                      India (+91)
                                    </option>
                                    {Object.values(myCountryCodesObject).map((value) => {
                                      return <option data-countrycode="IN" value={value} key={value} >
                                        {value}
                                      </option>
                                    })}
                                  </optgroup>

                                </select>
                              </div>
                            </div>
                            <div className="col-8 mb-4">
                              <div className="field-box">
                                <input id="mobile" type="number" placeholder="Enter mobile number" value={mobileNumber} onChange={(e) => setmobileNumber(e.target.value)} />
                              </div>
                            </div>
                            <div className="col-md-12 mb-4">
                              <div className="field-box">
                                <label htmlFor="password" className="form-label">
                                  Password
                                </label>
                                <div className="field-otp-box">
                                  <input name="mpassword" type={showPassword ? 'text' : "password"} placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                  <button className="btn btn-sm btn-dark " onClick={() => { setShowPassword(!showPassword) }}>
                                    {!showPassword ? <i className="ri-eye-off-line pe-0"></i> : <i className="ri-eye-line pe-0"></i>}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-4 text-end">
                              <div className="field-box">
                                <Link to="/forgotpassword">
                                  Forgot Password?
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-12 mb-4 mt-4">
                              <div className="field-box">
                                <button
                                  className="btn btn-gradient w-100 justify-content-center btn-medium"
                                  type="button" onClick={() => { handleLogin(mobileNumber, password) }}>
                                  <span>Sign In</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </form> */}
                      </div>
                      <div
                        className="tab-pane  px-0   container"
                        id="login_email"
                      >
                        {/* <form> */}
                        <div className="row">
                          <div className="col-md-12 mb-4">
                            <div className="field-box">
                              <label htmlFor="email" className="form-label">
                                Email
                              </label>
                              <input id="email" name="email" type="email" placeholder="Please enter Email"
                                value={signId} onChange={(e) => { setSignId(e.target.value) }} />
                            </div>
                          </div>
                          <div className="col-md-12 mb-4">
                            <div className="field-box">
                              <label htmlFor="password" className="form-label">
                                Password
                              </label>
                              <div className="field-otp-box">
                                <input name="mpassword" type={showPassword ? 'text' : "password"} placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                <button className="btn btn-sm btn-dark " onClick={() => { setShowPassword(!showPassword) }}>
                                  {!showPassword ? <i className="ri-eye-off-line pe-0"></i> : <i className="ri-eye-line pe-0"></i>}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-4 text-end">
                            <div className="field-box">
                              <Link to="/forgotpassword">
                                Forgot Password?
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-12 mb-4 mt-4">
                            <div className="field-box">
                              <button className="btn btn-gradient w-100 justify-content-center btn-medium {
                                    "type="button" onClick={() => { handleLogin(signId, password) }}>
                                <span>Sign In</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* </form> */}
                      </div>
                      <div className="row justify-content-center text-center">
                        <div className="col-lg-12">
                          Don't have an account?
                          <Link to="/signup" className="color-primary">
                            Sign up
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`otb_tab  ${!isAuthEnabled && "d-none"} `}>
                    <div className="mb-3">
                      <h3 className="mb-2">Two Factor Verification</h3>
                      <p className="medium" style={{ color: "black", fontWeight: "bold" }}>
                        {authType === 2 ? "Your Code will be sent to Google Authenticator App" : authType === 1 ? `Your Code will be sent ${userDetails?.emailId}` : authType === 3 ? `Your Code will be sent ${userDetails?.mobileNumber}` : null}</p>
                    </div>
                    <form>
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label htmlFor="Code" className="form-label">
                              Enter Code
                            </label>
                            <input
                              id="Verification"
                              type="text"
                              value={verification_code}
                              placeholder=""
                              onChange={(e) => setverification_code(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div
                            className="field-box"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOtpresend(mobileNumber ? mobileNumber : signId)}>
                            {/* <Link to="/forgotpassword">
                              {" "} */}
                            Re-send verification code
                            {/* </Link> */}
                          </div>
                        </div>
                        <div className="col-md-12 mb-4 mt-4">
                          <div className="field-box">
                            <button className="btn btn-gradient w-100 justify-content-center btn-medium {" type="button"
                              onClick={() =>
                                handleAuthVerify(mobileNumber ? mobileNumber : signId, authType, verification_code)}>
                              <span>Verify</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="row justify-content-center text-center">
                      <div className="col-lg-12">
                        Back to
                        <Link to="#" className={"color-primary"} onClick={() => setIsAuthEnabled(false)}>
                          Sign in
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/shape/1-light.png"
          alt="shape"
          className="shape shape-1  "
        />
        <img
          src="images/shape/2-light.png"
          alt="shape"
          className="shape shape-2  "
        />
        <img
          src="images/shape/3-light.png"
          alt="shape"
          className="shape shape-3"
        />
      </div>
    </>
  );
};

export default LoginPage;
