import React, { useState, useEffect, useRef } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";

const QuickBuy = () => {
  const [currency, setCurrency] = useState("USDT");
  const [amountToPay, setAmountToPay] = useState();
  const [amountToBuy, setAmountToBuy] = useState();
  const [amount, setAmount] = useState();
  const [amountSell, setAmountSell] = useState();
  const [coinType, setCoinType] = useState();
  const [focus, setFocus] = useState(false);
  const [buyTab, setBuyTab] = useState(true);
  const [sellTab, setSellTab] = useState(false);
  const [amountToSell, setAmountToSell] = useState();
  const [sellAmount, setSellAmount] = useState();
  const [focusAmount, setFocusAmount] = useState(false);
  const [coinName, setCoinName] = useState([]);



  useEffect(() => {
    balanceByCoinName(currency);
    getCoin()
  }, []);

  const getCoin = async () => {
    LoaderHelper.loaderStatus(true)
    await AuthService.getCoin().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false)
        try {
          setCoinName(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false)
        alertErrorMessage(result.message);
      }
    });
  };

  const balanceByCoinName = async (currency) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.balanceByCoinName(currency).then(async (result) => {
      if (result.succes) {
        LoaderHelper.loaderStatus(false)
        try {
          setAmount(result?.data);
          setCoinType(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false)
        alertErrorMessage(result.message);
      }
    });
  };

  const balanceByCoinNameSell = async (currency) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.balanceByCoinName(currency).then(async (result) => {
      if (result.succes) {
        LoaderHelper.loaderStatus(false)
        try {
          setAmountSell(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false)
        alertErrorMessage(result.message);
      }
    });
  };

  const handleBuyConvertAmount = async (side, currency, amountToPay) => {
    await AuthService.handleBuyConvertAmount(side, currency, amountToPay).then(
      async (result) => {
        if (result.message) {
          try {
            setAmountToBuy(result.data);
            setDisableBtn(false)
          } catch (error) {
            alertErrorMessage(error);
          }
        }
        // else {
        //   alertErrorMessage(result.message);
        // }
      }
    );
  };

  const handleSellConvertAmount = async (side, currency, amountToSell) => {
    await AuthService.handleSellConvertAmount(side, currency, amountToSell).then(
      async (result) => {
        if (result.success) {
          try {
            setSellAmount(result.data);
            setDisable(false)
          } catch (error) {
            alertErrorMessage(error);
          }
        }
        // else {
        //   alertErrorMessage(result.message);
        // }
      }
    );
  };

  const BuyHandler = async (currency, amountToPay, amountToBuy) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.buy(currency, amountToPay, amountToBuy).then(
      async (result) => {
        if (result.success) {
          LoaderHelper.loaderStatus(false)
          try {
            alertSuccessMessage(result.message);
            setAmountToPay("")
            setAmountToBuy("")
            balanceByCoinName(result.data.to);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false)
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const SellHandler = async (currency, amountToSell, sellAmount) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.sell(currency, amountToSell, sellAmount).then(
      async (result) => {
        if (result.success) {
          LoaderHelper.loaderStatus(false)
          try {
            alertSuccessMessage(result.message);
            setAmountToSell("")
            setSellAmount("")
            balanceByCoinNameSell('CITRA');
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false)
          alertErrorMessage(result.message);
        }
      }
    );
  };


  const timeoutRef = useRef(null);
  const [disableBtn, setDisableBtn] = useState(true);
  const handleBuyInputAmount = (e) => {
    setDisableBtn(true)
    setAmountToPay(e.target.value);
    clearTimeout(timeoutRef.current);
    if (e.target.value >= amount) {
      alertErrorMessage("Low Balance");
    }
    timeoutRef.current = setTimeout(() => {
      handleBuyConvertAmount("BUY", currency, e.target.value ? e.target.value : 0)
    }, 700);
  };

  const timeoutRef2 = useRef(null);
  const [disable, setDisable] = useState(true);
  const handleSellInputAmount = (e) => {
    setDisable(true)
    setAmountToSell(e.target.value)
    clearTimeout(timeoutRef2.current);
    if (e.target.value >= amountSell) {
      alertErrorMessage("Low Balance");
    }
    timeoutRef2.current = setTimeout(() => {
      handleSellConvertAmount("SELL", currency, e.target.value ? e.target.value : 0)
    }, 700);
  };



  return (
    <>
      <div className="page_wrapper">
        <div className="container">
          <div className="row align-items-cente gutter-0 justify-content-center">
            <div className="col-xl-6 ">
              <div className="signup-wrapper ">
                <div className="signup-content signin-content">
                  <div class="d-flex-between mb-5">
                    <ul class="nav custom-tabs">
                      <li>
                        <a class="active" data-bs-toggle="tab" href="#buy" onClick={() => {
                          setBuyTab(true);
                          setSellTab(false);
                          balanceByCoinName(currency);
                        }}>
                          Buy
                        </a>
                      </li>
                      <li>
                        <a data-bs-toggle="tab" href="#sell" onClick={() => {
                          setBuyTab(false);
                          setSellTab(true);
                          balanceByCoinNameSell("CITRA");
                        }}>
                          Sell
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/*  */}
                  {buyTab && (
                    <div className="sign-in_tab" id="buy">
                      <h2 className="mb-4 text-center">Buy Citra Coins</h2>
                      <p className=" text-center"></p>
                      <div className="mb-3">
                        <ul className="nav custom-tabs my-3">
                          <li>
                            <span className="px-3">
                              Your Balance : {amount}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label for="email" className="form-label">
                              Coin
                            </label>
                            <input name="currencyType" type="text" value="CITRA" disabled />
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label for="email" className="form-label">
                              Select Currency
                            </label>
                            <select name="currencyType" required value={currency} onChange={(e) => { setCurrency(e.target.value); balanceByCoinName(e.target.value) }}>
                              <option value="USDT" selected>
                                USDT
                              </option>
                              <option value="BNB">BNB</option>
                            </select>

                            {/* <input id="email" name="email" type="text" /> */}
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label for="amount" className="form-label">
                              Amount to pay
                            </label>
                            <input id="amounttopay" type="number" value={amountToPay}
                              onChange={(e) => handleBuyInputAmount(e)} onWheelCapture={(e) => e.target.blur()} />
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label for="amount" className="form-label">
                              Amount to buy
                            </label>
                            <input id="amounttobuy" disabled type="number" value={amountToBuy} />
                          </div>
                        </div>

                        <div className="col-md-12 mb-4 mt-4">
                          <div className="field-box">
                            <button className="btn btn-gradient w-100 justify-content-center btn-medium" type="button"
                              onClick={() => BuyHandler(currency, amountToPay, amountToBuy)} disabled={disableBtn ? true : false} >
                              <span>Buy Now</span>
                            </button>


                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/*  */}

                  {sellTab && (
                    <div className="sign-in_tab" id="sell">
                      <h2 className="mb-4 text-center">Sell Citra Coins</h2>
                      <p className=" text-center"></p>
                      <div className="mb-3">
                        <ul className="nav custom-tabs my-3">
                          <li>
                            <span className="px-3">
                              Your Citra Balance : {amountSell}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label for="email" className="form-label">
                              Coin
                            </label>
                            <input name="currencyType" type="text" value="CITRA" disabled />
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label for="email" className="form-label">
                              Select Currency
                            </label>
                            <select name="currencyType" required value={currency} onChange={(e) => setCurrency(e.target.value)}
                              onClick={() => setFocusAmount(false)}>
                              <option value="USDT" selected>
                                USDT
                              </option>
                              <option value="BNB">BNB</option>
                            </select>
                            {/* <input id="email" name="email" type="text" /> */}
                          </div>
                        </div>

                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label for="email" className="form-label">
                              Amount to sell
                            </label>
                            <input id="email" name="email" type="number" value={amountToSell} onChange={(e) => handleSellInputAmount(e)} onWheelCapture={(e) => e.target.blur()} />
                          </div>
                        </div>

                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label for="email" className="form-label">
                              Amount to get
                            </label>
                            <input id="email" name="email" disabled type="number" value={sellAmount} />
                          </div>
                        </div>

                        <div className="col-md-12 mb-4 mt-4">
                          <div className="field-box">
                            <button className="btn btn-gradient w-100 justify-content-center btn-medium" type="button"
                              onClick={() => SellHandler(currency, amountToSell, sellAmount)} disabled={disable ? true : false}>
                              <span>Sell Now</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1" />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2" />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3" />
      </div >
    </>
  );
};

export default QuickBuy;
