import React, { useState, useEffect, useContext } from "react";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import "react-image-crop/dist/ReactCrop.css";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import "react-image-crop/dist/ReactCrop.css";
import { ProfileContext } from "../../../Context";
import { notEqualsZero, validateEmail, validateNumber } from "../../../utils/Validation"
import DefaultInput from "../../../customComponent/DefaultInput";



const SettingsPage = (props) => {

  const [localImage, setlocalImage] = useState();
  const [profileImage, setprofileImage] = useState();

  // ********* Context ********** //
  const { firstName, setFirstName, email, setEmail, lastName, setLastName, setMobile, mobile, userImage, handleUserDetials, emailProfile, mobileProfile } = useContext(ProfileContext);

  // ********* Update User Details ********** //
  const updateUserDetails = async (myfile, firstName, lastName, emailId, mobile) => {
    if (mobile && mobile.length < 10) {
      alertErrorMessage("Mobile number Should be 10 digit")
      return

    }

    var formData = new FormData();
    formData.append("profilepicture", myfile);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("emailId", emailId);

    formData.append("mobileNumber", mobile);

    LoaderHelper.loaderStatus(true);
    await AuthService.updateSettings(formData).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result.message);
          handleUserDetials();
          window.scrollTo({ top: 100, behavior: 'smooth' });
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  // ********* Handle Image Change ********** //
  const handleImageChange = (e) => {
    const fileUploaded = e.target.files[0];
    setlocalImage(URL.createObjectURL(fileUploaded));
    setprofileImage(fileUploaded)
  };

  // ********* Scroll Upside ********** //
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <div className="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
        <div className="row">
          <div className="col-md-12 col-lg-8 m-auto">
            <div className="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-4">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <input type="file" id="imageUpload" name="myfile" onChange={handleImageChange} />
                        <label htmlFor="imageUpload">
                          <i className="ri-camera-line"></i>
                        </label>
                      </div>
                      <div className="avatar-preview">
                        <img alt="user" src={!userImage && !localImage ? "images/profilelogo.png" : userImage && !localImage ? `${ApiConfig.baseUrl + userImage}` : localImage} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-4">
                    <div className="field-box">
                      <label htmlFor="text" className="form-label">
                        First Name
                      </label>
                      <input type="text" name="firstName" placeholder="Enter Your First Name" value={firstName === "undefined" ? "" : firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                    </div>
                  </div>
                  <div className="col-md-12 mb-4">
                    <div className="field-box">
                      <label htmlFor="text" className="form-label">
                        Last Name
                      </label>
                      <input type="text" name="lastName" placeholder="Enter Last Name" value={lastName === "undefined" ? "" : lastName} onChange={(e) => { setLastName(e.target.value) }} />
                    </div>
                  </div>
                  <div className="col-md-12 mb-4">
                    <div className="field-box">
                      <label htmlFor="text" className="form-label">
                        Email
                      </label>
                      <DefaultInput
                        type="email" name="Email" className="form-control" placeholder="Enter Email" value={email === "undefined" ? "" : email}
                        errorStatus={validateEmail(email) !== undefined && notEqualsZero(email)
                        }
                        errorMessage={email === "undefined" ? "" : validateEmail(email)}
                        onChange={(e) => setEmail(e.target.value)} disabled={emailProfile ? true : false} />
                    </div>
                  </div>
                  <div className="col-md-12 mb-4">
                    <div className="field-box">
                      <label htmlFor="text" className="form-label">
                        Mobile Number
                      </label>
                      <DefaultInput
                        type="number"
                        name="Mobile"
                        // disabled ={mobile ? true : false}
                        placeholder="Enter Mobile Number"
                        errorStatus={!validateNumber(mobile)}
                        errorMessage={
                          mobile === "undefined"
                            ? ""
                            : "Please enter a valid mobile number"
                        }
                        value={mobile === "undefined" ? "" : mobile}
                        onChange={(e) => {
                          const inputText = e.target.value;
                          if (/^\d*$/.test(inputText) && inputText.length <= 10) {
                            setMobile(inputText);
                          }
                        }}
                        disabled={mobileProfile ? true : false} />

                    </div>
                  </div>
                  <div className="col-md-12 mb-4 mt-4">
                    <div className="field-box">
                      <button className="btn btn-gradient w-100 justify-content-center btn-medium {" type="button"
                        onClick={() => updateUserDetails(profileImage, firstName, lastName, email, mobile)}>
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
