import React, { useState, useEffect } from "react";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import NiceSelect from "../../../customComponent/NiceSelect";
import jsPDF from 'jspdf';
import moment from "moment";
import 'jspdf-autotable';

const DownloadReports = () => {
  const [exchReport, setExchReport] = useState([]);
  const [depositReport, setDepositReport] = useState([])
  const [quickTradeReport, setQuickTradeReport] = useState([])
  const [accTradeReport, setAccTradeReport] = useState([])
  const [checkedEx, setCheckedEx] = useState(false)
  const [checkedDeposit, setCheckedDeposit] = useState(false)
  const [checkedTrade, setCheckedTrade] = useState(false)
  const [checkedAcc, setCheckedAcc] = useState(false)
  const [type, setType] = useState("");
  const [duration, setDuration] = useState("current_month")
  const [isEmptyData, setIsEmptyData] = useState(false)

  const currentYear = new Date().getFullYear() % 100
  const lastYear = new Date().getFullYear() - 1
  const secondLastYear = new Date().getFullYear() - 2
  const thirdLastYear = new Date().getFullYear() - 3

  useEffect(() => {
    if (type && (checkedEx || checkedDeposit || checkedTrade || checkedAcc)) {
      handleTradingReport(type, duration)
    }
  }, [type, checkedEx, checkedDeposit, checkedTrade, checkedAcc, duration])

  const handleTradingReport = async (type, duration) => {
    await AuthService.getTradingReport(type, duration).then(async (result) => {
      if (result.success) {
        if (result.data?.Name == "exchange") {
          setIsEmptyData(false)
          setExchReport(result.data?.data);
          console.log(result.data?.data, "ggg")
        }
        if (result.data?.Name == "deposit_withdrawal") {
          setIsEmptyData(false)
          setDepositReport(result.data?.data)
        }
        if (result.data?.Name == "quick_trades") {
          setIsEmptyData(false)
          setQuickTradeReport(result.data?.data)
        }
        if (result.data?.Name == "accounts") {
          setIsEmptyData(false)
          setAccTradeReport(result.data?.data)
        }
        if (result.data.length == 0) {
          setIsEmptyData(true)
        }

        LoaderHelper.loaderStatus(false);
      } else {
        LoaderHelper.loaderStatus(false);
        // alertErrorMessage(result.message);
      }
    });
  };
  const HandleExport = () => {
    console.log(checkedAcc, "aa");
    console.log(isEmptyData, "aaa");
    if (checkedEx && !isEmptyData)
      exportToPDF()
    if (checkedDeposit && !isEmptyData) {
      depositExportToPDF()
    }
    if (checkedTrade && !isEmptyData) {
      tradeExportToPDF()
    }
    if (checkedAcc && !isEmptyData) {
      accExportToPDF()
    }
    if (isEmptyData && (checkedEx || checkedDeposit || checkedTrade || checkedAcc)) {
      alertErrorMessage("NO REPORT AVAILABLE")
    }
  };

  // ********Export Trade Data In PDF Formats ************ // 
  const exportToPDF = () => {
    const doc = new jsPDF('landscape', 'mm', 'a4');
    doc.text('Trading Report', 10, 10);
    const tableHeaders = ['Serial No.', 'Date/Time', 'Trading Currency', "Transaction Id", 'Amount', 'Transaction Type', 'Fee', 'TDS'];
    const tableData = exchReport.map((trade, index) => [index + 1, moment(trade?.createdAt).format("MMMM Do YYYY, h:mm:ss a"), trade?.currency, trade?._id, trade?.amount, trade?.transaction_type, trade?.fee, trade?.tds])

    const fixedColumnWidths = [10, 35, 35, 20, 20, 20, 20, 30,];
    const columnStyles = {};
    tableHeaders.forEach((_, index) => {
      columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
    });

    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      theme: 'grid',
      columnStyles: columnStyles,
      headStyles: {
        fillColor: "#f2ae26", // Change to the color you want (RGB values)
      }
    });

    doc.save('exchangeTrades.pdf');
  };

  const depositExportToPDF = () => {
    const doc = new jsPDF('landscape', 'mm', 'a4');
    doc.text('Deposit Withdrawal Report', 10, 10);
    const tableHeaders = ['Serial No.', 'Date/Time', 'Assests', 'Amount', 'Fee', 'Transaction ID', 'Transaction Type', 'Status'];
    const tableData = depositReport.map((trade, index) => [index + 1, moment(trade?.createdAt).format("MMMM Do YYYY, h:mm:ss a"), trade?.short_name, trade?.amount, trade?.fee, trade?._id, trade?.transaction_type, trade?.status])

    const fixedColumnWidths = [10, 35, 35, 20, 20, 20, 20, 30];
    const columnStyles = {};
    tableHeaders.forEach((_, index) => {
      columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
    });

    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      theme: 'grid',
      columnStyles: columnStyles,
      headStyles: {
        fillColor: "#f2ae26", // Change to the color you want (RGB values)
      }
    });

    doc.save('depositWithdrawalTrades.pdf');
  };

  const tradeExportToPDF = () => {
    const doc = new jsPDF('landscape', 'mm', 'a4');
    doc.text('Quick Trade Report', 10, 10);
    const tableHeaders = ['Sr No.', 'Date/Time', 'From Currency', 'To Currency', "Transaction Id", 'Amount', 'Transaction Type', 'status'];
    const tableData = quickTradeReport.map((trade, index) => [index + 1, moment(trade?.createdAt).format("MMMM Do YYYY, h:mm:ss a"), trade?.from_currency, trade?.to_currency, trade?._id, trade?.amount, trade?.transaction_type, trade?.status])

    const fixedColumnWidths = [10, 35, 35, 20, 20, 20, 20, 30];
    const columnStyles = {};
    tableHeaders.forEach((_, index) => {
      columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
    });

    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      theme: 'grid',
      columnStyles: columnStyles,
      headStyles: {
        fillColor: "#f2ae26", // Change to the color you want (RGB values)
      }
    });

    doc.save('quickTrades.pdf');
  };

  const accExportToPDF = () => {
    console.log("parul");
    const doc = new jsPDF('landscape', 'mm', 'a4');
    doc.text('Available Coins', 10, 10);
    const tableHeaders = ['Sr No.', 'Coins'];
    const tableData = accTradeReport.map((trade, index) => [index + 1, trade?.short_name])

    const fixedColumnWidths = [30, 70];
    const columnStyles = {};
    tableHeaders.forEach((_, index) => {
      columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
    });

    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      theme: 'grid',
      columnStyles: columnStyles,
      headStyles: {
        fillColor: "#f2ae26", // Change to the color you want 
      }
    });

    doc.save('accountLedgerTrades.pdf');
  };

  return (
    <>
      <div class="tab-pane  active show" id="CurrencyPill" role="tabpanel" aria-labelledby="Currency-pill"      >
        <div class="upload-formate mb-6  ">
          <h3 class="mb-1">Download Reports</h3>
          <p class="formate">Get your trading report on your email.</p>
        </div>
        <div class=" row py-4">
          <div class="col-md-12 col-lg-8 m-auto">
            <div class="form-field-wrapper">
              <div className="field-box ">
                <NiceSelect id="inputGroupSelect01" onChange={(e) => setDuration(e.target.value == "Current Month" ? "current_month" : e.target.value)}
                  name="tradingReport" className="wide form-select lh-lg"
                  value={duration}>
                  <option value="Current Month">Current Month</option>
                  <option value="Last 30 Days">Last 30 Days</option>
                  <option value="Last 60 Days">Last 60 Days</option>
                  <option value="Last 90 Days">Last 90 Days</option>
                  <option value="last_three_financial_year"> Financial year {thirdLastYear}-{secondLastYear % 100} </option>
                  <option value="last_two_financial_year"> Financial year {secondLastYear}-{lastYear % 100} </option>
                  <option value="last_financial_year"> Financial year {lastYear}-{currentYear} </option>
                </NiceSelect>
              </div>

              <span class="sc-gsTEea ffCUrw  mb-3 ">
                From 01 May, 2022 to 25 May, 2022
              </span>
              <span color="rgb(72, 81, 86)" class="sc-gsTEea kvuTVX">
                The report will include:
              </span>

              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="exchange" id="flexCheck1" onClick={() => setCheckedEx(!checkedEx)} onChange={(e) => setType(e.target.value)} />
                <label className="form-check-label" htmlFor="flexCheck1">
                  Exchange Trades
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="deposit_withdrawal" id="flexCheck2" onClick={() => setCheckedDeposit(!checkedDeposit)} onChange={(e) => setType(e.target.value)} />
                <label className="form-check-label" htmlFor="flexCheck2">
                  Deposit and Withdrawals
                </label>
              </div>
              {/* <div className="form-check">
                <input className="form-check-input" type="checkbox" value="quick_trades" id="flexCheck3" onClick={() => setCheckedTrade(!checkedTrade)} onChange={(e) => setType(e.target.value)} />
                <label className="form-check-label" htmlFor="flexCheck3">
                  Quick Trades
                </label>
              </div> */}
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="accounts" id="flexCheck4" onClick={() => setCheckedAcc(!checkedAcc)} onChange={(e) => setType(e.target.value)} />
                <label className="form-check-label" htmlFor="flexCheck4">
                  Account Ledger
                </label>
              </div>
            </div>

            <button type="button" class="btn btn-gradient text-center  mt-3 w-100">
              <span className="m-auto" onClick={HandleExport}>
                Request Trading Report
              </span>
            </button>
          </div >
        </div >
      </div >
    </>
  );
};

export default DownloadReports;
