import HomeService from "../../../api/services/HomeService";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";

export const LevelIncome = () => {
  const [levelIncomeData, setLevelIncomeData] = useState([]);

  const userId = sessionStorage.getItem("userId");
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    getLevelIncome();
  }, []);

  const getLevelIncome = async () => {
    LoaderHelper.loaderStatus(true);
    await HomeService.levelIncome(state, userId).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setLevelIncomeData(result?.data);
        } catch (error) {
          console.log(error);
        }
      } else {
        alertErrorMessage(result.message);
        LoaderHelper.loaderStatus(false);
      }
    });
  };

  const totalPrice = levelIncomeData.reduce(
    (acc, current) => acc + current.level_bonus,
    0
  );

  return (
    <>
      <section class="page_wrapper">
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1" />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2" />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3" />

        <div class="container page-header_top">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div class="inner text-start mb-0">
                <Link to="/referral" className="btn btn-link back-btn text-white px-0">
                  <i class="ri-arrow-left-line me-1"></i> Back</Link>
                <h1 class="title"> Level Income</h1>
                <nav class="mt-2">
                  <ol class="breadcrumb mt-0 justify-content-start">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Level Income
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-md-6 level_icome">
              <Link to="/levelChart" class="level_card">
                <i class="ri-arrow-right-up-line"></i>
                <h4>{state}</h4>
                <div className="text-start mb-0 ps-3">
                  <span>Level</span>
                  <p>{levelIncomeData.length} Member</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-50">
        <div class="container">
          {/* <div class="tab-content custom-tab-content p-0">
            <div
              class="tab-pane fade show container active  table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded"
              id="funds"
            > */}
          <div class="table-responsive">
            <table class="table mb-0 ">
              <thead style={{ backgroundColor: "darkgoldenrod" }}>
                <tr>
                  <th>Sr No.</th>
                  <th>Name</th>
                  {/* <th>User ID</th> */}
                  <th>CITRA Coins in Flexi Wallet</th>
                  <th>Level Percentage</th>
                  <th>Level Income</th>
                </tr>
              </thead>
              {levelIncomeData.length > 0 ? (
                levelIncomeData.map((value, index) => (
                  <tbody>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{value?.from}</td>
                      {/* <td>{value?.userId}</td> */}
                      {value?.citra_balance ? (
                        <td>{value?.citra_balance}</td>
                      ) : (
                        <td>00</td>
                      )}
                      <td>{value?.interest}%</td>
                      <td>{value?.level_bonus}</td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tr rowSpan="5">
                  <td colSpan="6">
                    <p style={{ textAlign: "center" }}>No data Available</p>
                  </td>
                </tr>
              )}
            </table>
          </div>
          {/* </div>
          </div> */}
          <div className="col-md-12 text-end pt-4">
            <p className="h6 px-3">
              TOTAL EARINED COINS IN THIS LEVEL ={" "}
              <strong className="d-inline-flex align-items-center h5 fw-bold">
                {totalPrice}
                <img
                  src="images/icon/citra.png"
                  className="img-fluid ms-2"
                  width="20"
                  height="20"
                />{" "}
              </strong>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
