import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { Link } from "react-router-dom";

const FlexiWallet = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    stackingList();
  }, []);

  const stackingList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.StackList().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setTransactions(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  return (
    <>
      <section className="page_wrapper">
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1" />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2" />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3" />
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">Flexi Wallet</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Flexi Wallet</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="pb-50">
        <div className="container">
          <div className="tab-content custom-tab-content p-0">
            <div className="tab-pane fade show container active  table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="funds" >
              <div className="table-responsive">
                <table className="table ">
                  <thead style={{ backgroundColor: "darkgoldenrod" }}>
                    <tr>
                      <th>Sr No.</th>
                      <th>Total Deposited Citra Coin</th>
                      <th>25% Citra Coin</th>
                      <th>Total Coin</th>
                      <th>Total Month Locked</th>
                      <th>Already Completed Months</th>
                      <th>Total Released Citra Coin</th>
                      <th>Balance in Flexiwallet</th>
                    </tr>
                  </thead>
                  {transactions.length > 0 ? transactions.map((value, index) => (
                    <tbody>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{value?.totalDepositCoin.toFixed(5)}</td>
                        <td>{value?.extra.toFixed(5)}</td>
                        <td>
                          {(value?.totalDepositCoin + value?.extra).toFixed(5)}
                        </td>
                        <td>{value?.totalMonthLocked}</td>
                        <td>{value?.completedMonth}</td>
                        <td>{value?.releasedCoin.toFixed(5)}</td>
                        <td>{value?.balance.toFixed(5)}</td>
                      </tr>
                    </tbody>
                  )) : <tr rowSpan="5">
                    <td colSpan="6">
                      <p style={{ textAlign: "center" }}>
                        No data Available
                      </p>
                    </td>
                  </tr>}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FlexiWallet;
