import { ApiConfig } from "../../api/apiConfig/apiConfig";
import { makeApiRequest2, parseFullSymbol } from "./helpers";
const { io } = require("socket.io-client");

const channelToSubscription = new Map();
let socket = io(`${ApiConfig?.webSocketUrl}`, { transports: ['websocket'], upgrade: false, rejectUnauthorized: false, reconnectionAttempts: 5, debug: true });

export async function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscriberUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
	const channelString = symbolInfo.name;
	const handler = {
		id: subscriberUID,
		callback: onRealtimeCallback,
	};
	const parsedSymbol = parseFullSymbol(symbolInfo?.name);
	const ApiData = await makeApiRequest2(parsedSymbol?.fromSymbol, parsedSymbol?.toSymbol);
	let CoinID = ApiData?.currency_ids;
	let historicalDataLength = ApiData?.count;
	let subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem) {
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscriberUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);

	let payload = {
		'message': 'exchange',
		'base_currency_id': CoinID?.base_currency_id,
		'quote_currency_id': CoinID?.quote_currency_id,
		'cursor': historicalDataLength,
	}
	socket.emit('message', payload);
	socket.on('message', (data) => {
		let currPair = data?.pairs?.filter((item) => { return item?.base_currency === parsedSymbol?.fromSymbol && item?.quote_currency === parsedSymbol?.toSymbol })
		let tickerData;
		if (data?.ticker?.length === 0) {
			return;
		}
		tickerData = data?.ticker && data?.ticker[data?.ticker?.length - 1];
		const tradeTime = tickerData?.time;
		const volume = tickerData?.volume;
		const tradePrice = currPair[0]?.buy_price;
		const channelString = symbolInfo?.name;
		const subscriptionItem = channelToSubscription.get(channelString);
		if (subscriptionItem === undefined) {
			return;
		}
		const lastDailyBar = subscriptionItem?.lastDailyBar;
		if (!lastDailyBar) {
			return
		}
		function removeSecondsFromTimestamp(timestamp) {
			const date = new Date(timestamp);
			date.setSeconds(0);
			return date.getTime(); // Return the updated timestamp in milliseconds
		}
		const updatedTimestamp = removeSecondsFromTimestamp(lastDailyBar?.time);
		const nextDailyBarTime = (updatedTimestamp / 1000) + 65;
		let bar;
		if (tradeTime * 1000 > nextDailyBarTime * 1000) {
			bar = {
				time: tradeTime * 1000,
				open: tradePrice,
				high: tradePrice,
				low: tradePrice,
				close: tradePrice,
				volume: volume,
			};
			subscriptionItem.lastDailyBar = bar
			// console.log('[socket] Generate new bar', bar);
		} else {
			bar = {
				...lastDailyBar,
				high: Math.max(lastDailyBar?.high, tradePrice),
				low: Math.min(lastDailyBar?.low, tradePrice),
				close: tradePrice,
				volume: volume,
			};
			// console.log('[socket] Update the latest bar by ', tickerData);
		}
		subscriptionItem.lastDailyBar = bar;
		// Send data to every subscriber of that symbol
		onRealtimeCallback(bar)

	});
}

export function unsubscribeFromStream(subscriberUID) {
	// Find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		const handlerIndex = subscriptionItem.handlers
			.findIndex(handler => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers.length === 0) {

				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}