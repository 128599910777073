import React, { useContext } from "react";
import LandingPage from "../ui/pages/LandingPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthHeader from "../customComponent/AuthHeader";
import UserHeader from "../customComponent/UserHeader";
import Footer from "../customComponent/Footer";
import LoginPage from "../ui/pages/LoginPage";
import ForgotPassPage from "../ui/pages/ForgotPassPage";
import ReferralPage from "../ui/pages/ReferralPage";
import TermsOfUsePage from "../ui/pages/TermsOfUsePage";
import PrivacyPolicyPage from "../ui/pages/PrivacyPolicyPage";
import SignupPage from "../ui/pages/SignupPage";
import TradePage from "../ui/pages/TradePage";
import ProfilePage from "../ui/pages/ProfilePage";
import ContactPage from "../ui/pages/ContactPage";
import MarketPage from "../ui/pages/MarketPage";
import FundPage from "../ui/pages/FundPage";
import ComingSoonPage from "../ui/pages/ComingSoonPage";
import AboutUs from "../ui/pages/AboutUs";
import KycPage from "../ui/pages/KycPage";
import FlexiWallet from "../ui/pages/FlexiWallet";
import NotificationPage from "../ui/pages/NotificationPage";
import { RequiresAuth, RequiresAuthHeader } from "../requiresAuth";
import QuickBuy from "../ui/pages/quickBuy";
import { LevelIncome } from "../ui/pages/LevelIncome";
import { MemberPanel } from "../ui/pages/MemberPanel";
import { Levels } from "../ui/pages/Levels";
import { ProfileContext } from "../Context";
import { ToastContainer } from "react-toastify";

const Routing = () => {
  // const [profileState] = useContext(ProfileContext);
  const token = sessionStorage.getItem("token");


  return (
    <Router>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />
      <RequiresAuthHeader><UserHeader /></RequiresAuthHeader>
      {/* {!token ? <AuthHeader /> : <UserHeader />} */}
      <Routes>
        <Route exact path="/" element={<LandingPage />}></Route>
        <Route exact path="/login" element={<LoginPage />}></Route>
        <Route exact path="/signup" element={<SignupPage />}></Route>
        <Route exact path="/forgotpassword" element={<ForgotPassPage />}></Route>
        <Route exact path="/trade" element={<RequiresAuth><TradePage /></RequiresAuth>}></Route>
        <Route exact path="/levelIncome" element={<RequiresAuth><LevelIncome /></RequiresAuth>}></Route>
        <Route exact path="/memberPanel" element={<RequiresAuth><MemberPanel /></RequiresAuth>}></Route>
        <Route exact path="/quickBuy" element={<RequiresAuth><QuickBuy /></RequiresAuth>}></Route>
        <Route exact path="/levelChart" element={<RequiresAuth><Levels /></RequiresAuth>}></Route>
        <Route exact path="/market" element={<RequiresAuth><MarketPage /></RequiresAuth>}></Route>
        <Route exact path="/notifications" element={<RequiresAuth><NotificationPage /></RequiresAuth>}></Route>
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicyPage />}></Route>
        <Route exact path="/termsConditions" element={<TermsOfUsePage />}></Route>
        <Route exact path="/aboutus" element={<AboutUs />}></Route>
        <Route exact path="/contact" element={<ContactPage />}></Route>

        {/* {!token && !profileState.token ? (
          <Route path="*" element={<ComingSoonPage />} />
        ) : (
          <> */}
        <Route exact path="/funds" element={<RequiresAuth><FundPage /></RequiresAuth>}></Route>
        <Route exact path="/flexiWallet" element={<RequiresAuth><FlexiWallet /></RequiresAuth>}></Route>
        <Route exact path="/profile" element={<RequiresAuth><ProfilePage /></RequiresAuth>}></Route>
        <Route exact path="/referral" element={<RequiresAuth><ReferralPage /></RequiresAuth>}></Route>
        <Route exact path="/kyc" element={<RequiresAuth><KycPage /></RequiresAuth>}        ></Route>
        <Route exact path="/coming_soon" element={<ComingSoonPage />} />

        {/* </>
        )} */}
        <Route path="*" element={<ComingSoonPage />} />
      </Routes>
      <Footer />
    </Router >
  );
};

export default Routing;
