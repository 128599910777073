import { Navigate, useLocation } from "react-router-dom";
import React from "react";
import UserHeader from "../customComponent/UserHeader";
import AuthHeader from "../customComponent/AuthHeader";

export function RequiresAuth({ children }) {
  const token = sessionStorage.getItem("token");
  const location = useLocation();
  return token ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
function RequiresAuthHeader({ children }) {
  const token = sessionStorage.getItem("token");
  const location = useLocation();
  return token ? (
    children
  ) : (
    <AuthHeader />
  );
}
export { RequiresAuthHeader }