import React, { useContext, useEffect, useState } from "react";
import SettingsPage from "../SettingsPage";
import { Link } from "react-router-dom";
import { ProfileContext } from "../../../Context";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import TwofactorPage from "../TwofactorPage";
import CurrencyPrefrence from "../CurrencyPrefrence";
import BankAccount from "../BankAccount"
import KycPage from "../KycPage";
import SecurityPage from "../SecurityPage";
import ActivitylogPage from "../ActivitylogPage";
import DownloadReports from "../DownloadReports";
import TransferHistory from "../TransferHistory";
import { Levels } from "../Levels";


const AccountPage = () => {
  const [activeTab, setActiveTab] = useState("settings");

  // ********* Context ********** //
  const { firstName, email, lastName, userImage, kycStatus, handleUserDetials } = useContext(ProfileContext);
  useEffect(() => {
    handleUserDetials();
  }, []);

  return (
    <>
      {/* Start banner area */}
      <section className="page_wrapper">
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1" />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2 " />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3" />
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">Account Settings</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Account Settings
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      {/* End banner area */}
      <section className="pb-90 account_sec ">
        <div className="container-fluid fluid-header">
          <form>
            <div className="create-item-wrapper my_acc ">
              <div className="row">
                <div className="col-lg-3 col-md-12 col-md-4">
                  {/*  file upload area  */}
                  <div className=" d-flex align-items-center ">
                    {!userImage ? (<img className="img-account-profile me-3" src="images/profilelogo.png" />
                    ) : (
                      <img className="img-account-profile me-3" src={`${ApiConfig.baseUrl + userImage}`} />
                    )}
                    <div>
                      <h5 className="fw-bolder pp-name fs-4 mb-0">
                        {`${firstName + ' ' + lastName}`}
                        <small className="text-success ms-2">
                          {kycStatus === 2 &&
                            <i className="ri-checkbox-circle-line"></i>
                          }
                        </small>
                      </h5>
                      <div className=" text-gray">
                        <small>{email && email}</small>
                      </div>
                    </div>
                  </div>
                  <div className="acc_tabs form-field-wrapper mt-5 mb-5">
                    <ul className="nav nav-pills flex-column" id="cardTab" role="tablist">
                      <li onClick={() => setActiveTab("settings")} className={activeTab === "settings" && "active"}>
                        <a className="menu-link  nav-link active" id="settings" href="#settings" data-bs-toggle="tab" role="tab" aria-controls="settings" aria-selected="true">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title">
                            My Profile
                          </span>
                        </a>
                      </li>
                      <li onClick={() => setActiveTab("currency")} className={activeTab === "currency" && "active"}>
                        <a className="menu-link  nav-link" id="Currency-pill" href="#CurrencyPill" data-bs-toggle="tab" role="tab" aria-controls="Currency"
                          aria-selected="true">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title" >
                            Currency Preference
                          </span>
                        </a>
                      </li>
                      <li className="active"
                       /* onClick={() => setActiveTab('kyc')} className={activeTab === 'kyc' && 'active'} */>
                        <Link className="menu-link  nav-link" id="kyc" to="/kyc">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title" >
                            KYC Verification
                          </span>
                        </Link>
                      </li>

                      <li onClick={() => setActiveTab("bank")} className={activeTab === "bank" && "active"}>
                        <a className="menu-link nav-link" id="Notification-pill" href="#NotificationPill" data-bs-toggle="tab" role="tab"
                          aria-controls="Notification" aria-selected="false">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title">
                            Bank Account Details
                          </span>
                        </a>
                      </li>

                      {/* <li className="active" */}
                      {/* /* onClick={() => setActiveTab('kyc')} className={activeTab === 'kyc' && 'active'} */}
                      {/* <Link className="menu-link  nav-link" id="notifications" to="/notifications">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title" >
                            Notifications
                          </span>
                        </Link>
                      </li> */}

                      {/* <li className="active">
                        <Link className="menu-link nav-link" id="notifications" to="/levelIncome">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span
                            className="menu-title"                            
                          >
                            {" "}
                            Get Level Income
                          </span>
                        </Link>
                      </li> */}

                      {/* <li
                        className="active"
                      >
                        <Link
                          className="menu-link  nav-link"
                          id="notifications"
                          to="/memberPanel"
                        >
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span
                            className="menu-title"
                            
                          >
                            {" "}
                            Member Panel
                          </span>
                        </Link>
                      </li> */}

                      <li onClick={() => setActiveTab("twofactor")} className={activeTab === "twofactor" && "active"}>
                        <a className="menu-link nav-link" id="Authencation-pill" href="#AuthencationPill" data-bs-toggle="tab" role="tab"
                          aria-controls="Authencation" aria-selected="false">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title" >
                            Two Factor Authencation
                          </span>
                        </a>
                      </li>
                      <li onClick={() => setActiveTab("security")} className={activeTab === "security" && "active"}>
                        <a className="menu-link nav-link" id="Security-pill" href="#SecurityPill" data-bs-toggle="tab" role="tab"
                          aria-controls="Security" aria-selected="false">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title" >
                            Security
                          </span>
                        </a>
                      </li>
                      <li onClick={() => setActiveTab("activity")} className={activeTab === "security" && "active"}>
                        <a className="menu-link  nav-link" id="Activity-pill" href="#ActivityPill" data-bs-toggle="tab" role="tab" aria-controls="Activity"
                          aria-selected="false">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title" >
                            Activity Logs
                          </span>
                        </a>
                      </li>
                      {/* <li className="active" onClick={() => setActiveTab("transferhistory")} className={activeTab === "transferhistory" && "active"}>
                        <a className="menu-link  nav-link" id="Transitions-pill" href="#TransitionsPill" data-bs-toggle="tab" role="tab"
                          aria-controls="example" aria-selected="false">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title" >
                            Transfer History
                          </span>
                        </a>
                      </li> */}
                      {/* <li className="active">
                        <Link
                          className="menu-link  nav-link"
                          to="/referral"
                          id="Transitions-pill"
                        >
                          {" "}
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span
                            className="menu-title"
                            
                          >
                            {" "}
                            Level Income
                          </span>
                        </Link>
                      </li> */}
                      {/* <li
                        className="active"
                        onClick={() => setActiveTab("levels")}
                        className={activeTab === "levels" && "active"}
                      >
                        <a
                          className="menu-link nav-link"
                          data-bs-toggle="tab"
                          role="tab"
                          aria-controls="reportDownload"
                          aria-selected="false"
                        >
                          {" "}
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span
                            className="menu-title"
                            
                          >
                            {" "}
                            Level Chart
                          </span>
                        </a>
                      </li> */}
                      <li onClick={() => setActiveTab("reportDownload")} className={activeTab === "reportDownload" && "active"}>
                        <a className="menu-link nav-link" data-bs-toggle="tab" role="tab" aria-controls="reportDownload" aria-selected="false">
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title" >
                            Download Reports
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-9 col-md-12 col-md-4">
                  {activeTab === "settings" && <SettingsPage />}
                  {activeTab === "bank" && <BankAccount />}
                  {activeTab === "kyc" && <KycPage />}
                  {activeTab === "currency" && <CurrencyPrefrence />}
                  {/* {activeTab === "Notification" && <NotificationPage />} */}
                  {activeTab === "twofactor" && (<TwofactorPage />)}
                  {activeTab === "security" && <SecurityPage />}
                  {activeTab === "activity" && <ActivitylogPage />}
                  {activeTab === "transferhistory" && <TransferHistory />}
                  {activeTab === "levels" && <Levels />}
                  {activeTab === "reportDownload" && <DownloadReports />}
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AccountPage;
