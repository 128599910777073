import { ApiConfig } from "../apiConfig/apiConfig";
import { ApiCallGet, ApiCallPost, ApiCallPut } from "../apiConfig/apiCall";
import { ConsoleLogs } from "../../utils/ConsoleLogs";
const TAG = "AuthService";

const AuthService = {

  login: async (signId, password) => {
    const { baseAuth, login } = ApiConfig;
    const url = baseAuth + login;
    const params = {
      email_or_phone: signId,
      password: password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  register: async (firstName, lastName, countryCode, signId, password, cPassword, otp, invitation) => {
    const { baseAuth, register } = ApiConfig;
    const url = baseAuth + register;
    const params = {
      firstName: firstName,
      lastName: lastName,
      cid: +countryCode.trim(),
      email_or_phone: signId,
      password: password,
      confirm_password: cPassword,
      referral_code: invitation,
      verification_code: otp,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  getCode: async (signId, type, otp) => {
    const { baseAuth, getcode } = ApiConfig;
    const url = baseAuth + getcode;
    const params = {
      "email_or_phone": signId,
      "type": type,
      "otp": +otp
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getCode2: async (signid) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signid,
      type: "forgot",
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getOtpresend: async (signid, type) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signid,
      resend: true,
      type: type,

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  ChangePassOtp: async (signid) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signid,
      resend: true
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getUpiOtp: async (signId) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;

    const params = {
      signId: signId,
      // type: "Login",
    };

    ConsoleLogs(TAG + ", getOtp", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", getOtp",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );

    const headers = {
      "Content-Type": "application/json",
    };

    return ApiCallPost(url, params, headers);
  },

  forgotPassword: async (signId, otp, password, cPassword) => {
    const { baseAuth, forgotpassword } = ApiConfig;
    const url = baseAuth + forgotpassword;
    const params = {
      email_or_phone: signId,
      verification_code: +otp,
      new_password: password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  verifyId: async (id) => {
    const { baseUrl, verifyId } = ApiConfig;

    const url = baseUrl + verifyId;

    const params = {
      hex: id,
    };

    ConsoleLogs(TAG + ", verifyId", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", verifyId",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );

    const headers = {
      "Content-Type": "application/json",
    };

    return ApiCallPost(url, params, headers);
  },

  emailVerify: async (id) => {
    const { baseUrl, emailVerify } = ApiConfig;

    const url = baseUrl + emailVerify;

    const params = {
      hex: id,
      type: "signup",
    };

    ConsoleLogs(TAG + ", emailVerify", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", emailVerify",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );

    const headers = {
      "Content-Type": "application/json",
    };

    return ApiCallPost(url, params, headers);
  },
  resetPassword: async (newPassword, rePassword, id) => {
    const { baseUrl, resetPassword } = ApiConfig;

    const url = baseUrl + resetPassword;

    const params = {
      newpass: newPassword,
      confpass: rePassword,
      hex: id,
    };

    ConsoleLogs(TAG + ", resetPassword", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", resetPassword",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );

    const headers = {
      "Content-Type": "application/json",
    };

    return ApiCallPost(url, params, headers);
  },

  support: async (name, email, mobileNumber, subject, message) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, support } = ApiConfig;

    const url = baseAuth + support;

    const params = {
      name: name,
      email: email,
      phone: mobileNumber,
      subject: subject,
      message: message,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  otpVerify: async (otp, userId, emailId, first, last, mobileNo, vCode) => {
    const { baseUrl, otpVerify } = ApiConfig;

    const url = baseUrl + otpVerify;

    const params = {
      userId: userId, emailId: emailId, firstName: first, lastName: last, mobileNumber: mobileNo, motp: vCode, otp: true, otpType: otp,
    };

    ConsoleLogs(TAG + ", otpVerify", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", otpVerify",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );

    const headers = {
      "Content-Type": "application/json",
    };

    return ApiCallPost(url, params, headers);
  },

  addkyc: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, addkyc } = ApiConfig;
    const url = baseAuth + addkyc;

    ConsoleLogs(TAG + ", addkyc", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };

    return ApiCallPost(url, formData, headers);
  },

  getDetails: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, getDetails } = ApiConfig;
    const url = baseAuth + getDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getBankDetails: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, userBankDetails } = ApiConfig;
    const url = baseAuth + userBankDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getOtp: async (signid, resend) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signid,
      "resend": true
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  addBankDetails: async (accountType, bankName, holderName, accountNumber, ifscCode, branchAddress) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, addBankDetails } = ApiConfig;
    const url = baseAuth + addBankDetails;
    const params = {
      account_type: accountType,
      bank_name: bankName,
      account_holder_name: holderName,
      account_number: accountNumber,
      ifsc_code: ifscCode,
      branch_name: branchAddress,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },



  // getDetails: async () => {
  //   const token = sessionStorage.getItem("token");
  //   const { baseAuth, getDetails } = ApiConfig;
  //   const url = baseAuth + getDetails;
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   };
  //   return ApiCallGet(url, headers);
  // },

  updateSettings: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, updateSettings } = ApiConfig;
    const url = baseAuth + updateSettings;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };
    return ApiCallPut(url, formData, headers);
  },

  getProfile: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, getDetails } = ApiConfig;

    const url = baseAuth + getDetails;

    const params = {};

    ConsoleLogs(TAG + ", getNotifications", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  googleAuth: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, googleAuth } = ApiConfig;
    const url = baseAuth + googleAuth;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  update2fa: async (authType, code, verifyType) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, update2fa } = ApiConfig;
    const url = baseAuth + update2fa;
    const params = {
      type: authType,
      verification_code: +code,
      email_or_phone: verifyType
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },


  setCurrency: async (currencyType) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, setCurrency } = ApiConfig;
    const url = baseAuth + setCurrency;
    const params = {
      currency: currencyType,
    };

    ConsoleLogs(TAG + ", setCurrency", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPut(url, params, headers);
  },

  setSecurity: async (inputData, code, signId) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, setSecurity } = ApiConfig;
    const url = baseAuth + setSecurity;
    const params = {

      current_password: inputData.currpassword,
      new_password: inputData.newpassword,
      confirm_password: inputData.conPassword,
      verification_code: +code,
      email_or_phone: signId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPut(url, params, headers);
  },

  getActivityLogs: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, activityLogs } = ApiConfig;
    const url = baseAuth + activityLogs;
    ConsoleLogs(TAG + ", activityLogs", `url : ' + ${url}`);
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },

  getTransHistory: async () => {
    const token = sessionStorage.getItem("token");
    const { baseTrans, thistory } = ApiConfig;

    const url = baseTrans + thistory;

    const params = {};

    ConsoleLogs(TAG + ", thistory", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  getConversionHistory: async () => {
    const token = sessionStorage.getItem("token");
    const { baseTrans, chistory } = ApiConfig;

    const url = baseTrans + chistory;

    const params = {};

    ConsoleLogs(TAG + ", chistory", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  getUserfunds: async () => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, userfunds } = ApiConfig;
    const url = baseWallet + userfunds;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getbnbbalance: async () => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, getbnbbalance } = ApiConfig;
    const url = baseWallet + getbnbbalance;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  generateAddress: async (currID, chain) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, generateAddress } = ApiConfig;
    const url = baseWallet + generateAddress;
    const params = {
      "currency_id": currID,
      "chain": chain
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },


  estimatedPortfolio: async () => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, estimatedPortfolio } = ApiConfig;
    const url = baseWallet + estimatedPortfolio;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  tradeHistory: async () => {
    const token = sessionStorage.getItem('token');
    const { baseTrans, tradeHistory } = ApiConfig;
    const url = baseTrans + tradeHistory;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  ConversionHistory: async () => {
    const token = sessionStorage.getItem('token');
    const { baseSwap, ConversionHistory } = ApiConfig;
    const url = baseSwap + ConversionHistory;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },






  walletHistory: async () => {
    const token = sessionStorage.getItem("token");
    const { baseTrans, walletHistory } = ApiConfig;
    const url = baseTrans + walletHistory;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getIfscDetails: async (ifscCode) => {
    const token = sessionStorage.getItem("token");

    const { baseKyc, checkifsc } = ApiConfig;
    const url = baseKyc + checkifsc;
    const params = {
      ifsc: ifscCode,
    };
    ConsoleLogs(TAG + ", getIfscDetails", `url : ' + ${url}`);
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  Contactus: async (name, email, mobileNumber, subject, message) => {
    const token = sessionStorage.getItem("token");

    const { baseAuth, Contactform } = ApiConfig;
    const url = baseAuth + Contactform;
    const params = {
      name: name,
      email: email,
      phone: mobileNumber,
      sub: subject,
      desc: message,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getTradingReport: async (type, timePeriod) => {
    const token = sessionStorage.getItem("token");
    const { baseExchange, tradeReportDate } = ApiConfig;
    const url = baseExchange + tradeReportDate;
    const params = {
      type: type,
      timePeriod: timePeriod,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  addStackingCoin: async (currencyId, coinAmount, otp) => {
    const token = sessionStorage.getItem("token");
    const { baseStacking, stackcoin } = ApiConfig;
    const url = baseStacking + stackcoin;
    const params = {
      currency_id: currencyId,
      amount: coinAmount,
      otp: otp

    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  stackingList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseStacking, getUserStacking } = ApiConfig;

    const url = baseStacking + getUserStacking;

    const params = {};

    ConsoleLogs(TAG + ", getUserStacking", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  addUPI: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, addUPI } = ApiConfig;
    const url = baseAuth + addUPI;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };

    return ApiCallPost(url, formData, headers);
  },

  handleZipCode: async (zipCode) => {
    const { baseKyc, getZipCode } = ApiConfig;
    const url = baseKyc + getZipCode;

    const params = {
      pincode: +zipCode,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    return ApiCallPost(url, params, headers);
  },

  levelUserList: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, getLevels } = ApiConfig;
    const url = baseAuth + getLevels;
    const params = {
      userId: userId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },


  notifications: async () => {
    const token = sessionStorage.getItem("token");
    const { baseNotify, notify } = ApiConfig;
    const url = baseNotify + notify;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  placeOrder: async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, placeOrder } = ApiConfig;
    const url = baseExchange + placeOrder;

    const params = {
      "order_type": infoPlaceOrder,
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
      "side": side,
      "price": +buyprice,
      "quantity": +buyamount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },
  pastOrder: async (base_currency_id, quote_currency_id) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, pastOrder } = ApiConfig;
    const url = baseExchange + pastOrder;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },
  cancelOrder: async (orderId) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, cancelOrder } = ApiConfig;
    const url = baseExchange + cancelOrder;
    const params = {
      "order_id": orderId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  coinDetails: async (currency_id) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, coinDetails } = ApiConfig;
    const url = baseExchange + coinDetails;
    const params = {
      "currency_id": currency_id
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },
  withdrawalCurrency: async (currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, withdrawalCurrency } = ApiConfig;
    const url = baseWallet + withdrawalCurrency;
    const params = {
      "address": walletAddress,
      "amount": +withdrawAmount,
      "chain": selectedChain,
      "currency_id": currencyId,
      "email_or_phone": signId,
      "otp": otp,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyDeposit: async () => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, verifyDeposit } = ApiConfig;
    const url = baseWallet + verifyDeposit;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getCoin: async () => {
    const token = sessionStorage.getItem("token");
    const { baseCoin, getCoin } = ApiConfig;
    const url = baseCoin + getCoin;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  favoriteCoin: async (pairId) => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, favoriteCoin } = ApiConfig;
    const url = baseAuth + favoriteCoin;
    const params = {
      "pair_id": pairId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);

  },

  favoriteList: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, favoriteList } = ApiConfig;
    const url = baseAuth + favoriteList;

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  handleBuyConvertAmount: async (side, currency, amountToPay) => {
    const token = sessionStorage.getItem("token");
    const { baseSwap, converttoken } = ApiConfig;
    const url = baseSwap + converttoken;
    const params = {
      base_currency: "CITRA",
      quote_currency: currency,
      amount: amountToPay,
      side: side,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  handleSellConvertAmount: async (side, currency, amountToSell) => {
    const token = sessionStorage.getItem("token");
    const { baseSwap, converttoken } = ApiConfig;
    const url = baseSwap + converttoken;
    const params = {
      base_currency: "CITRA",
      quote_currency: currency,
      amount: +amountToSell,
      side: side,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  buy: async (currency, amountToPay, amountToBuy) => {
    const token = sessionStorage.getItem("token");
    const { baseSwap, buy } = ApiConfig;
    const url = baseSwap + buy;
    const params = {
      base_currency: "CITRA",
      quote_currency: currency,
      amount: amountToPay,
      swapped_amount: amountToBuy,
      side: "BUY",
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  sell: async (currency, amountToSell, sellAmount) => {
    const token = sessionStorage.getItem("token");
    const { baseSwap, buy } = ApiConfig;
    const url = baseSwap + buy;
    const params = {
      base_currency: "CITRA",
      quote_currency: currency,
      amount: +amountToSell,
      swapped_amount: sellAmount,
      side: "SELL",
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  balanceByCoinName: async (currency) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, balanceByName } = ApiConfig;
    const url = baseWallet + balanceByName;
    const params = {
      currency: currency,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  getPairs: async () => {
    const token = sessionStorage.getItem('token');
    const { baseCoin, getPairs } = ApiConfig;
    const url = baseCoin + getPairs;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  StackList: async () => {
    const token = sessionStorage.getItem('token');
    const { baseStacking, stackinglist } = ApiConfig;
    const url = baseStacking + stackinglist;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  getHistoricalData: async (base, quote, from, to) => {
    console.log(from, to, ',from,to');
    const token = sessionStorage.getItem('token');
    const { baseExchange, getHistoricalData } = ApiConfig;
    const url = baseExchange + getHistoricalData;
    const params = {
      base_currency: base,
      quote_currency: quote,
      from: from,
      to: to,
      limit: 2000,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },
  cryptoCompareApi: async (base, quote, to, resolution) => {
    const token = sessionStorage.getItem('token');
    const url = `https://min-api.cryptocompare.com/data/v2/${resolution}?fsym=${base}&tsym=${quote}&toTs=${to}`
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

};

export default AuthService;
