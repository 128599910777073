import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DefaultInput from "../../../customComponent/DefaultInput";
import { validPassword, notEqualsZero, matchPassword } from "../../../utils/Validation";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { ProfileContext } from "../../../Context";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const SecurityPage = () => {



    const { email, mobile } = useContext(ProfileContext);


    const [code, setCode] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const [inputData, setinputData] = useState({});
    const [newpassword, setNewpassword] = useState(false);
    const [conshowPassword, setConshowPassword] = useState(false);

    const navigate = useNavigate();

    const handleLogOut = () => {
        sessionStorage.clear();
        navigate("/login");
        window.location.reload();

    }

    const emailId = sessionStorage.getItem("emailId")
    const mobileNumber = sessionStorage.getItem("mobileNumber")


    const handleSecutity = async (inputData, code) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.setSecurity(inputData, code, emailId ? emailId : mobileNumber).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false)
                try {
                    alertSuccessMessage(result.message);
                    handleLogOut();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false)
                alertErrorMessage(result.message);
            }
        });
    }


    const handleGetOtp1 = async (signId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.ChangePassOtp(signId).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    alertSuccessMessage(email ? "OTP sent to your Registered Email" : "OTP sent to your Registered Mobile Number");
                    //setIsShow(true);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(
                    "EmailId does not exist. Please update your emailId."
                );
            }
        });
    };
    const handleInputChange1 = (event) => {
        const { name, value } = event.target;
        setinputData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
            <div className="upload-formate mb-6 d-flex justify-content-between align-items-center">
                <div>
                    <h3 className="mb-1">
                        Security
                    </h3>
                    <p className="formate mb-0">
                        Fill the form below to change your password.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-8 m-auto">
                    <div className="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-4">
                        <form>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="field-box">
                                        {console.log(validPassword(inputData.currpassword), "inputData.currpasswordinputData.currpassword")}
                                        <label htmlFor="text" className="form-label">Current Password</label>
                                        <div className="field-otp-box">
                                            <DefaultInput errorstatus={validPassword(inputData.currpassword)}
                                                errormessage={validPassword(inputData.currpassword)} value={inputData.currpassword}
                                                type={showPassword ? "text" : "password"} name="currpassword" className="form-control" aria-describedby="password"
                                                placeholder="Enter Your password.." onChange={(e) => handleInputChange1(e)} />
                                            <button type="button" className="btn btn-sm btn-dark " onClick={() => { setShowPassword(!showPassword) }}>
                                                {!showPassword ? <i className="ri-eye-off-line pe-0"></i> : <i className="ri-eye-line pe-0"></i>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <div className="field-box">
                                        <label htmlFor="text" className="form-label">New Password</label>
                                        <div className="field-otp-box">
                                            <DefaultInput type={newpassword ? "text" : "password"} name="newpassword" placeholder="Enter password" value={inputData.newpassword} errorstatus={validPassword(inputData.newpassword) !== undefined && notEqualsZero(inputData.newpassword)} errormessage={validPassword(inputData.newpassword)} onChange={(e) => handleInputChange1(e)} />
                                            <button type="button" className="btn btn-sm btn-dark " onClick={() => { setNewpassword(!newpassword) }}>
                                                {!newpassword ? <i className="ri-eye-off-line pe-0"></i> : <i className="ri-eye-line pe-0"></i>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <div className="field-box">
                                        <label htmlFor="text" className="form-label">Confirm New Password</label>
                                        <div className="field-otp-box">
                                            <DefaultInput type={conshowPassword ? "text" : "password"} name="conPassword" placeholder="Enter confirm Password" value={inputData.conPassword} errorstatus={validPassword(inputData.conPassword) !== undefined && notEqualsZero(inputData.conPassword) || matchPassword(inputData.newpassword, inputData.conPassword)} errormessage={validPassword(inputData.conPassword) || matchPassword(inputData.newpassword, inputData.conPassword)} onChange={(e) => handleInputChange1(e)} />
                                            <button type="button" className="btn btn-sm btn-dark " onClick={() => { setConshowPassword(!conshowPassword) }}>
                                                {!conshowPassword ? <i className="ri-eye-off-line pe-0"></i> : <i className="ri-eye-line pe-0"></i>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label htmlFor="text" className="form-label">OTP</label>
                                    <div className="field-box field-otp-box">
                                        <DefaultInput type="number" name="password" value={code} onChange={(e) => setCode(e.target.value)} onWheel={(e) => e.target.blur()} />
                                        <button className="btn btn-sm btn-gradient" type="button" onClick={(e) => {
                                            handleGetOtp1(email ? email : mobile);
                                        }}>
                                            <span>Get Otp</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4 mt-4">
                                    <div className="field-box">
                                        <button className="btn btn-gradient w-100 justify-content-center btn-medium {" type="button" onClick={() => handleSecutity(inputData, code)}><span>Submit</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default SecurityPage;