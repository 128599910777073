import React from "react";
import "@rainbow-me/rainbowkit/styles.css";
import Routing from "./Routing";
import Loading from "./customComponent/Loading";
import LoaderHelper from "./customComponent/Loading/LoaderHelper";
import { ProfileProvider } from "./Context";


function App() {
  return (
    <ProfileProvider>
      <Routing />
      <Loading ref={ref => LoaderHelper.setLoader(ref)} />
    </ProfileProvider>

  );
}

export default App;
