import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import 'swiper/css';
import "swiper/css/pagination";


const ActivitylogPage = () => {

    const [activity, setActivity] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);



    useEffect(() => {
        activityLogs();
    }, []);

    const activityLogs = async () => {
        LoaderHelper.loaderStatus(true)
        await AuthService.getActivityLogs().then(async result => {
            LoaderHelper.loaderStatus(false)
            if (result.success) {
                setActivity(result.data)
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    //******************* Custom Pagination *********************//
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = Math.ceil(activity ? activity.length / itemsPerPage : []);
    const currentItems = activity ? activity.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : "";


    return (
        <>
            <div class="tab-pane" id="ActivityPill" role="tabpanel" aria-labelledby="Activity-pill">
                <div class="upload-formate mb-6 d-flex justify-content-between align-items-center">
                    <div>
                        <h3 class="mb-1">
                            Activity Logs
                        </h3>
                        <p class="formate mb-0">
                            your  Activity Logs display for all Activity
                        </p>
                    </div>
                </div>
                {Object.keys(activity).length === 0 ?
                    <div className="favouriteData">
                        <img src="images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                        <br />
                        <p className="mt-3 mb-4" > No Data Found. </p>
                    </div>
                    :
                    <div class="row">
                        <div class="col-md-12 m-auto">
                            <div class="form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded">
                                <div class="activity-wrapper">
                                    <div class="custom-history">
                                        {activity.length > 0 ?
                                            activity.map(item =>
                                                <div class="single-item-history d-flex-center p-3 m-0">
                                                    <div class="content">
                                                        <p> {item?.activity}</p>
                                                        <p>IP Address: {item?.ipAddress}</p>
                                                        <p>Page Visited	: {item?.page}</p>
                                                    </div>
                                                    <small class="date align-self-start text-end">
                                                        Date/Time	: <br /> {moment(item?.date).format('MMMM Do YYYY, h:mm:ss a')}
                                                    </small>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>

                            </div>
                            <ReactPaginate
                                pageCount={pageCount}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                containerClassName={'customPagination text-dark'}
                                activeClassName={'active'} />
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default ActivitylogPage;