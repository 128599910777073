import React, { useContext, useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import { validAccountno, validIfscCode } from "../../../utils/Validation";
import DefaultInput from "../../../customComponent/DefaultInput";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { ProfileContext } from "../../../Context";

const BankAccount = () => {
  /* Bank tab Funcationlty Start */
  const { email, mobile } = useContext(ProfileContext);
  const [holderName, setHolderName] = useState("");
  const [bankdetails, setBankDetails] = useState({});
  const [acNumber, setAcNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [account, setAccount] = useState("Saving");
  const [branch, setBranch] = useState("");
  const [bankname, setbankname] = useState("");
  const [upiId, setUpiId] = useState("");
  const [qrCode, setQrCode] = useState();
  const [myFile, setMyFile] = useState("");
  const [signId, setSignId] = useState();
  const [code, setCode] = useState("");

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "bankName":
        setbankname(event.target.value);
        console.log(event.target.value);
        break;
      case "branchAddress":
        setBranch(event.target.value);
        // handleIfsc(ifscCode);
        console.log(event.target.value);
        break;
      case "accountNumber":
        setAcNumber(event.target.value);
        console.log(event.target.value);
        break;
      case "accountType":
        setAccount(event.target.value);
        console.log(event.target.value);
        break;
      case "ifscCode":
        setIfsc(event.target.value);

        console.log(event.target.value);
        break;
      case "holderName":
        setHolderName(event.target.value);
        console.log(event.target.value);
        break;

      default:
        break;
    }
  };

  const resetInput = () => {
    setHolderName("");
    setIfsc("");
    setAccount("");
    setAcNumber("");
    setBranch("");
    setbankname('')
  };

  const handleMessageQuery = async (accountType, bankName, holderName, accountNumber, ifscCode, branchAddress) => {
    await AuthService.addBankDetails(accountType, bankName, holderName, accountNumber, ifscCode, branchAddress).then(async (result) => {
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          getBankDetails();
          resetInput();
        } catch (error) {
          alertErrorMessage(error);
          console.log("error", `${error}`);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  useEffect(() => {
    getBankDetails();
    handleDetials();
  }, []);

  console.log(qrCode);

  const getBankDetails = async () => {
    await AuthService.getBankDetails().then(async (result) => {
      if (result.sucess) {
        setUpiId(result?.data?.upi_id);
        setQrCode(result?.data?.qr_code);
        setBankDetails(result?.data);
        setAcNumber(result?.data?.account_number);
        setIfsc(result?.data?.ifsc_code);
        setAccount(result?.data?.account_type.toUpperCase());
        setBranch(result?.data?.branch_name.toUpperCase());
        setbankname(result?.data?.bank_name.toUpperCase());
        setHolderName(result?.data?.account_holder_name.toUpperCase())
      } else {
        alertErrorMessage(result.message);
      }
    });
  };
  console.log(qrCode);

  const handleDetials = async () => {
    await AuthService.getProfile().then(async (result) => {
      // if (result.sucess) {
      try {
        setSignId(result?.message?.emailId);
      } catch (error) {
        alertErrorMessage(error);
        console.log("error", `${error}`);
      }
      // } else {
      //   alertErrorMessage(result?.message);
      // }
    });
  };

  const handleImage = async (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    setMyFile(fileUploaded);
  };

  const handleupi = async (upiId, myFile, code, signId) => {
    var formData = new FormData();
    formData.append("upi_id", upiId);
    formData.append("qr-image", myFile);
    formData.append("email_or_phone", signId);
    formData.append("verification_code", code);

    LoaderHelper.loaderStatus(true);
    await AuthService.addUPI(formData).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result.message);
          getBankDetails();
          setUpiId("")
          setMyFile()
          setSignId('')
          setCode("")
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleGetOtp = async (signId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtpresend(signId).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage("OTP sent to your emailId");
          //setIsShow(true);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(
          "EmailId does not exist. Please update your emailId."
        );
      }
    });
  };

  return (
    <>
      <div class="upload-formate mb-6 d-flex justify-content-between align-items-center">
        <div>
          <h3 class="mb-1">Payment Options</h3>
          <p class="formate mb-0">
            Select your payment options for all transtions.
          </p>
        </div>
        <button
          class=" btn btn-gradient btn-small justify-content-center"
          data-bs-toggle="modal"
          data-bs-target="#Withdraw_modal"
          type="button"
        >
          <span>{Object.keys(bankdetails).length != 0 ? 'Edit Bank' : 'Add  Bank'}</span>
        </button>
        <button
          class=" btn btn-gradient btn-small justify-content-center"
          data-bs-toggle="modal"
          data-bs-target="#upi_modal"
          type="button"
        >
          <span>{bankdetails?.upi_id ? 'Edit UPI' : 'Add UPI Id'} </span>
        </button>
      </div>

      {/* {Object.keys(bankdetails).length === 0  && (
        //  Object.keys(upiDetails).length === && (
        <div className="favouriteData">
          <img
            src="images/no-data.svg"
            className="img-fluid"
            width="96"
            height="96"
            alt=""
          />
          <br />
          <p className="mt-3 mb-4"> No Data Found. </p>
        </div>
      ) */}
      {/* // : ( */}

      {Object.keys(bankdetails).length != 0 ? (
        <div class="form-field-wrapper  mb-3 ">
          <div class="acc_details">
            <div class="row">
              <div class="col-md-4">
                <h5 class="text-start">
                  <small>UPI Id</small> <br />{bankdetails?.upi_id}
                </h5>
              </div>
              <div class="col-md-4">
                <h5 class="text-start">
                  <small>QR Code</small> <br />
                  <img src={ApiConfig.baseUrl + bankdetails?.qr_code} />
                </h5>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {Object.keys(bankdetails).length != 0 ? (
        <div class="form-field-wrapper bank-acc mb-3 ">
          <div class="d-flex align-items-start justify-content-between">
            <h5 class="text-start">
              <small>Bank Account</small>
              <br />
              {bankdetails?.account_number}
            </h5>
          </div>
          <div class="acc_details">
            <div class="row">
              <div class="col-md-4">
                <h5 class="text-start">
                  <small>Account Holder Name</small> <br /> {bankdetails?.account_holder_name}
                </h5>
              </div>
              <div class="col-md-4">
                <h5 class="text-start">
                  <small>Account Number</small> <br /> {bankdetails?.account_number}
                </h5>
              </div>
              <div class="col-md-4">
                <h5 class="text-start">
                  <small>IFSC Code</small> <br />
                  {bankdetails?.ifsc_code}
                </h5>
              </div>
              <div class="col-md-4">
                <h5 class="text-start">
                  <small>Account Type</small> <br />
                  {bankdetails?.account_type}
                </h5>
              </div>
              <div class="col-md-4">
                <h5 class="text-start">
                  <small>Branch Address</small> <br />
                  {bankdetails?.branch_name}
                </h5>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* {Object.keys(bankdetails).length === 0 &&
        Object.keys(upiDetails).length === 0 && (
          <div className="favouriteData">
            <img
              src="images/no-data.svg"
              className="img-fluid"
              width="96"
              height="96"
              alt=""
            />
            <br />
            <p className="mt-3 mb-4"> No Data Found. </p>
          </div>
        )} */}

      {/* // )} */}

      {/* add bank modal */}

      <div
        class="modal fade"
        id="Withdraw_modal"
        tabindex="-1"
        aria-labelledby="Withdraw_modalLaebl"
        aria-hidden="true"
      >

        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Edit Account details
              </h3>
              <button
                type="button"
                class="btn-custom-closer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="ri-close-fill"></i>
              </button>
            </div>
            {/* <!-- End modal-header --> */}
            <div class="modal-body px-8 py-5">
              <form>
                <div class="form-group mb-4 d-inline-block w-100 ">
                  <label for="royality" class="form-label">
                    Select Bank Type
                  </label>
                  <select
                    id="royality"
                    name="accountType"
                    className=" "
                    value={account}
                    onChange={handleInputChange}
                  >
                    <option value="saving">Saving Account</option>
                    <option value="Current">Current Account</option>
                    <option value="deposit">Fixed Deposit account</option>
                  </select>
                </div>
                <div class="form-group mb-4 ">
                  <label for="bit"> Bank Name </label>
                  <input
                    type="text"
                    id="bit"
                    className="form-control"
                    name="bankName"
                    value={bankname}
                    onChange={handleInputChange}
                  />
                </div>
                <div class="form-group mb-4">
                  <label for="bit"> Account Holder Name </label>
                  <input
                    type="text"
                    className="form-control"
                    name="holderName"
                    value={holderName}
                    onChange={handleInputChange}
                  />
                </div>
                <div class="form-group mb-4">
                  <label for="bit"> Account Number</label>
                  <DefaultInput
                    errorStatus={validAccountno(acNumber)}
                    errorMessage={validAccountno(acNumber)}
                    id="bit"
                    name="accountNumber"
                    type="number"
                    value={acNumber}
                    onChange={handleInputChange}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
                <div class="form-group mb-4">
                  <label for="bit"> IFSC Code </label>
                  <DefaultInput
                    errorStatus={validIfscCode(ifsc)}
                    errorMessage={validIfscCode(ifsc)}
                    id="bit"
                    name="ifscCode"
                    type="text"
                    className="form-control"
                    value={ifsc}
                    onChange={handleInputChange}

                  />
                  {/* <input type="text" id="bit" className="form-control" name="ifscCode" value={ifscCode} onChange={handleInputChange} /> */}
                </div>
                <div class="form-group mb-4">
                  <label>Branch Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="branchAddress"
                    value={branch}
                    onChange={handleInputChange}
                  />
                </div>
                {/*   <!-- End .form-group --> */}
              </form>
            </div>
            {/* <!-- End modal-body --> */}
            <div class="modal-footer px-8">
              <button
                type="button"
                class="btn btn-gradient btn-small w-100 justify-content-center"
                data-bs-dismiss="modal"
                aria-label="Close"
                disabled={
                  !acNumber ||
                  !ifsc ||
                  !(validAccountno(acNumber) === undefined) ||
                  !(validIfscCode(ifsc) === undefined)
                }
                onClick={() =>
                  handleMessageQuery(
                    account,
                    bankname,
                    holderName,
                    acNumber,
                    ifsc,
                    branch
                  )
                }
              >
                <span>Add Bank</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add bank modal */}
      <div
        class="modal fade"
        id="upi_modal"
        tabindex="-1"
        aria-labelledby="Withdraw_modalLaebl"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Add UPI Details
              </h3>
              <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-fill"></i>
              </button>
            </div>
            {/* <!-- End modal-header --> */}
            <div class="modal-body px-8 py-5">
              <div
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   handleupi(upiId, myFile, code);
              // }}
              >
                <div class="form-group mb-4 ">
                  <label for="bit">UPI ID</label>
                  <input type="text" id="bit" value={upiId} onChange={(e) => setUpiId(e.target.value)} />
                </div>
                <div class="form-group mb-4">
                  <label for="bit">QR Code </label>
                  <input type="file" onChange={handleImage} />
                </div>
                <label for="bit">Email or Phone</label>
                <div className="field-box field-otp-box mb-4">
                  <input type="email" value={email ? email : mobile} />

                </div>
                <label>OTP</label>

                <div class="field-box field-otp-box mb-4">
                  <input type="number" value={code} onChange={(e) => setCode(e.target.value)} onWheel={(e) => e.target.blur()} />
                  <button className="btn btn-sm btn-gradient" type="button" onClick={(e) => {
                    handleGetOtp(email ? email : mobile);
                  }}>
                    Get Otp
                  </button>
                </div>

                <div class="modal-footer px-8">
                  <button type="button" class="btn btn-gradient btn-small w-100 justify-content-center" onClick={() => handleupi(upiId, myFile, code, email ? email : mobile)}
                    data-bs-dismiss="modal" aria-label="Close">
                    <span>Add UPI ID</span>
                  </button>
                </div>
                {/*   <!-- End .form-group --> */}
              </div>
            </div>
            {/* <!-- End modal-body --> */}
          </div>
        </div>
      </div >
    </>
  );
};

export default BankAccount;
