import React, { useEffect, createContext, useState } from 'react';
import LoaderHelper from '../customComponent/Loading/LoaderHelper';
import { alertErrorMessage } from '../customComponent/CustomAlertMessage';
import AuthService from '../api/services/AuthService';


export const ProfileContext = createContext();

export function ProfileProvider(props) {
  const [updateRouting, setUpdateRouting] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [userImage, setUserImage] = useState();
  const [authType, setAuthType] = useState();
  const [kycStatus, setKycStatus] = useState();
  const [updateTheme, setupdateTheme] = useState(true);
  const [currency, setCurrency] = useState();
  const [Theme, setTheme] = useState();
  const [emailProfile, setEmailProfile] = useState('');
  const [mobileProfile, setMobileProfile] = useState('');


  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (token) {
      handleUserDetials();
    }
  }, [updateRouting]);

  // ********* Update Theme ********** //
  useEffect(() => {
    const body = document.getElementById("body");
    let Theme = localStorage.getItem('Theme');
    if (Theme === 'theme-light') {
      body.classList.add('theme-light');
      body.classList.remove("theme-dark");
      setTheme('theme-light');
    } else {
      body.classList.add('theme-dark');
      body.classList.remove("theme-light");
      setTheme('theme-dark');
    }
  }, [updateTheme]);


  // ********* User Profile Details ********** //
  const handleUserDetials = async () => {
    LoaderHelper.loaderStatus(false);
    await AuthService.getDetails().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setEmail(result.data.emailId);
          setMobile(result.data.mobileNumber);
          setFirstName(result.data.firstName);
          setLastName(result.data.lastName);
          setUserImage(result.data.profilepicture);
          setAuthType(result?.data?.["2fa"]);
          setKycStatus(result?.data?.kycVerified);
          setCurrency(result?.data?.currency_prefrence);
          setEmailProfile(result?.data?.emailId);
          setMobileProfile(result?.data?.mobileNumber);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };


  // *********Button Scroll Upside ********** //
  useEffect(() => {
    const btnScrollToTop = document.querySelector("#btnScrollToTop");
    if (btnScrollToTop) {
      btnScrollToTop.addEventListener("click", e => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      });
      window.addEventListener('scroll', e => {
        window.scrollY > 100 ? btnScrollToTop.style.display = "block" : btnScrollToTop.style.display = "none";
      });
    }
  }, []);
  return (
    <>
      {/* <button id='btnScrollToTop' type='button'><i className="ri-arrow-up-line"></i></button> */}
      <ProfileContext.Provider value={{ handleUserDetials, setUpdateRouting, updateRouting, firstName, setFirstName, email, setEmail, lastName, setLastName, setMobile, mobile, userImage, setUserImage, updateTheme, setupdateTheme, authType, kycStatus, currency, setCurrency, Theme, emailProfile, mobileProfile }}>{props.children}</ProfileContext.Provider>
    </>
  );
}


