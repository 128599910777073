import React, { useState, useEffect, useContext } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { Link } from "react-router-dom";
import { $ } from "react-jquery-plugin";
import Moment from "react-moment";
import moment from "moment";
import { usePagination, useSortBy, useTable } from "react-table";
import { Col, Row } from "react-bootstrap";
import { ProfileContext } from "../../../Context"

const FundPage = () => {

  const [fundData, setfundData] = useState([]);
  const [estimatedportfolio, setEstimatedportfolio] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [selectedDeposit, setSelectDeposit] = useState("");
  const [selectedChain, setSelectedchain] = useState("");
  const [tradeHistory, settradeHistory] = useState([]);
  const [minDeposit, setminDeposit] = useState();
  const [updatedLength, setupdatedLength] = useState(5);
  const [walletHisDetail, setwalletHisDetail] = useState();
  const [tradeHisDetails, settradeHisDetails] = useState();
  // const [updatedTradeLength, setupdatedTradeLength] = useState(10);
  // const [updatedtransHistory, settransHistory] = useState(10);
  const [currencyId, setcurrencyId] = useState();
  const [allChain, setallChain] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [transHistory, setTransHistory] = useState([]);
  const [minWithdrawal, setminWithdrawal] = useState();
  const [maxWithdrawal, setmaxWithdrawal] = useState();
  const [Conversion_history, setConversion_history] = useState([]);
  const [otp, setOtp] = useState();
  const { email, mobile } = useContext(ProfileContext);
  const [coinAmount, setCoinAmount] = useState("");
  const [msg, setMsg] = useState(false)
  const [getBalance, setGetBalance] = useState('');
  const [walletStatus, setWalletStatus] = useState();

  useEffect(() => {
    getUserfunds();
    estimatedPortfolio();
    TradeHistory();
    VerifyDeposit();
    WalletHistory();
    ConversionHistory();
    handleUserDetials();
  }, []);

  console.log(getBalance, "getBalancegetBalance");
  console.log(selectedCurrency, 'selectedCurrency');


  // *****walllet status*****************

  const handleUserDetials = async () => {
    LoaderHelper.loaderStatus(false);
    await AuthService.getDetails().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setWalletStatus(result?.data?.wallet_status);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };

  console.log(walletStatus, "walletStatus");

  // ********* Get OTP ********** //
  const handleGetOtp = async (signId, resend) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId, resend).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          setMsg(true)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  // ********* Funds Data ********** //
  const getUserfunds = async (orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserfunds(orderId).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setfundData(result?.data)
        } catch (error) {
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message)
      }
    })
  };

  // ********* Coin Deposit Address ********** //
  const getDepostAddress = async (currId, chain) => {
    setSelectedchain(chain);
    setSelectDeposit("");
    LoaderHelper.loaderStatus(true);
    await AuthService.generateAddress(currId, chain).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setSelectDeposit(result?.data)
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message)
        $('#Deposit_modal').modal('hide');
      }
    })
  };

  // BNB balance requied

  useEffect(() => {
    GetBalance();
  }, []);


  const GetBalance = async () => {
    LoaderHelper.loaderStatus(true)
    await AuthService.getbnbbalance().then((result) => {
      if (result.success) {
        try {
          setGetBalance(result.data)
        }
        catch {
          console.log(result.massage);
        }
      }
    })
  }



  // ********* Estimated Portfolio********** //
  const estimatedPortfolio = async () => {
    await AuthService.estimatedPortfolio().then((result) => {
      if (result.success) {
        try {
          setEstimatedportfolio(result?.data?.toFixed(8))
        } catch (error) {
          console.log(error);
        }
      };
      return
    })
  };

  // ********* Trade History ********** //
  const TradeHistory = async () => {
    await AuthService.tradeHistory().then((result) => {
      if (result.success) {
        try {
          settradeHistory(result?.data?.reverse());
        } catch (error) {
          console.log(error);
        }
      };
      return
    })
  };

  // ********* Wallet History ********** //
  const WalletHistory = async () => {
    await AuthService.walletHistory().then(async (result) => {
      LoaderHelper.loaderStatus(true);
      if (result.success) {
        setTransHistory(result?.data);
        LoaderHelper.loaderStatus(false);
      } else {
        alertErrorMessage(result.message);
        LoaderHelper.loaderStatus(false);
      }
    });
  };

  console.log(transHistory, "ttt");

  // ********* Coin Min Deposit Data ********** //
  const coinDetails = async (currId) => {
    await AuthService.coinDetails(currId).then((result) => {
      if (result.success) {
        try {
          setminDeposit(result?.data?.min_deposit);
          setminWithdrawal(result?.data?.min_withdrawal);
          setmaxWithdrawal(result?.data?.max_withdrawal);
        } catch (error) {
          console.log(error);
        }
      }
    })
  };

  // ********* Withdrawal Currency********** //
  const WithdrawalCurrency = async (currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId) => {
    setOtp('');
    LoaderHelper.loaderStatus(true);
    await AuthService.withdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          getUserfunds()
          alertSuccessMessage(result.message);
        } catch (error) {
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message)
      }
    })
  };
  //******** Auto Call Verify -Deposit after evry 5 minute **********//
  const VerifyDeposit = async () => {
    const ref = window.location.href.split("/");
    ref[ref.length - 1] === 'FundPage' ? setTimeout(VerifyDeposit, 300000) : console.log('Outtttt');;
    await AuthService.verifyDeposit().then(async (result) => {
      if (result.success) {
      } else {
        console.log(result);
      }
    });
  };

  console.log(window, "window");

  //******** Conversion History **********//
  const ConversionHistory = async () => {
    await AuthService.ConversionHistory().then((result) => {
      if (result.success) {
        try {
          setConversion_history(result?.data?.reverse());
        } catch (error) {
          console.log(error);
        }
      };
      return
    })
  };

  // ******* React Table Implementation Trade History *********** //
  const data = React.useMemo(() => tradeHistory, [tradeHistory]);

  const columns = React.useMemo(() => [
    {
      Header: "Sr No.", accessor: "_id", disableSortBy: true, Cell: ({ row }) => {
        const { index } = row; return index + 1
      }
    },
    { Header: "Date/Time", accessor: "updatedAt", disableSortBy: true, Cell: ({ row }) => { return <Moment date={row?.values?.updatedAt} format="DD-MM-YYYY, h:mm:ss" /> } },
    { Header: "Trading Currency", accessor: "currency", disableSortBy: true, },
    { Header: "Side", accessor: "side", disableSortBy: true, },
    { Header: "Order Type", accessor: "order_type", disableSortBy: true, },
    { Header: "Price", accessor: "price", Cell: ({ row }) => { return parseFloat(row?.values?.price) } },
    { Header: "Executed", accessor: "quantity", Cell: ({ row }) => { return parseFloat(row?.values?.quantity?.toFixed(2)) } },
    { Header: "Total", accessor: "Total", disableSortBy: true, Cell: ({ row }) => { return parseFloat((row?.values?.price * row?.values?.quantity).toFixed(2)) } },
    { Header: "Transaction Type ", accessor: "transaction_type", disableSortBy: true, },
    { Header: "Fee", accessor: "fee", Cell: ({ row }) => { return parseFloat(row?.values?.fee?.toFixed(2)) } },
  ], []);

  const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canNextPage, canPreviousPage, pageOptions, gotoPage, state, pageCount, setPageSize, prepareRow } = useTable({ columns, data }, useSortBy, usePagination);
  const { pageIndex, pageSize } = state;


  // ******* React Table Implementation Wallet History *********** //
  const data1 = React.useMemo(() => transHistory, [transHistory]);
  const columns1 = React.useMemo(() => [
    {
      Header: "Sr No.", accessor: "_id", disableSortBy: true, Cell: ({ row }) => {
        const { index } = row; return index + 1
      }
    },
    { Header: "Date/Time", accessor: "updatedAt", disableSortBy: true, Cell: ({ row }) => { return <Moment date={row?.values?.updatedAt} format="DD-MM-YYYY, h:mm:ss" /> } },
    { Header: "Coin", accessor: "short_name", disableSortBy: true, },
    { Header: "Amount", accessor: "amount", Cell: ({ row }) => { return parseFloat(row?.values?.amount).toFixed(8) } },
    { Header: "Transaction Type", accessor: "transaction_type", disableSortBy: true, },
    { Header: "Status", accessor: "status", disableSortBy: true },
  ], [transHistory]);

  const {
    getTableProps: getTableProps1,
    getTableBodyProps: getTableBodyProps1,
    headerGroups: headerGroups1,
    page: page1,
    nextPage: nextPage1,
    previousPage: previousPage1,
    canNextPage: canNextPage1,
    canPreviousPage: canPreviousPage1,
    pageOptions: pageOptions1,
    gotoPage: gotoPage1,
    state: state1,
    pageCount: pageCount1,
    setPageSize: setPageSize1,
    prepareRow: prepareRow1
  } = useTable({ columns: columns1, data: data1 }, useSortBy, usePagination);

  const { pageIndex: pageIndex1, pageSize: pageSize1 } = state1;


  // ******* React Table Implementation Conversion History *********** //
  const data2 = React.useMemo(() => Conversion_history, [Conversion_history]);
  const columns2 = React.useMemo(() => [
    {
      Header: "Sr No.", accessor: "_id", disableSortBy: true, Cell: ({ row }) => { const { index } = row; return index + 1 }
    },
    { Header: "Date/Time", accessor: "updatedAt", disableSortBy: true, Cell: ({ row }) => { return <Moment date={row?.values?.updatedAt} format="DD-MM-YYYY, h:mm:ss" /> } },
    { Header: "Coin", accessor: "coinSymbol", disableSortBy: true, },
    { Header: "Amount", accessor: "amount", Cell: ({ row }) => { return parseFloat(row?.values?.amount).toFixed(8) } },
    { Header: "From", accessor: "from", disableSortBy: true },
    { Header: "To", accessor: "to", disableSortBy: true },
    { Header: "Transaction Type", accessor: "transType", disableSortBy: true, },
    { Header: "Status", accessor: "status", disableSortBy: true },



  ], [Conversion_history]);

  const {
    getTableProps: getTableProps2,
    getTableBodyProps: getTableBodyProps2,
    headerGroups: headerGroups2,
    page: page2,
    nextPage: nextPage2,
    previousPage: previousPage2,
    canNextPage: canNextPage2,
    canPreviousPage: canPreviousPage2,
    pageOptions: pageOptions2,
    gotoPage: gotoPage2,
    state: state2,
    pageCount: pageCount2,
    setPageSize: setPageSize2,
    prepareRow: prepareRow2
  } = useTable({ columns: columns2, data: data2 }, useSortBy, usePagination);

  const { pageIndex: pageIndex2, pageSize: pageSize2 } = state1;

  const handleCopy = () => {

    navigator.clipboard.writeText(selectedDeposit)
      .then(() => {
        // Successfully copied to clipboard
        alertSuccessMessage('Copied!!');
      })
      .catch((error) => {
        // Unable to copy to clipboard
        alertErrorMessage('Failed to Copy:', error);
      })
  }


  // ********* Transfer Amount********** //
  const handleTrasferAmount = async (currencyId, coinAmount, otp) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.addStackingCoin(currencyId, coinAmount, otp).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result.message);
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message)
      }
    })
  };

  return (
    <div onClick={() => setMsg(false)}>
      <section className="page_wrapper">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">Funds</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Wallet</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      {walletStatus === "ACTIVE" ?
        < section className="mt-md-0 mt-4">
          <div className="container">
            <div className="d-flex-between mb-5 custom_dlflex">
              <ul className="nav custom-tabs overflowx_scroll funds_tab">
                <li><a className=" active" data-bs-toggle="tab" href="#funds">Funds</a></li>
                <li><a data-bs-toggle="tab" href="#tt_history"> Wallet History</a> </li>
                <li><a data-bs-toggle="tab" href="#tradehistory">Trade History</a></li>
                <li><a data-bs-toggle="tab" href="#Conversion_history"> Conversion History</a> </li>
              </ul>
              <div className="d-flex-between bidder">
                <span>Estimated Portfolio : </span>
                <div className="d-flex-center ms-1">
                  <span className=" ">{estimatedportfolio}</span>
                </div>
              </div>
            </div>
            <div className="tab-content custom-tab-content p-0">
              <div className="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="funds">
                <div className="table-responsive">
                  <table className="table ">
                    <thead style={{ backgroundColor: "#252f30" }}>
                      <tr>
                        <th>Sr No.</th>
                        <th>ASSETS</th>
                        <th>Available Balance</th>
                        <th>Locked</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fundData.length > 0 ? (

                        fundData?.slice(0, updatedLength).map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.short_name} </td>
                            <td>{(item?.balance.toFixed(8))}</td>
                            <td>{item?.locked_balance <= 0 ? "0.00000000" : (item?.locked_balance.toFixed(8))}</td>
                            <td>{(item?.balance + item?.locked_balance).toFixed(8)}
                            </td>
                            <td>
                              <a href="#/" className=" tb_btn badge badge-success mx-1" data-bs-toggle="modal"
                                data-bs-target="#Deposit_modal" onClick={() => {
                                  coinDetails(item?.currency_id);
                                  setSelectedCurrency(item.short_name); setallChain(item.chain); getDepostAddress(item.currency_id, item.chain[0]); setcurrencyId(item.currency_id); GetBalance();
                                }}>
                                Deposit
                              </a>
                              <a href="#/" className=" tb_btn  badge badge-danger  mx-1" data-bs-toggle="modal" data-bs-target="#Withdraw_modal" onClick={() => { setcurrencyId(item.currency_id); setallChain(item.chain); setSelectedCurrency(item.short_name); setSelectedchain(item.chain[0]); setWalletAddress(''); setWithdrawAmount(''); coinDetails(item?.currency_id) }}>
                                Withdraw
                              </a>
                              {item.short_name === "CITRA" && <a href="#/" className=" tb_btn  badge badge-primary  mx-1" data-bs-toggle="modal" data-bs-target="#transfer_modal" onClick={() => setcurrencyId(item.currency_id)}>Transfer</a>}
                            </td>
                          </tr>
                        ))
                      ) : (

                        <tr rowSpan="5">
                          <td colSpan="12">
                            <p className="text-center" style={{ textAlign: "center" }}>
                              No data Available
                            </p>
                          </td>
                        </tr>
                      )}
                      {(fundData.length > 0 && updatedLength < fundData.length) &&
                        <tr rowSpan="5">
                          <td colSpan="12" className="cursor-pointer loadMore" >
                            <p className="text-center cursor-pointer" style={{ cursor: 'pointer' }} onClick={() => {
                              setupdatedLength(updatedLength + 5); window.scrollTo({
                                top: document.documentElement.scrollHeight,
                                left: 0,
                                behavior: "smooth"
                              })
                            }} >
                              Load More...
                            </p>
                          </td>
                        </tr>}
                    </tbody>
                  </table>

                </div>
              </div>
              <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tt_history">
                <div className="table-responsive">
                  <><table className="table a"   {...getTableProps()}>
                    <thead style={{ backgroundColor: "#252f30" }} >
                      {headerGroups1.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} >
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              {(column.Header === 'Amount') && <i className="ri-arrow-up-down-fill ms-1" style={{ color: column.isSorted ? (column.isSortedDesc ? '#ff00009c' : 'green') : '' }}></i>}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps1()}>
                      {page1.map((row) => {
                        prepareRow1(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()} className="cursor_pointer" data-bs-toggle="modal" data-bs-target="#wallet_history" onClick={() => { setwalletHisDetail(row?.original) }}>{cell.render("Cell")} </td>
                            ))}
                          </tr>
                        );
                      })}
                      {transHistory.length === 0 && <tr rowSpan="5">
                        <td colSpan="12">
                          <p className="text-center" style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>}
                    </tbody>
                  </table></>
                  {transHistory.length !== 0 &&
                    <div className="container pt-3 pb-4  table_control" >
                      <div className=" row align-items-center " >
                        <div className="col-md-4" >
                          <div className="btn-group btn-group-mini justify-content-start" role="group" aria-label="Basic radio toggle button group">
                            <strong className="text-dark" > Page{pageIndex1 + 1} of {pageOptions1.length}</strong>
                            <select className="text-dark" value={pageSize1} onChange={(e) => setPageSize1(+e.target.value)} >
                              {[10, 15, 20]?.map((num) => {
                                return (
                                  <option className="text-dark" key={num} value={num}>Show {num}</option>
                                )
                              })}
                            </select>
                            <button className="btn-check" > Button </button>
                          </div>
                        </div>
                        <div className="col-md-4" ></div>
                        <div className="col-md-4" >
                          <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                            <input type="button" className="btn-check text-dark" name="btnradio" id="btnradio125" autoComplete="off" disabled={!canPreviousPage1} />
                            <label className="btn text-dark " htmlFor="btnradio125" onClick={() => { gotoPage1(0) }} >{'<<'}</label>
                            <input type="button" className="btn-check text-dark" name="btnradio" id="btnradio250" autoComplete="off" disabled={!canPreviousPage1} />
                            <label className="btn text-dark " htmlFor="btnradio250" onClick={() => { previousPage1() }}>Previous</label>
                            <input type="button" className="btn-check text-dark" name="btnradio" id="btnradio375" autoComplete="off" disabled={!canNextPage1} />
                            <label className="btn text-dark " htmlFor="btnradio375" disabled onClick={() => { nextPage1() }}>Next</label>
                            <input type="button" className="btn-check text-dark" name="btnradio" id="btnradio3100" autoComplete="off" disabled={!canNextPage1} />
                            <label className="btn text-dark " htmlFor="btnradio3100" onClick={() => { gotoPage1(pageCount1 - 1) }}>{'>>'}</label>
                          </div>
                        </div>
                      </div>
                    </div>}
                </div>
              </div>

              {/* Transfer Modal */}

              <div className="modal fade" id="transfer_modal" tabIndex="-1" aria-labelledby="transfer_modalLaebl" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header flex-column px-8">
                      <h3 className="modal-title" id="placeBitLaebl">Transfer Funds</h3>
                      <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                    </div>
                    <div className="modal-body px-8 py-5">
                      <div className="form-group mb-4">
                        <input className="form-control" type="number" name="amount_val" placeholder="Amount" data-listener-added_cc10f445="true" value={coinAmount} onChange={(e) => setCoinAmount(e.target.value)} />
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="field-box">
                          <div className="field-otp-box">
                            <input className="form-control" type="text" name="Otp" placeholder="OTP" value={otp} data-listener-added_cc10f445="true" onChange={(e) => setOtp(e.target.value)} />
                            <button type="btn" className="btn btn-sm btn-gradient" onClick={() => handleGetOtp(email ? email : mobile)}>
                              <span> GET OTP </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#transfer_modal" onClick={() => { handleTrasferAmount(currencyId, coinAmount, otp) }}><span>Transfer</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* *****Trade History******** */}
              <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tradehistory">
                <div className="table-responsive">
                  <>
                    <table className="table a"  {...getTableProps()}>
                      <thead style={{ backgroundColor: "#252f30" }} >
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()} >
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render("Header")}
                                {(column.Header === 'Price' || column.Header === 'Fee' || column.Header === 'Executed') && <i className="ri-arrow-up-down-fill ms-1" style={{ color: column.isSorted ? (column.isSortedDesc ? '#ff00009c' : 'green') : '' }}></i>}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()} className="cursor_pointer" data-bs-toggle="modal" data-bs-target="#trade_history" onClick={() => { settradeHisDetails(row?.original) }}>{cell.render("Cell")} </td>
                              ))}
                            </tr>
                          );
                        })}
                        {tradeHistory.length === 0 && <tr rowSpan="5">
                          <td colSpan="12">
                            <p className="text-center" style={{ textAlign: "center" }}>
                              No data Available
                            </p>
                          </td>
                        </tr>}
                      </tbody>

                    </table></>
                  {tradeHistory.length !== 0 &&
                    <div className="container pt-3 pb-4  table_control" >
                      <div className=" row align-items-center " >
                        <div className="col-md-4" >
                          <div className="btn-group btn-group-mini justify-content-start" role="group" aria-label="Basic radio toggle button group">
                            <strong> Page{pageIndex + 1} of {pageOptions.length}</strong>
                            <select value={pageSize} onChange={(e) => setPageSize(+e.target.value)} >
                              {[10, 15, 20]?.map((num) => {
                                return (
                                  <option key={num} value={num}>Show {num}</option>
                                )
                              })}
                            </select>
                            <button className="btn-check" > Button </button>
                          </div>
                        </div>
                        <div className="col-md-4" ></div>
                        <div className="col-md-4" >
                          <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                            <input type="button" className="btn-check" name="btnradio" id="btnradio125" autoComplete="off" disabled={!canPreviousPage} />
                            <label className="btn " htmlFor="btnradio125" onClick={() => { gotoPage(0) }} >{'<<'}</label>

                            <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={!canPreviousPage} />
                            <label className="btn " htmlFor="btnradio250" onClick={() => { previousPage() }}>Previous</label>

                            <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" disabled={!canNextPage} />
                            <label className="btn " htmlFor="btnradio375" disabled onClick={() => { nextPage() }}>Next</label>

                            <input type="button" className="btn-check" name="btnradio" id="btnradio3100" autoComplete="off" disabled={!canNextPage} />
                            <label className="btn " htmlFor="btnradio3100" onClick={() => { gotoPage(pageCount - 1) }}>{'>>'}</label>
                          </div>
                        </div>
                      </div>
                    </div>}
                </div>
              </div>

              {/* *****Conversion History******** */}
              <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="Conversion_history">
                <div className="table-responsive">
                  <>
                    <div className="table-responsive">
                      <><table className="table a"  {...getTableProps()}>
                        <thead style={{ backgroundColor: "#252f30" }} >
                          {headerGroups2.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} >
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                  {column.render("Header")}

                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps2()}>
                          {page2.map((row) => {
                            prepareRow2(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td {...cell.getCellProps()} className="cursor_pointer" data-bs-toggle="modal" data-bs-target="#wallet_history" onClick={() => { setwalletHisDetail(row?.original) }}>{cell.render("Cell")} </td>
                                ))}
                              </tr>
                            );
                          })}
                          {Conversion_history.length === 0 && <tr rowSpan="5">
                            <td colSpan="12">
                              <p className="text-center" style={{ textAlign: "center" }}>
                                No data Available
                              </p>
                            </td>
                          </tr>}
                        </tbody>
                      </table></>
                    </div></>
                </div>
              </div>
            </div>
          </div>
        </section >
        : < section className="mt-md-0 mt-4">
          <div className="container">
            <div className="d-flex-center justify-content-center mb-5 custom_dlflex">
              <ul className="nav custom-tabs overflowx_scroll funds_tab">
                <li><a className=" active">Your wallet is de-activated by admin due to some suspicious activity please contact support
                </a></li>
              </ul>
            </div>
          </div>
        </section>
      }
      <div className="modal fade" id="Deposit_modal" tabIndex="-1" aria-labelledby="Deposit_modalLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Deposit Funds
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <form action="#">
                <>
                  {getBalance >= 0.01 || selectedCurrency === 'BNB' ?
                    <div id="bnm">
                      <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                        {allChain ? allChain?.map((item) => {
                          console.log(item, 'item');
                          return (
                            <button type="button" className={`btn btn-outline-primary ${selectedChain === item && "active"}`} htmlFor={item} onClick={() => { getDepostAddress(currencyId, item) }}>
                              {item}
                            </button>)
                        }) : ''}
                      </div>
                      <img alt="" src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${selectedDeposit}&choe=UTF-8`}
                        className="qr_img img-fluid" />
                      <input className="shareUrl-input copy_url js-shareUrl text-center" type="text" readOnly="readonly" defaultValue={selectedDeposit} onClick={handleCopy} />
                      <small className="text-center d-block mtb-2">
                        Click above to copy the Code.
                      </small>
                      <ul className="disclaimer mt-3">
                        <label>Deposit Tips</label>
                        <li>
                          • Minimum deposit of {minDeposit} {selectedCurrency}, deposit
                          below that cannot be recovered.
                        </li>
                        <li>
                          • Please deposit only {selectedCurrency} on this
                          address. If you deposit any other coin, it will be
                          lost forever.
                        </li>
                        <li>
                          • This is {selectedChain} deposit address type.
                          Transferring to an unsupported network could result in
                          loss of deposit.
                        </li>
                      </ul>
                    </div>
                    : <div className="form-group mb-4">
                      <h4> Please Maintain 0.01 BNB into Your Account</h4>
                    </div>}
                </>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Withdrwal modal first  */}

      <div className="modal fade" id="Withdraw_modal" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Withdraw Funds
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" onClick={() => setMsg(false)}>
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <>
                <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                  {allChain ? allChain?.map((item) => {
                    return (
                      <button type="button" className={`btn btn-outline-primary ${selectedChain === item && "active"}`} htmlFor={item} onClick={() => { setSelectedchain(item) }}>
                        {item}
                      </button>)
                  }) : ''}
                </div>
                <div className="field-box field-otp-box  mb-4">
                  <input className="" type="number" name="wallet_Add" value={otp} placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} />
                  <button type="btn" className="btn btn-sm btn-gradient" onClick={() => handleGetOtp(email ? email : mobile, true)}>
                    <span>GET OTP</span>
                  </button>
                  {msg && <small className="d-block text-center">OTP sent to {email ? email : mobile}</small>}

                </div>
                <div className="form-group mb-4">
                  <input className="" type="text" name="wallet_Add" value={walletAddress} placeholder="Wallet Address" onChange={(e) => setWalletAddress(e.target.value)} />
                </div>

                <div className="form-group mb-4">
                  <input className="" type="number" name="amount_val" value={withdrawAmount} placeholder="Amount" onChange={(e) => setWithdrawAmount(e.target.value)} />
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <small>Mininum Withdrawal: {minWithdrawal}</small>
                  <small>Maximum Withdrawal: {maxWithdrawal}</small>
                </div>
                <div className="form-group mb-4 d-flex justify-content-center align-items-center m-auto">
                  <button type="button" className="btn btn-success active" disabled={!withdrawAmount || !walletAddress || !otp} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2">
                    Withdraw
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>



      {/* Withdrawal Modal */}
      <div className="modal fade" id="Withdraw_modal2" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Confirm
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <>
                <div className="form-group mb-4">
                  <h4>
                    You are going to withdraw {selectedCurrency}{" "}
                    {withdrawAmount}  ,
                    please confirm your withdraw by clicking 'Confirm' button
                    below.
                  </h4>
                </div>
                <Row>
                  <Col>
                    <div className="form-group mb-4">
                      <button type="button" className="btn btn-danger btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </Col>

                  <Col>
                    <div className="form-group mb-4">
                      <button type="button" className="btn btn-success btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" onClick={() => { WithdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, email ? email : mobile) }}>
                        <span>Confirm</span>
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* TradeHistory modal */}
      <div className="modal fade" id="trade_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Trade Details
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5 body_history">
              <div className="tt_data">
                <div className="tt_item ">
                  <span className="tt_disable">Date &amp; Time</span>
                  <span className="tt_normal">
                    <b><Moment date={tradeHisDetails?.updatedAt} format='MMMM Do YYYY, h:mm:ss a' /> </b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Currency</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.currency}</b>
                  </span>
                </div>
              </div>
              <div className="tt_item ">
                <span className="tt_disable">Side</span>
                <span className="tt_normal">
                  <b>{tradeHisDetails?.side}</b>
                </span>
              </div>

              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Transaction Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.transaction_type}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">
                    Fee
                  </span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.fee}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Fee Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.fee_type}</b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Amount</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.amount}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Order Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.order_type}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Quantity</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.quantity}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Order Id</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.order_id}</b>
                  </span>
                </div>


                <div className="tt_item ">
                  <span className="tt_disable">Remarks</span>
                  <span className="tt_normal">
                    <b>Flexibits Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Wallet History modal */}
      <div className="modal fade" id="wallet_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Transaction Details
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5 body_history">
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Date/Time</span>
                  <span className="tt_normal">
                    <b><Moment date={walletHisDetail?.updatedAt} format='MMMM Do YYYY, h:mm:ss A' /></b>
                  </span>
                </div>
              </div>
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Coin</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.short_name}</b>
                  </span>
                </div>
              </div>
              <div className="tt_item ">
                <span className="tt_disable">Status</span>
                <span className="tt_normal">
                  <b>{walletHisDetail?.status}</b>
                </span>
              </div>

              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Transaction Type</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.transaction_type}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">
                    Fee<small> (Incl.of all applicable taxes)</small>
                  </span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.fee}</b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Amount</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.amount}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">From Address</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.from_address}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">To Address</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.to_address}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Transaction Hash</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.transaction_hash}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Date &amp; Time</span>
                  <span className="tt_normal">
                    <b>{moment(walletHisDetail?.updatedAt).format("MMMM Do YYYY, h:mm:ss a")} </b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Remarks</span>
                  <span className="tt_normal">
                    <b>Flexibits Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default FundPage;
