import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const AuthHeader = () => {
  const token = localStorage.getItem("token");
  const { pathname } = useLocation();


  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  const removeHandler = () => {
    const tab = document.getElementById("body");
    tab.classList.remove("trade_body");
  };

  const addHandler = () => {
    const tab = document.getElementById("body");
    tab.classList.add("trade_body");
  };

  function showMenu() {
    let tab = document.getElementById("qwert");
    console.log(tab);
    tab.classList.add("active");
  }

  function hideMenu() {
    let tab = document.getElementById("qwert");
    console.log(tab);
    tab.classList.remove("active");
  }
  return (
    <>
      <header className="ib-header header-default header-fixed header--sticky ">
        <div className="container">
          <div className="header-inner d-flex align-items-center justify-content-between">
            <div className="header-left d-flex align-items-center">
              <div className="logo-wrapper">
                <Link to="/" className="logo logo-light">
                  <img src="images/logo-white.png" alt="brand" />{" "}
                </Link>
              </div>
              {/* <!-- End .logo-wrapper --> */}
            </div>
            {/* <!-- End .header-left --> */}

            <div className="header-right d-flex align-items-center">
              <div className="mainmenu-wrapper">
                <nav id="sideNav" className="mainmenu-nav d-none d-xl-block">
                  {/* <!-- Start Mainmanu Nav --> */}
                  <ul className="mainmenu">
                    <li className="">
                      <Link className="" to="/" onClick={() => removeHandler()}>
                        Home
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/market" onClick={() => removeHandler()}>
                        Market
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/trade" onClick={() => addHandler()}>
                        Trade
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/quickBuy" onClick={() => addHandler()}>
                        Quick Buy/Sell
                      </Link>
                    </li>
                  </ul>
                  {/* <!-- End Mainmanu Nav --> */}
                </nav>
              </div>
              <ul className="header-right-inner">
                <li className="wallet-button">
                  <Link to="/login" className="btn btn-link btn-small" onClick={() => removeHandler()}>
                    <span>Login</span>
                  </Link>
                  <div className="btn-group">
                    <Link to="/signup" className="btn btn-gradient btn-small" onClick={() => removeHandler()}>
                      <span> Register </span>
                    </Link>
                  </div>
                </li>
                {/* <!-- End .wallet-button --> */}

                <li className="setting-option mobile-menu-bar d-block d-xl-none">
                  <button className="hamberger-button" onClick={() => showMenu()}>
                    <i className="ri-menu-2-fill"></i>
                  </button>
                </li>
              </ul>
            </div>
            {/* <!-- End .header-left --> */}
          </div>
        </div>
      </header>
      {/* <!-- End header area --> */}

      {/* <!-- Start mobile menu area --> */}
      <div className="popup-mobile-menu" id="qwert">
        <div className="inner">
          <div className="header-top">
            <div className="logo logo-custom-css">
              <Link className="logo-light" to="/">
                <img src="images/logo-white.png" alt="nft-logo" />
              </Link>
            </div>
            <div className="close-menu">
              <button className="close-button" onClick={() => hideMenu()}>
                <i className="ri-close-fill"></i>
              </button>
            </div>
          </div>
          <nav>
            {/* <!-- Start Mainmanu Nav --> */}
            <ul className="mainmenu">
              <li className="">
                <Link className="" to="/" onClick={() => hideMenu()}>
                  Home
                </Link>
              </li>
              <li className="">
                <Link className="" to="/market" onClick={() => hideMenu()}>
                  Market
                </Link>
              </li>
              <li className="">
                <Link className="" to="/trade" onClick={() => hideMenu()}>
                  Trade
                </Link>
              </li>
              <li className="">
                <Link className="" to="/flexiWallet" onClick={() => hideMenu()}>
                  Quick Buy
                </Link>
              </li>
            </ul>
            {/* <!-- End Mainmanu Nav --> */}
          </nav>
        </div>
      </div>
    </>
  );
};

export default AuthHeader;
