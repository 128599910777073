import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import { io } from "socket.io-client";
import { Link } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import ReactPaginate from 'react-paginate';

const MarketPage = () => {

  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [AllData, setAllData] = useState([]);
  const [coinData, setCoinData] = useState([]);
  const [search, setsearch] = useState("");
  const [socket, setSocket] = useState(null);
  const [favCoins, setfavCoins] = useState([]);
  const [FavCoinList, setFavCoinList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);


  useEffect(() => {
    const newSocket = io(`${ApiConfig?.webSocketUrl}`, {
      transports: ['websocket'],
      upgrade: false,
      rejectUnauthorized: false,
      reconnectionAttempts: 3,
      debug: true
    });

    setSocket(newSocket);
    let payload = {
      'message': 'market',
    }
    newSocket.emit('message', payload);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('message', (data) => {
        // console.log(data, "market data");
        setAllData(data.pairs);
        // sethotPairs(data.hot.slice(0, 3));
        // setnewListedPair(data.new_listed.slice(0, 3));
      });
    }
  }, [socket]);

  const nextPage = (data) => {
    sessionStorage.setItem('RecentPair', JSON.stringify(data))
    navigate('/trade', { state: data });
  };

  const HandleAddFav = async (pairId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.favoriteCoin(pairId).then((result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          FavoriteList()
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message)
      }
    })
  };

  const FavoriteList = async () => {
    await AuthService.favoriteList().then((result) => {
      if (result.success) {
        try {
          let filterdList = AllData?.filter((item) => {
            return result?.data?.pairs ? result?.data?.pairs?.includes(item?.base_currency_id) : []
          })
          console.log(filterdList, 'filterdList');
          setfavCoins(result?.data?.pairs ? result?.data?.pairs : '');
          setFavCoinList(filterdList);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  // console.log(coinData, "coinData?coinData?coinData");
  // console.log(AllData, "AllDataAllDataAllDataAllData");

  // ******* Search Filter ***********//
  useEffect(() => {
    if (search) {

      let filteredData = AllData?.filter((item) => {
        return item?.base_currency.toLowerCase().includes(search.toLowerCase()) || item?.quote_currency.toLowerCase().includes(search.toLowerCase())
      })
      setCoinData(filteredData);

    } else {
      setCoinData(AllData);

    }
  }, [search, AllData]);



  const [activeTab, setActiveTab] = useState("ALL");

  const handleTab = (tab) => {
    setActiveTab(tab);
    if (tab === 'ALL') {
      setCoinData(AllData);
    } else if (tab === "BTC") {
      let data = AllData.filter(item => item?.quote_currency === "BTC");
      setCoinData(data);
    } else if (tab === "USDT") {
      let data = AllData.filter(item => item?.quote_currency === "USDT");
      setCoinData(data);
    }

  };


  //******************* Custom Pagination *********************//
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const pageCount = Math.ceil(coinData ? coinData.length / itemsPerPage : []);
  const currentItems = coinData ? coinData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  ) : "";

  //******************* Custom Pagination End *********************//

  return (
    <>
      <section className="page_wrapper">
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1" />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2 " />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3" />

        <div className="container">
          <div className="inner text-center">
            <h1 className="title">Market</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Market
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className=" live_prices mt-0 market_prices pb-50 ">
        <div className="container">
          <Swiper
            className="market_slider  pb-10"
            spaceBetween={10}
            autoplay={{
              delay: 2000,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            // slidesPerView={5}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            {AllData?.length > 0 ? AllData?.map((item) => (
              <SwiperSlide key={item?._id}>
                <div className="top-seller-style-two " >
                  <div className="d-flex-between">
                    <div className="d-flex-center">
                      <div className="thumb-wrapper">
                        <a href="#" className="thumb no-border" onClick={() => nextPage(item)}>
                          <img src={ApiConfig.baseUrl + item?.icon_path} alt="top sellter" />
                        </a>
                      </div>
                      <div className="content">
                        <h4 className="title pb-0 mb-0">
                          <a href="#" onClick={() => nextPage(item)}>{item?.base_currency}/{item?.quote_currency}</a>
                        </h4>
                      </div>
                      {/* <!-- End .content --> */}
                    </div>
                    {/* <!-- End .d-flex-center --> */}
                    <i
                      onClick={() => nextPage(item)}
                      className="ri-arrow-right-line btn_icon"
                    ></i>
                  </div>
                  <hr />
                  <div className="d-flex-between">
                    <div>
                      <span className="price">{item?.price}</span>
                      <br />
                      <span
                        className={`price_small ${item?.Change >= 0 ? "text-success" : "text-danger"}`}>
                        {item?.change?.toFixed(2)}%
                      </span>
                      <div>
                        <small>{item?.volume?.toFixed(6)}</small>
                      </div>
                    </div>
                    <img src="images/graph/graph.png" alt="top sellter" className="" />
                  </div>
                </div>
              </SwiperSlide>
            )) : null
            }
          </Swiper>
        </div>
        <div className="container">
          <div className="d-flex-between mb-5 custom_dlflex">
            <ul className="nav custom-tabs">
              <li>
                <a data-bs-toggle="tab" href="#tt_history">Favorite</a>
              </li>
              <li>
                <a className="active" data-bs-toggle="tab" href="#funds">
                  Spot
                </a>
              </li>
            </ul>
            <div className="searchBar custom-tabs" ><i className="ri-search-2-line"></i>
              <input type="search" className="custom_search" placeholder="Search Crypto" value={search} onChange={(e) => { setsearch(e.target.value) }} />
            </div>
          </div>
          <div className="tab-content custom-tab-content p-0">
            <div className="tab-pane container active show fade" id="funds">
              <div
                className="btn-group btn_radio_group " role="group" aria-label="Basic radio toggle button group">
                <button onClick={() => handleTab('ALL')} type="button" className={`btn  btn-outline-primary ${activeTab === 'ALL' && 'active'}`} htmlFor="ALL">ALL</button>
                <button onClick={() => handleTab("INR")} type="button" className={`btn  btn-outline-primary ${activeTab === "INR" && "active"}`} htmlFor="INR">INR</button>
                <button onClick={() => handleTab("USDT")} type="button" className={`btn  btn-outline-primary ${activeTab === "USDT" && "active"}`} htmlFor="USDT">USDT</button>
              </div>
              <div className="table-responsive">
                <table className="table table_sm">
                  <thead>
                    <tr>
                      <th> Pair</th>
                      <th> Last Price</th>
                      <th> 24H Change</th>
                      <th> 24H High</th>
                      <th> 24H Low</th>
                      <th> 24H Vol</th>
                      <th> 24H Turnover</th>
                      <th> Operation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems?.length > 0 ? currentItems?.map((item) => (
                      ((activeTab === item?.quote_currency || activeTab === 'ALL')) &&
                      <tr key={item?._id}>
                        <td>
                          <div className="td_div">
                            {token && <span className={"star_btn btn_icon active"}><i className={favCoins?.includes(item?.base_currency_id) ? "ri ri-star-fill text-warning me-2 " : "ri ri-star-line me-2 "} onClick={() => { HandleAddFav(item?.base_currency_id) }} > </i></span>}
                            <img alt="" src={ApiConfig.baseUrl + item?.icon_path} className="img-fluid icon_img coinimg me-2 " />
                            {item?.base_currency}/{item?.quote_currency}
                          </div>
                        </td>
                        <td><b>{item?.buy_price?.toFixed(8)}</b></td>
                        <td className={item?.change >= 0 ? "color-green text-success" : "color-red text-danger"}><b>{item?.change?.toFixed(8)}</b>
                        </td>
                        <td><b>{item?.high?.toFixed(8)}</b></td>
                        <td><b>{item?.low?.toFixed(8)}</b></td>
                        <td><b>{(item?.volume?.toFixed(2))}</b></td>
                        <td><b>{(item?.buy_price * item?.volume?.toFixed(2))}</b></td>

                        <td><a href="#/" onClick={() => nextPage(item)} className="btn btn-gradient btn-sm"><span>Trade</span></a></td>
                      </tr>
                    )) : <tr rowSpan="5">
                      <td colSpan="12">
                        <p style={{ textAlign: "center" }}>
                          No data Available
                        </p>
                      </td>
                    </tr>}
                  </tbody>
                </table>
                <ReactPaginate
                  pageCount={pageCount}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName={'customPagination text-dark'}
                  activeClassName={'active'} />
              </div>
            </div>
            {/* <!-- End bid ifno --> */}

            {/* Favourite Table Start */}
            <div className="tab-pane container fade" id="tt_history">
              <div className="table-responsive" >
                {token ? <table className="table ">
                  <thead>
                    <tr>
                      <th> Pair</th>
                      <th> Price</th>
                      <th> Change</th>
                      <th> 24H High</th>
                      <th> 24H Low</th>
                      <th> 24H Vol</th>
                      <th> Operation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {FavCoinList.length > 0 ? FavCoinList.map((item) => (
                      <tr key={item?._id}>
                        <td>
                          <div className="td_div">
                            <span className={"star_btn btn_icon active"} >
                              <i className="ri-star-fill" onClick={() => { HandleAddFav(item?.base_currency_id) }}></i></span>
                            <img alt="" src={ApiConfig.baseUrl + item?.icon_path}
                              className="img-fluid icon_img coinimg me-2 " />
                            {item?.base_currency}/{item?.quote_currency}
                          </div>
                        </td>
                        <td><b>{item?.buy_price?.toFixed(8)}</b></td>
                        <td className={item?.change >= 0 ? "color-green text-success" : "color-red text-danger"}>
                          <b>{item?.change?.toFixed(8)}</b></td>
                        <td><b>{item?.high?.toFixed(8)}</b></td>
                        <td><b>{item?.low?.toFixed(8)}</b></td>
                        <td><b>{(item?.volume?.toFixed(8))}</b></td>
                        <td><a href="/trade" onClick={() => nextPage(item)} className="btn btn-gradient btn-sm"><span>Trade</span></a></td>
                      </tr>
                    )) : <tr rowSpan="5">
                      <td colSpan="12">
                        <p style={{ textAlign: "center" }}>
                          No data Available
                        </p>
                      </td>
                    </tr>}
                  </tbody>
                </table> : <div className="favouriteData">
                  <img src="images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                  <p className="mt-3 mb-4" > No results.... Go to <Link className="btn-link" to="/login" >Sign in</Link> and add your favorite coins from Spot. </p>
                </div>}
              </div>
            </div>
            {/* Favourite Table End */}
          </div>
        </div>
      </section>
    </>
  );
};

export default MarketPage;
