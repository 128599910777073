const appUrl = "https://backend.flexibits.io";

export const ApiConfig = {
  // =========EndPoints==========

  login: "login",
  getDetails: "profile",
  getHistoricalData: 'historical-data',
  addkyc: "submit-kyc",
  getcode: "verify-otp",
  register: "register",
  getOtp: "send-otp",
  forgotpassword: "forgot_password",
  updateSettings: "edit-profile",
  update2fa: "enable-2fa",
  googleAuth: "generate-google-qr",
  userfunds: "user-wallet",
  generateAddress: "generate-address",
  estimatedPortfolio: "estimated-portfolio",
  tradeHistory: 'trade-history',
  walletHistory: "wallet-history",
  coinDetails: 'coin-details',
  withdrawalCurrency: "withdrawal-currency",
  verifyDeposit: 'verify-deposit',
  pastOrder: 'past-order',
  cancelOrder: 'cancel-order',
  favoriteCoin: 'favorite-coin',
  favoriteList: 'favorite-list',
  addFavourite: "exch/addfavcoin",
  converttoken: "convert-token",
  buy: "swap-token",
  balanceByName: "get_coin_balance",
  ConversionHistory: 'history',
  addBankDetails: "add-bank-details",
  userBankDetails: "get-bank-details",
  addUPI: "add-upi",
  setCurrency: "currency-preference",
  setSecurity: "change-account-password",
  stackinglist: "stacking-list",
  stackcoin: "stack-coin",
  notify: "notification-list",
  activityLogs: "activity-logs",
  getbnbbalance: 'getbnb',
  support: "support",
  tradeReportDate: "download_report",
  getPairs: "get-pairs",
















  verifyId: "auth/verifyHex",
  resetPassword: "auth/changeFpass",
  // support: "auth/setsupport",
  emailVerify: "auth/verifyHex",
  // openOrders: "exch/openOrders",
  // completedOrders: "exch/completedOrders",
  // getAllCompleteorders: "exch/getallCompleteorders",
  // allPendingSell: "exch/allPendingSell",
  // allPendingBuy: "exch/allPendingBuy",
  // getBalbycoin: "wallet/getbalbycoin",
  // addOrder: "exch/addorder",
  // cancelorder: "exch/cancelorder",
  // changePassword: 'auth/changepass',
  // help: 'createhelp',
  // loginList: 'logs',


  depositInr: "depositInr",
  // withdrawInr: "withdrawinr",
  // helpList: "getHelplist",
  estimatedportfolio: "getTotalBal",
  // update2fa: "update2fa",
  otpVerify: "auth/checkotp",

  getChain: "getchain",
  getFee: "getfee",
  // getDetails: "getdetails",
  // identity: "submitkyc",
  // googleAuth: "getgauthqr",
  // getOtp: "auth/getcode",



  // thistory: "transactions",
  // addFavourite: "exch/addfavcoin",
  // getFavouriteList: "exch/getallfavcoin",
  inrwithdrawreq: "inrwithdrawreq",
  withdrawcoin: "withdrawcp",
  withdrawInr: "withdrawInr",
  checkifsc: "checkifsc",
  // Contactform: "setsupport",

  referalcode: "user_refer_code",
  totalReferalCount: "total_refer_count",
  referralAmount: "totalrewards",
  selectedChains: "generateadr",

  sendOther: "withdrawalcoin",
  getreceive: "getreceive",
  getUserStacking: "getUserStacking",

  // getZipCode: "pincodeDetails",


  // chistory: "conversionHistory",
  getLevels: "getdirectreferusers",
  // getNotifications: "acc/getallnotification",
  transAmount: "stackcitra",
  levelIncome: "getlevelincome",
  memberData: "getalllevelmembers",
  fetchBNB: "fetchbnb",
  userLevels: "getlevel",
  placeOrder: 'place-order',
  getCoin: 'get-coin',


  // coinDetails: 'coin-details',




  // ============URLs================
  baseUrl: `${appUrl}/`,
  appUrl: `${appUrl}`,
  baseKyc: `${appUrl}/kyc/`,
  baseAuth: `${appUrl}/v1/user/`,
  baseTrans: `${appUrl}/v1/transaction/`,
  baseHelp: `${appUrl}/help/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseOrder: `${appUrl}/binance/`,
  baseStacking: `${appUrl}/v1/stacking/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseSwap: `${appUrl}/v1/swap/`,
  baseNotify: `${appUrl}/v1/admin/`,


  webSocketUrl: "wss://backend.flexibits.io",
  // webSocketUrl: "ws://139.59.60.135:3001/",

};
