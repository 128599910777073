import React, { useState, useEffect, useMemo, useContext } from "react";
import countryList from "react-select-country-list";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../../customComponent/CustomAlertMessage";
import "./style.css";
import { validateNumber, validatePanCard, matchPanCard, drivingLicense, documentNum, OtherCountryvalidateNumber, postCode, notEqualsZero, aadharNum, email, } from "../../../utils/Validation";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { CountryList } from "../../../customComponent/CountryList";
import DefaultInput from "../../../customComponent/DefaultInput";
import { ProfileContext } from "../../../Context";
import AuthService from "../../../api/services/AuthService";

const KycPage = () => {

  const options = useMemo(() => countryList().getData(), []);
  const { kycStatus } = useContext(ProfileContext)
  const [kycVerfied, setKycVerified] = useState(kycStatus);
  const [infoCountry, setInfoCountry] = useState("India");
  const [kycType, setKycType] = useState("Personal");
  const [number, setNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [infoDob, setInfoDob] = useState("");
  const [docType, setDocType] = useState("");
  const [address, setAddress] = useState("");
  const [infoState, setInfoState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [localFront, setLocalFront] = useState("");
  const [localBack, setLocalBack] = useState("");
  const [localSelfie, setLocalSelfie] = useState("");
  const [panCard, setPanCard] = useState("");
  const [rePanCard, setRePanCard] = useState("");
  const [localPanCard, setLocalPanCard] = useState("");
  const [kyc2fa, setKyc2fa] = useState("");
  const [emailId, setEmailId] = useState("");
  const [gender, setGender] = useState("male");
  const [reason, setReason] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [selfieImage, setSelfieImage] = useState("");
  const [pancardImage, setPanCardImage] = useState("");
  const [isShowOther, setIsShowOther] = useState();
  const [disableBtn, setDisbaleBtn] = useState(false);
  const [disableBtn2, setDisbaleBtn2] = useState(false);
  const [mobileOtp, setmobileOtp] = useState();
  const [emailOtp, setemailOtp] = useState();
  const [isShow, setIsShow] = useState();
  const [emailError, setemailError] = useState(false);
  const [middleName, setMiddleName] = useState(" ");


  const CheckValidImage = (event) => {
    if (event.target.files[0].size < 5000000 && (event.target.files[0].type === "image/png" || event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/jpg")) {
      return true
    } else {
      alertWarningMessage('Only JPEG, PNG & JPG formats and file size upto 5MB are supported')
    }
  }
  const handleChangeIdentity = async (event) => {
    event.preventDefault();
    let ImageValidation = CheckValidImage(event);
    if (ImageValidation) {
      setLocalFront(event.target.files[0]);
      const imgata = URL.createObjectURL(event.target.files[0]);
      setFrontImage(imgata);
    }


    // const imageFile = event.target.files[0];
    // 
    // if (fileUploaded.size > 5000000) {
    //   const options = {
    //     maxSizeMB: 5,
    //   }
    //   try {
    //     const compressedFile = await imageCompression( fileUploaded, options);
    //     console.log(compressedFile)
    //     setLocalFront(compressedFile);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    // else {
    //   setLocalFront(event.target.files[0]);
    // }
  };

  const handleChangeIdentity2 = async (event) => {
    event.preventDefault();
    let ImageValidation = CheckValidImage(event);
    if (ImageValidation) {
      setLocalBack(event.target.files[0]);
      const imgata = URL.createObjectURL(event.target.files[0]);
      setBackImage(imgata);
    }
  };

  const handleChangeSelfie = async (event) => {
    event.preventDefault();
    let ImageValidation = CheckValidImage(event);
    if (ImageValidation) {
      setLocalSelfie(event.target.files[0]);
      const imgata = URL.createObjectURL(event.target.files[0]);
      setSelfieImage(imgata);
    }
  };

  const handleChangePanCard = async (event) => {
    event.preventDefault();
    let ImageValidation = CheckValidImage(event);
    if (ImageValidation) {
      setLocalPanCard(event.target.files[0]);
      const imgata = URL.createObjectURL(event.target.files[0]);
      setPanCardImage(imgata);
    }
  };

  const handleKyc = async (infoCountry, kycType, firstName, lastName, gender, number, infoDob, address, infoState, city,
    zipCode, aadhar, panCard, localFront, localBack, localSelfie, localPanCard, docType, rePanCard, middleName, mobileOtp, emailOtp, emailId) => {
    var formData = new FormData();
    formData.append("document_front_image", localFront);
    formData.append("document_back_image", localBack);
    formData.append("user_selfie", localSelfie);
    formData.append("pancard_image", localPanCard);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", infoState);
    formData.append("country", infoCountry);
    formData.append("document_number", aadhar);
    formData.append("pancard_number", panCard);
    formData.append("confirm_pancard_number", rePanCard);
    formData.append("dob", infoDob);
    formData.append("zip_code", zipCode);
    formData.append("first_name", firstName);
    formData.append("middle_name", middleName);
    formData.append("last_name", lastName);
    formData.append("kyc_type", kycType);
    formData.append("gender", gender);
    formData.append("mobileNumber", number);
    formData.append("document_type", docType);
    formData.append("motp", mobileOtp);
    formData.append("eotp", emailOtp);
    formData.append("emailId", emailId);

    LoaderHelper.loaderStatus(true);
    await AuthService.addkyc(formData).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result.message);
          handleResetInput();
          handleDetials();
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleResetInput = () => {
    setInfoCountry("");
    setKycType("");
    setMiddleName("");
    setFirstName("");
    setLastName("");
    setInfoDob("");
    setAddress("");
    setInfoState("");
    setCity("");
    setZipCode("");
    setAadhar("");
    setLocalFront("");
    setLocalBack("");
    setLocalSelfie("");
    setPanCard("");
    setRePanCard("");
    setLocalPanCard("");
  };

  useEffect(() => {
    // handleDetials();
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleDetials = async () => {
    await AuthService.getDetails().then(async (result) => {
      if (result.message) {
        LoaderHelper.loaderStatus(false);
        setKycVerified(result.data?.kycVerified);
        setKyc2fa(result.data?.["2fa"]);
        setEmailId(result.data?.emailId);
        setFirstName(result.data?.firstName);
        setLastName(result.data?.lastName);
        setReason(result?.data?.kyc_reject_reason);
        setNumber(result?.data?.mobileNumber);
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleSelected = (type) => {
    setIsShow(type);
  };

  const handleSelectedOther = (type) => {
    setIsShowOther(type);
  };

  const getEighteenYearsAgoDate = () => {
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18
    );
    eighteenYearsAgo = moment(eighteenYearsAgo).format("YYYY-MM-DD");
    return eighteenYearsAgo;
  };

  const verifyAgain = () => {
    setKycVerified("4");
  };


  const handleGetOtp = async (signId, resend) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId, resend).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          setDisbaleBtn2(true)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };
  useEffect(() => {
    if (email(emailId) === undefined) {
      setemailError(false)
    }
  }, [emailId]);

  return (
    <>
      <section className="page_wrapper">
        <img src="images/shape/1-light.png" alt="shape" className="shape shape-1  " />
        <img src="images/shape/2-light.png" alt="shape" className="shape shape-2  " />
        <img src="images/shape/3-light.png" alt="shape" className="shape shape-3" />

        <div className="container">
          <div className="inner text-center">
            <h1 className="title">KYC Verification</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/profile">My Account</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  KYC Verification
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="pb-90">
        {kycVerfied == "1" ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="create-item-wrapper create-item-wrapper-kyc">
                  <div className="form-field-wrapper kyc_wrapper ">
                    <div className="rightsidebox">
                      <div className="kyc_nofti kyc_done">
                        <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                          <div className={`check_bar ${!emailId ? "acive" : "acive"}`}                          >
                            <i className="ri-check-fill"></i>
                            <h5> Email</h5>
                          </div>
                          <div
                            className={`check_bar ${kyc2fa == "0" ? "" : "acive"}`}>
                            <i className="ri-check-fill"></i>
                            <h5> Security </h5>
                          </div>
                          <div className="check_bar ">
                            <i className="ri-check-fill"></i>
                            <h5> Welcome </h5>
                          </div>
                        </div>
                        <hr />
                        <div className="d-kyc_sec">
                          <div>
                            <h4 className="mb-1" >
                              Your Flexibits account Kyc is Pending
                            </h4>
                            <p> Your Flexibits account KYC is pending because of admin approval </p>
                          </div>
                          <a href="/trade" className="btn btn-gradient btn-medium justify-content-center disabled">
                            <span>Start Trading</span>
                          </a>
                        </div>
                        <hr />
                        <div className="d-kyc_share">
                          <div>
                            <h4 className="mb-1" >
                              <strong>Earn 50% commssion on your friend's trading fees</strong>
                            </h4>
                            <p>
                              <small>
                                when your friend sign up using your referal link
                                & trades, you can earn 50% commission on their
                                trading fees.
                              </small>
                            </p>
                          </div>
                          {/* <a href="/referral" className="btn btn-dark btn-mini">
                            <span>Invite & Earn</span>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : kycVerfied == "2" ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="create-item-wrapper create-item-wrapper-kyc">
                  <div className="form-field-wrapper kyc_wrapper ">
                    <div className="rightsidebox">
                      <div className="kyc_nofti kyc_done">
                        <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                          <div className={`check_bar ${!emailId ? "" : "acive"}`}>
                            <i className="ri-check-fill "></i>
                            <h5> Email</h5>
                          </div>
                          <div className={`check_bar ${!kyc2fa ? "" : "acive"}`}>
                            <i className="ri-check-fill "></i>
                            <h5> Security </h5>
                          </div>
                          <div className="check_bar acive">
                            <i className="ri-check-fill "></i>
                            <h5> Welcome </h5>
                          </div>
                        </div>
                        <hr />
                        <div className="d-kyc_sec">
                          <div>
                            <h4 className="text-success pb-2">
                              Congratulations
                            </h4>
                            <p>Your Flexibits account Kyc is Approved</p>
                          </div>
                          <Link to="/trade" className="btn btn-gradient btn-medium justify-content-center">
                            <span>Start Trading</span>
                          </Link>
                        </div>
                        <hr />
                        <div className="d-kyc_share">
                          <div>
                            <h5>
                              Earn 50% commssion on your friend's trading fees
                            </h5>
                            <p>
                              <small>
                                when your friend sign up using your referal link
                                & trades, you can earn 50% commission on their
                                trading fees.
                              </small>
                            </p>
                          </div>
                          {/* <a href="/referral" className="btn btn-dark btn-mini">
                            <span>Invite & Earn</span>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : kycVerfied == "3" ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="create-item-wrapper create-item-wrapper-kyc">
                  <div className="form-field-wrapper kyc_wrapper ">
                    <div className="rightsidebox">
                      <div className="kyc_nofti kyc_done">
                        <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                          <div className={`check_bar ${!emailId ? "" : "acive"}`}>
                            <i className="ri-check-fill"></i>
                            <h5> Email</h5>
                          </div>
                          <div className={`check_bar ${!kyc2fa ? "" : "acive"}`}>
                            <i className="ri-check-fill"></i>
                            <h5> Security </h5>
                          </div>
                          <div className="check_bar ">
                            <i className="ri-check-fill"></i>
                            <h5> Welcome </h5>
                          </div>
                        </div>
                        <hr />
                        <div className="d-kyc_sec">
                          <div>
                            {/* <h4 className="p-b 10">Sorry</h4> */}
                            <h5>Your Flexibits account Kyc is Rejected</h5>
                            <span className="text-danger mt-3">{reason}</span>
                          </div>
                          <button className="btn btn-gradient btn-medium justify-content-center" onClick={verifyAgain}>
                            <span>Verify Again</span>
                          </button>
                        </div>
                        <hr />
                        <div className="d-kyc_share">
                          <div>
                            <h5>
                              Earn 50% commssion on your friend's trading fees
                            </h5>
                            <p>
                              <small>
                                when your friend sign up using your referal link
                                & trades, you can earn 50% commission on their
                                trading fees.
                              </small>
                            </p>
                          </div>
                          {/* <a href="/referral" className="btn btn-dark btn-mini">
                            <span>Invite & Earn</span>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleKyc(
                  infoCountry, kycType, firstName, lastName, gender, number, infoDob, address, infoState, city,
                  zipCode, aadhar, panCard, localFront, localBack, localSelfie, localPanCard, docType, rePanCard, middleName, mobileOtp, emailOtp, emailId
                );
              }}
            >
              <div className="row">
                <div className="col-lg-10 m-auto">
                  <div className="create-item-wrapper">
                    <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box" defaultValue="India">
                            <label htmlFor="royality" className="form-label">
                              Select country
                            </label>
                            <select className="" id="inputGroupSelect03" value={infoCountry} name="infoCountry"
                              onChange={(event) => { setDocType(''); setInfoCountry(event.target.value) }}>
                              <option >India</option>
                              {options.map((item, index) => <option value={item.label} key={index}>{item.label}</option>)}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="sizes" className="form-label">
                              KYC Type
                            </label>
                            <input type="text" value={kycType} name="kycType" className="form-control" onChange={(event) => setKycType(event.target.value)} disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 pt-6">
                      <h5 className="title ">Personal Information</h5>
                    </div>
                    <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              First Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={firstName === "undefined" ? "" : firstName}
                              name="firstName" onChange={(event) => setFirstName(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Middle Name
                            </label>
                            <input type="text" placeholder="" value={middleName === "undefined" ? "" : middleName} name="middleName" onChange={(event) => setMiddleName(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Last Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={lastName === "undefined" ? "" : lastName}
                              name="lastName" onChange={(event) => setLastName(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="pb-2" htmlFor="">
                              Gender<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="d-flex align-items-center mb-3">
                              <div className="form-check me-3">
                                <input id="male" className="form-check-input" type="radio" name="gender" value="male" onClick={(event) => setGender(event.target.value)} defaultChecked={gender === 'male'} />
                                <label className="form-check-label ms-2" htmlFor="male">
                                  Male
                                </label>
                              </div>
                              <div className="form-check">
                                <input id="female" className="form-check-input" type="radio" value="female" name="gender" onClick={(event) => setGender(event.target.value)} defaultChecked={gender === 'female'} />
                                <label className="form-check-label ms-2" htmlFor="female">
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                        <div className="col-12 ">
                          <div className="field-box field-otp-box">
                            <label htmlFor="Code" className="form-label">
                              Mobile Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3 mb-4">
                            <div className="field-box " defaultValue="+91">
                              <select name="countryCode" id="" value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                                <optgroup>
                                  <option value="+91">
                                    India (+91)
                                  </option>
                                  {CountryList.map((value) => {
                                    return <option value={value} key={value}>{value}</option>
                                  })}
                                </optgroup>
                              </select>
                            </div>
                          </div>
                          <div className="col-6 mb-4">
                            <div className="field-box field-otp-box">
                              <DefaultInput required type="number" name="mobile" placeholder="Enter mobile number"
                                errorStatus={infoCountry === "India" ? validateNumber(number) : OtherCountryvalidateNumber(number)}
                                errorMessage={validateNumber(number)} value={number}
                                onChange={(e) => setNumber(e.target.value)} />
                              <button type="button" className="btn btn-sm btn-gradient" onClick={() => { handleGetOtp(number, disableBtn); setDisbaleBtn(true); }}                              >
                                <span> {disableBtn ? 'Resend OTP' : 'GET OTP '}</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-3 mb-3">
                            <div className="field-box">
                              <input type="number" placeholder="Enter Mobile OTP" value={mobileOtp} onChange={(e) => { setmobileOtp(e.target.value) }} />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="fleld-box">
                            <label htmlFor="email" className="form-label">
                              Email Id
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-8 mb-4">
                          <div className="field-box field-otp-box">
                            {/* {console.log(email(emailId), 'email(emailId)')} */}
                            <DefaultInput required errorStatus={email(emailId)} errorMessage={(emailError) && email(emailId)}
                              name="email" type="email" placeholder="Enter Email" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                            <button type="button" className="btn btn-sm btn-gradient" onClick={() => { email(emailId) === undefined ? handleGetOtp(emailId, disableBtn) : setemailError(true); }}>
                              <span> {disableBtn2 ? 'Resend OTP' : 'GET OTP '}</span>
                            </button>
                          </div>
                        </div>
                        <div className="col-4 mb-3">
                          <div className="field-box">
                            <input id="emailOTP" name="emailOTP" type="number" placeholder="Enter Email OTP" value={emailOtp} onChange={(e) => { setemailOtp(e.target.value) }} />
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label htmlFor="dob" className="form-label">
                              Date of birth
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input name="dob" id="dob" type="date" style={{ cursor: "pointer" }} required placeholder="ddd" max={getEighteenYearsAgoDate()} value={infoDob} onChange={(event) => setInfoDob(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Pin Code <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput required errorStatus={postCode(zipCode)} errorMessage={postCode(zipCode)} type="number" value={zipCode} name="zipCode" onChange={(event) => setZipCode(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              State<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={infoState} name="state" onChange={(event) => setInfoState(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              City<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={city} name="city" onChange={(event) => setCity(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Address<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={address} name="address" onChange={(event) =>
                              setAddress(event.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 pt-6">
                      <h5 className="title ">Document Type</h5>
                    </div>

                    {infoCountry === "India" ? <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="sizes" className="form-label">
                              Select Document Type <span style={{ color: "red" }}>*</span>
                            </label>
                            <select value={docType} required name="docType"
                              onChange={(event) => {
                                setAadhar(""); setDocType(event.target.value);
                                handleSelected(event.target.value === "Aadhaar" ? 1 : event.target.value === "Driving License" ? 2 : event.target.value === "Other" ? 3 : undefined)
                              }}>
                              <option value="" selected>Select</option>
                              <option value="Aadhaar">Aadhar card </option>
                              <option value="Driving License">
                                Driving License
                              </option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className={`field-box ${isShow !== 1 && "d-none"}`} id="aadhar">
                            <label htmlFor="name" className="form-label">
                              Aadhar Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              errorStatus={aadharNum(aadhar) !== undefined && notEqualsZero(aadhar)}
                              errorMessage={aadharNum(aadhar)}
                              onChange={(event) => setAadhar(event.target.value)} />
                          </div>
                          <div className={`field-box ${isShow !== 2 && "d-none"}`}                          >
                            <label htmlFor="name" className="form-label">
                              Driving License Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              errorStatus={drivingLicense(aadhar) !== undefined && notEqualsZero(aadhar)}
                              errorMessage={drivingLicense(aadhar)}
                              onChange={(event) => setAadhar(event.target.value.toUpperCase())} />
                          </div>
                          <div className={`field-box ${isShow !== 3 && "d-none"}`}>
                            <label htmlFor="name" className="form-label">
                              Other Document Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              errorStatus={documentNum(aadhar) !== undefined && notEqualsZero(aadhar)}
                              errorMessage={documentNum(aadhar)}
                              onChange={(event) => setAadhar(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6 upload-area ">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Front Image<span style={{ color: "red" }}>*</span>
                              <div className="mt-1 text-alert"  >
                                <small>
                                  (Only JPEG, PNG & JPG formats are supported)
                                </small><br />
                                <small>
                                  (Max image size is 5MB)
                                </small>
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" data-multiple-caption="{count} files selected"
                              onChange={handleChangeIdentity} />
                            {docType === "" ?
                              <label htmlFor="file" title="No File Choosen">
                                <i className="ri-upload-cloud-line"></i>
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </label>
                              :
                              <label htmlFor="file" title="No File Choosen">
                                <img alt="" src={docType === 'Aadhaar' && !frontImage ? 'images/kyc/aadhaar_front.png' : docType === 'Driving License' && !frontImage ? 'images/kyc/dl front.png' : docType === 'Other' && !frontImage ? 'images/kyc/other_doc.png' : frontImage} className="img-fluid" width="300" height="300" />
                              </label>}
                          </div>
                        </div>
                        <div className="col-md-6 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Back Image <span style={{ color: "red" }}>*</span>
                              <div className="mt-1 text-alert"  >
                                <small>
                                  (Only JPEG, PNG & JPG formats are supported)
                                </small><br />
                                <small>
                                  (Max image size is 5MB)
                                </small>
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" onChange={handleChangeIdentity2} />
                            {docType === "" ?
                              <label htmlFor="file" title="No File Choosen">
                                <i className="ri-upload-cloud-line"></i>
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </label>
                              :
                              <label htmlFor="file" title="No File Choosen">
                                <img alt="" src={docType === 'Aadhaar' && !backImage ? 'images/kyc/aadhaar_back.png' : docType === 'Driving License' && !backImage ? 'images/kyc/dl back.png' : docType === 'Other' && !backImage ? 'images/kyc/other_doc.png' : backImage} className="img-fluid" width="300" height="300" />
                              </label>}
                          </div>
                        </div>
                      </div>
                    </div> : <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="sizes" className="form-label">
                              Select Document Type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select required value={docType} name="docType"
                              onChange={(event) => {
                                setAadhar(""); setDocType(event.target.value);
                                handleSelectedOther(event.target.value === "Driving License" ? 2 : event.target.value === "passport" ? 3 : undefined)
                              }}>
                              <option value="">Select</option>
                              <option value="Driving License">
                                Driving License
                              </option>
                              <option value="passport">Passport</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className={`field-box ${isShowOther !== 2 && "d-none"}`}>
                            <label htmlFor="name" className="form-label">
                              Driving License Number <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              onChange={(event) => setAadhar(event.target.value.toUpperCase())} />
                          </div>
                          <div className={`field-box ${isShowOther !== 3 && "d-none"}`}>
                            <label htmlFor="name" className="form-label">
                              Passport Number <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              onChange={(event) => setAadhar(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Front Image<span style={{ color: "red" }}>*</span>
                              <div className="mt-1 text-alert"  >
                                <small>
                                  (Only JPEG, PNG & JPG formats are supported)
                                </small><br />
                                <small>
                                  (Max image size is 5MB)
                                </small>
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" data-multiple-caption="{count} files selected" onChange={handleChangeIdentity} />
                            {docType === '' ?
                              <label htmlFor="file" title="No File Choosen">
                                <i className="ri-upload-cloud-line"></i>
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </label>
                              : <label htmlFor="file" title="No File Choosen">
                                <img alt="" src={docType === 'Driving License' && !frontImage ? 'images/kyc/dl front.png' : docType === 'passport' && !frontImage ? 'images/kyc/passport_front.png' : frontImage} className="img-fluid" width="300" height="300" />
                              </label>}
                          </div>
                        </div>
                        <div className="col-md-6 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Back Image <span style={{ color: "red" }}>*</span>
                              <div className="mt-1 text-alert"  >
                                <small>
                                  (Only JPEG, PNG & JPG formats are supported)
                                </small><br />
                                <small>
                                  (Max image size is 5MB)
                                </small>
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" onChange={handleChangeIdentity2} />
                            {docType === '' ?
                              <label htmlFor="file" title="No File Choosen">
                                <i className="ri-upload-cloud-line"></i>
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </label> :
                              <label htmlFor="file" title="No File Choosen">
                                <img alt="" src={docType === 'Driving License' && !backImage ? 'images/kyc/dl back.png' : docType === 'passport' && !backImage ? 'images/kyc/passport_back.png' : backImage} className="img-fluid" width="300" height="300" />
                              </label>}
                          </div>
                        </div>
                      </div>
                    </div>}
                    <div className="mb-4 pt-6">
                      {infoCountry === "India" ? <h5 className="title ">Pan Card</h5> : <h5 className="title ">Other Document</h5>}
                    </div>
                    {infoCountry === "India" ? <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Pan Card Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="panCard" value={panCard}
                              errorStatus={validatePanCard(panCard) !== undefined && notEqualsZero(panCard)}
                              errorMessage={validatePanCard(panCard)}
                              onChange={(event) => setPanCard(event.target.value.toUpperCase())} />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Confirm Pan Card Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="rePanCard" value={rePanCard}
                              errorStatus={(validatePanCard(rePanCard) !== undefined && notEqualsZero(rePanCard)) || matchPanCard(panCard, rePanCard)}
                              errorMessage={validatePanCard(rePanCard) || matchPanCard(panCard, rePanCard)}
                              onChange={(event) => setRePanCard(event.target.value.toUpperCase())} />
                          </div>
                        </div>
                        <div className="col-md-12 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Upload Item File
                              <span style={{ color: "red" }}>*</span>
                              <div className="mt-1 text-alert"  >
                                <small>
                                  (Only JPEG, PNG & JPG formats are supported)
                                </small><br />
                                <small>
                                  (Max image size is 5MB)
                                </small>
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" id="file" type="file" required className="inputfile" data-multiple-caption="{count} files selected" multiple onChange={handleChangePanCard} />
                            <label htmlFor="file" title="No File Choosen">
                              <img alt="" src={pancardImage ? pancardImage : 'images/kyc/pan_card.png'} className="img-fluid" width="300" height="300" />
                            </label>

                          </div>
                        </div>
                      </div>
                    </div> : <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Other Document Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="panCard" value={panCard} onChange={(event) => setPanCard(event.target.value.toUpperCase())} />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Confirm Other Document Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="rePanCard" value={rePanCard} onChange={(event) => setRePanCard(event.target.value.toUpperCase())} />
                          </div>
                        </div>
                        <div className="col-md-12 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Upload Item File
                              <span style={{ color: "red" }}>*</span>
                              <div className="mt-1 text-alert"  >
                                <small>
                                  (Only JPEG, PNG & JPG formats are supported)
                                </small><br />
                                <small>
                                  (Max image size is 5MB)
                                </small>
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" id="file" type="file" required className="inputfile" data-multiple-caption="{count} files selected" multiple onChange={handleChangePanCard} />
                            <label htmlFor="file" title="No File Choosen">
                              <img alt="" src={pancardImage ? pancardImage : 'images/kyc/other_doc.png'} className="img-fluid" width="300" height="300" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>}

                    <div className="mb-4 pt-6">
                      <div className="title ">
                        Upload Selfie with ID
                        <span style={{ color: "red" }}>*</span>
                        <div className="mt-1 text-alert"  >
                          <small>
                            (Only JPEG, PNG & JPG formats are supported)
                          </small><br />
                          <small>
                            (Max image size is 5MB)
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="form-field-wrapper">
                      <div className="row">
                        <div className="col-md-12 upload-area">
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" onChange={handleChangeSelfie} />
                            <label htmlFor="file" title="No File Choosen">
                              <img alt="" src={selfieImage ? selfieImage : 'images/kyc/Selfie.png'} className="img-fluid" width="300" height="300" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className=" mt-7  btn w-100 btn-gradient btn-medium justify-content-center">
                      <span>Submit for verification</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </section>
    </>
  );
};

export default KycPage;
